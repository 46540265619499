import React from 'react'

interface Props {
  size?: string
  radius?: number
}

const Document: React.FC<Props> = ({ size, radius }) => (
  <svg viewBox="0 0 600 600" preserveAspectRatio="xMinYMid" width={size} height={size}>
    <rect rx={radius} ry={radius} width="100%" height="100%" fill="#2b5797" />
    <g fill="#fff">
      <path
        d="M228.771 280.188c-9.549.164-15.053 9.571-15.053 22.019 0 12.542 5.683 21.484 15.144 21.648 9.694.174 15.322-9.267 15.322-22.028-.004-11.78-5.535-21.806-15.413-21.639zM160.957 281.533c-2.841.045-4.668.346-5.744.637v39.938c1.076.29 2.822.326 4.403.351 11.632.291 19.34-6.594 19.34-21.468.087-12.93-6.943-19.65-17.999-19.458z"
        fill="#FFF"
      />
      <path d="M335.419 448.668c.468.025.93.067 1.405.067h106.92c12.507 0 22.68-10.176 22.68-22.681V178.196c0-12.502-10.173-22.68-22.68-22.68h-106.92c-.472 0-.937.047-1.405.068V126l-208.682 27.85V450.59l208.682 28.571v-30.492zm1.405-280.19h106.92c5.36 0 9.72 4.363 9.72 9.72v247.863c0 5.356-4.361 9.72-9.72 9.72h-106.92c-.478 0-.943-.077-1.405-.146v-79.24h96.087a4.291 4.291 0 100-8.58l-96.087-.001v-26.332h96.087a4.295 4.295 0 004.296-4.29 4.29 4.29 0 00-4.296-4.29h-96.087v-25.514h96.087a4.293 4.293 0 004.296-4.29 4.291 4.291 0 00-4.296-4.29h-96.087v-25.755h45.15a4.292 4.292 0 000-8.582h-45.15v-75.85c.462-.068.924-.142 1.405-.142zM183.278 325.837c-6.133 5.322-15.344 7.657-26.406 7.366-6.532-.177-11.107-.744-14.202-1.272v-59.32c4.563-.924 10.557-1.526 16.946-1.688 10.776-.283 17.872 1.659 23.49 6.061 6.108 4.721 9.966 12.416 9.966 23.537 0 12.052-4.13 20.304-9.794 25.316zm44.829 9.638c-18.39-.49-28.894-14.957-28.894-32.992 0-18.973 11.593-33.458 29.93-33.945 19.644-.518 30.634 14.467 30.634 32.797 0 21.781-13.007 34.637-31.67 34.14zm76.855-10.897c4.84.082 10.264-.855 13.479-2.036l2.462 12.318c-3.006 1.367-9.714 2.734-18.352 2.5-23.952-.633-35.956-15.32-35.956-34.346 0-22.78 16.46-35.897 37.526-36.45 8.34-.22 14.744 1.26 17.649 2.634l-3.329 12.545c-3.316-1.286-7.891-2.446-13.587-2.353-12.585.211-22.205 7.695-22.205 22.74 0 13.544 8.174 22.204 22.313 22.448z" />
      <path d="M406.436 261.649l3.978-16.161c1.016-3.927 1.683-7.238 2.297-10.91h.098c.405 3.72 1.015 6.983 1.883 10.91l3.575 16.16H425l9.22-34.36h-6.372l-3.569 15.55c-.917 4.178-1.734 8.103-2.344 11.878h-.108c-.503-3.723-1.221-7.547-2.086-11.627l-3.417-15.801h-6.574l-3.718 15.55c-.974 4.331-1.952 8.41-2.503 12.03h-.094a305.947 305.947 0 00-2.25-11.98l-3.263-15.597h-6.676l8.413 34.36h6.777v-.002z" />
    </g>
  </svg>
)

export default Document
