import React, { Suspense } from 'react'
import * as Sentry from '@sentry/react'
import { ThemeProvider } from 'styled-components'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import LayoutService from './components/Layout/Service'
import LayoutHeader from './components/Layout/Header'
import Index from './routes/Index'
import FileNotFound from './routes/FileNotFound'
import UrlNotFound from './routes/UrlNotFound'
import i18next from './modules/language/i18next'
import GlobalTheme from './constants/GlobalTheme'
import { GlobalStyles } from './constants/GlobalStyle'
import Loader from './components/Loader'
import RateLimiting from './routes/RateLimiting'
import { StaticRoutes } from './routes/Static/StaticRoutes'
import { Country } from './modules/language/model'
import { Service } from './modules/config/model'
import { ConfigContextProvider } from './modules/config/context'
import { errorBoundaryFallback } from './modules/sentry/init'
import useWindowReload from './hooks/useWindowReload'

const CommonRoutes = (
  <>
    <Route index element={<Index />} />
    <Route path="file-not-found" element={<FileNotFound />} />
    <Route path="rate-limiting" element={<RateLimiting />} />
    <Route path="*" element={<UrlNotFound />} />
  </>
)

const App: React.FC = () => {
  useWindowReload(1000 * 60 * 60 * 24, 1000 * 60)

  return (
    <ThemeProvider theme={GlobalTheme}>
      <GlobalStyles />
      <Sentry.ErrorBoundary fallback={errorBoundaryFallback}>
        <I18nextProvider i18n={i18next}>
          <Suspense fallback={<Loader />}>
            <ConfigContextProvider>
              <BrowserRouter>
                <Routes>
                  <Route path="/cse/ulozto/:country" element={<Navigate to="/" />} />
                  <Route path="/cse/pinkfile" element={<Navigate to="/" />} />
                  <Route path="/" element={<LayoutService service={Service.MG} country={Country.EN} />}>
                    {CommonRoutes}
                  </Route>
                  <Route path="/" element={<LayoutHeader />}>
                    {StaticRoutes}
                  </Route>
                  <Route path="*" element={<UrlNotFound />} />
                </Routes>
              </BrowserRouter>
            </ConfigContextProvider>
          </Suspense>
        </I18nextProvider>
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  )
}

export default App
