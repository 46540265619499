import styled, { css } from 'styled-components'

export const Filter = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.bg.shade5};

  .wrapper {
    position: relative;
    padding: 0 10px;

    @media screen and (min-width: ${({ theme }) => theme.layout.sizes.xl + 1}px) {
      display: flex;
      justify-content: center;
    }

    &.hidden {
      display: none;
    }
  }

  .more {
    &:hover ul {
      display: block;
    }
    ul {
      display: none;
    }
  }
`

export const Grad = styled.div<{
  type: string
  $isEnd: boolean
}>`
  @media screen and (max-width: ${({ theme }) => theme.layout.sizes.md}px) {
    position: absolute;
    z-index: 10;
    top: 0;
    display: none;
    width: 50px;
    height: 100%;
    pointer-events: none;

    ${({ type, $isEnd }) =>
      type === 'left' &&
      !$isEnd &&
      css`
        left: 0;
        display: block;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, ${({ theme }) => theme.color.bg.shade5} 100%);
      `}

    ${({ type, $isEnd }) =>
      type === 'right' &&
      !$isEnd &&
      css`
        right: 0;
        display: block;
        background: linear-gradient(270deg, ${({ theme }) => theme.color.bg.shade5} 0%, rgba(0, 0, 0, 0) 100%);
      `}
  }
`

export const Tabs = styled.div`
  padding: 10px 1px 5px 1px;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;

  @media screen and (min-width: ${({ theme }) => theme.layout.sizes.xl + 1}px) {
    width: ${({ theme }) => theme.size.querybox}px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  a {
    display: flex;
    align-items: center;
    padding: 5px 2px 5px 0;
    color: ${({ theme }) => theme.color.text};
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    text-align: center;

    @media screen and (min-width: ${({ theme }) => theme.layout.sizes.lg}px) {
      font-size: 13px;
    }

    &.is-active {
      color: ${({ theme }) => theme.color.link};
    }
    &:not(span):hover {
      opacity: 0.8;
    }
  }
  span.disabled {
    display: flex;
    align-items: center;
    padding: 5px 2px 5px 0;
    color: ${({ theme }) => theme.color.bg.shade30};
    font-size: 12px;
    pointer-events: none;

    @media screen and (min-width: ${({ theme }) => theme.layout.sizes.lg}px) {
      font-size: 13px;
    }

    span {
      cursor: auto;
    }
  }

  ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style-type: none;
    white-space: nowrap;

    @media screen and (min-width: ${({ theme }) => theme.layout.sizes.xl + 1}px) {
      max-width: ${({ theme }) => theme.size.querybox}px;
    }

    > li:not(:where(:last-child)) {
      margin-right: 15px;
    }

    ul {
      position: absolute;
      z-index: 20;
      display: flex;
      flex-direction: column;
      margin-left: -10px;
      background-color: ${({ theme }) => theme.color.bg.main};
      border-radius: 5px;
      box-shadow: 0 0 4px ${({ theme }) => theme.color.shadow};

      li {
        margin: 0;
      }

      a {
        padding: 10px 10px;

        &:hover {
          background-color: ${({ theme }) => theme.color.bg.shade10};
          opacity: 1;
        }
      }
    }
  }

  li {
    @media screen and (max-width: ${({ theme }) => theme.layout.sizes.md}px) {
      &.filter {
        margin-left: 15px;
      }
    }
    @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
      &.filter {
        margin-left: auto;
      }
    }
  }

  svg {
    display: inline-block;
    width: 20px;
    height: 15px;
    margin-right: 5px;
  }
`

export const Sorting = styled.div`
  padding: 1px 1px 5px 1px;
  overflow-x: scroll;
  overflow-y: auto;
  scrollbar-width: none;

  @media screen and (min-width: ${({ theme }) => theme.layout.sizes.xl + 1}px) {
    width: ${({ theme }) => theme.size.querybox}px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  ul {
    display: flex;
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    gap: 12px;
    list-style-type: none;
    white-space: nowrap;

    @media screen and (min-width: ${({ theme }) => theme.layout.sizes.lg}px) {
      font-size: 13px;
    }
    @media screen and (min-width: ${({ theme }) => theme.layout.sizes.lg}px) {
      gap: 20px;
    }

    > li {
      display: flex;
      align-items: center;

      strong {
        display: inline-block;
        margin-right: 10px;
        font-weight: 500;
      }
      span {
        display: inline-flex;
        align-items: center;
        padding: 5px 2px;
        cursor: pointer;
        gap: 5px;
      }

      &.is-active {
        > span {
          color: ${({ theme }) => theme.color.link};

          svg {
            color: ${({ theme }) => theme.color.text};
          }
        }
      }

      .wrapper {
        position: absolute;
        z-index: 10;
        top: 25px;
        left: 0;
        display: inline-block;
        padding: 0;
        margin: 0 0 0 -12px;
        background-color: ${({ theme }) => theme.color.bg.main};
        border-radius: 5px;
        box-shadow: 0 0 4px ${({ theme }) => theme.color.shadow};
        list-style-type: none;
        pointer-events: none;
        visibility: hidden;

        ul {
          display: inline-block;
          list-style-type: none;
        }
        li {
          position: relative;
          display: block;
          padding: 10px 45px 10px 15px;
          margin: 0;
          cursor: pointer;
          white-space: nowrap;

          &:first-child {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }
          &:last-child {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          }

          &:hover {
            background-color: ${({ theme }) => theme.color.bg.shade10};
          }
          &.is-active {
            color: ${({ theme }) => theme.color.link};
          }
          svg {
            position: absolute;
            top: 12px;
            right: 15px;
          }
        }
      }
    }
  }
`
