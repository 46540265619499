import { ApiRequestPromise, ApiResponse, getMockedApiResponse, request } from '../api/request'
import { MaxQueryLength, SearchFilter, SearchFilterType, SearchQuery } from '../search/model'
import { Service } from '../config/model'
import { Country, Language } from '../language/model'
import { SessionId } from '../user/model'
import { getServiceRoute } from '../router/service'
import { Platform } from '../app/model'
import { encode, isObfuscationEnabled } from '../api/obfuscation'
import { GOZO_SLEEPING } from '../../constants/common'
import { TUndefined } from '../../types'
import { File, MatchingType } from './model'

export enum ErrorCodes {
  InvalidSearchQuery = 20005,
}

export interface GetFileListResponse extends ApiResponse {
  data: {
    metadata: {
      is_smut_query: boolean
      is_partial_match: boolean
    }
    items: File[]
  }
}

export const getFileList = (
  sessionId: SessionId,
  service: Service,
  query: SearchQuery,
  filter: SearchFilter,
  limit: number,
  offset: number
): ApiRequestPromise<GetFileListResponse> => {
  if (GOZO_SLEEPING) {
    return getMockedApiResponse<GetFileListResponse>({
      data: {
        status: 200,
        data: {
          metadata: {
            is_smut_query: false,
            is_partial_match: false,
          },
          items: [],
        },
      },
    })
  }

  // truncate query silently, if limit is exceeded
  let url = `v2/files/list?services[]=${encodeURIComponent(service)}&query=${encodeURIComponent(query.substring(0, MaxQueryLength))}`
  url += `&limit=${encodeURIComponent(limit)}&offset=${encodeURIComponent(offset)}`

  if (filter.contentType !== SearchFilterType.All) {
    url += `&type=${encodeURIComponent(filter.contentType)}`
  }

  if (filter.contentType === SearchFilterType.Video) {
    filter.video?.streaming.forEach((value) => (url += `&videoStreaming[]=${encodeURIComponent(value)}`))
    filter.video?.quality.forEach((value) => (url += `&videoQuality[]=${encodeURIComponent(value)}`))
    filter.video?.duration.forEach((value) => (url += `&videoDuration[]=${encodeURIComponent(value)}`))
    filter.video?.size.forEach((value) => (url += `&videoSize[]=${encodeURIComponent(value)}`))
  }

  if (filter.contentType === SearchFilterType.Image) {
    filter.image?.quality.forEach((value) => (url += `&imageQuality[]=${encodeURIComponent(value)}`))
    filter.image?.size.forEach((value) => (url += `&imageSize[]=${encodeURIComponent(value)}`))
  }

  if (filter.contentType === SearchFilterType.Audio) {
    filter.audio?.quality.forEach((value) => (url += `&audioQuality[]=${encodeURIComponent(value)}`))
    filter.audio?.duration.forEach((value) => (url += `&audioDuration[]=${encodeURIComponent(value)}`))
    filter.audio?.size.forEach((value) => (url += `&audioSize[]=${encodeURIComponent(value)}`))
  }

  if (filter.contentType === SearchFilterType.Archive) {
    filter.archive?.size.forEach((value) => (url += `&archiveSize[]=${encodeURIComponent(value)}`))
  }

  if (filter.contentType === SearchFilterType.Document) {
    filter.document?.size.forEach((value) => (url += `&documentSize[]=${encodeURIComponent(value)}`))
  }

  return request(url, sessionId) as ApiRequestPromise<GetFileListResponse>
}

export const createFileDetailLink = (
  sessionId: SessionId,
  service: Service,
  matchingType: TUndefined<MatchingType>,
  searchQuery: SearchQuery,
  language: Language,
  country: Country,
  platform: Platform,
  backDomain: string | null,
  url: string,
  id: string | null
): string => {
  const finalPlatform = [Service.Ulozto].includes(service) ? platform.toString() : Platform.Web.toString() // todo move it somewhere else
  const serviceRoute = getServiceRoute(service, country).replace(/\/$/, '')
  const params = new URLSearchParams({
    language,
    service,
    userSessionId: sessionId,
    platform: finalPlatform,
    errorUrl: window.location.origin + serviceRoute + '/file-not-found?platform=' + finalPlatform,
    rateLimitingUrl: window.location.origin + serviceRoute + '/rate-limiting?platform=' + finalPlatform,
  })
  if (backDomain) {
    params.set('backDomain', backDomain)
  }
  if (id) {
    params.set('id', id)
  }
  if (matchingType === MatchingType.Exact || service === Service.MG) {
    params.set('searchQuery', searchQuery)
  }

  const fileDetailLink = `v3/${url}?${params.toString()}`
  return window.Config.BACKEND_API_URL + 'redirect/' + (isObfuscationEnabled() ? encode(fileDetailLink) : fileDetailLink)
}
