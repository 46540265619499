import { useEffect } from 'react'
import _ from 'lodash'

const useScroll = (element: HTMLElement | Document | null, callback: (e: Event) => void) => {
  const throttled = _.throttle(callback, 50, { trailing: true })

  useEffect(() => {
    if (!element) {
      return
    }

    element.addEventListener('scroll', throttled)

    return () => element.removeEventListener('scroll', throttled)
  }, [element])
}

export default useScroll
