import React from 'react'

const LogoSymbolSvg: React.FC = () => (
  <svg viewBox="0 0 85 100" width="1em" height="1em" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#7AA5D2"
      d="m9.6 80 11.9-21.3 11.1 15.1-8.3 14.7-14.7-8.5zm63-4.7-6.6 10.2 9.3-5.4-2.7-4.8zm-30.2-53.7-19.5 34.6 11.1 15.2 18.2-32.4-9.8-17.4zm12.8 17.4 17.5 31.2 11.9-18.3v-26.5l-16.4-9.6-13 23.2zm-19.5 34.7 15.1 20.6 10.2-5.9 10.2-15.7-17.4-31-18.1 32zm6.7-57.4 6.6-11.7-6.5-3.8-6.6 3.9 6.5 11.6zm31.8 56.5 3.3 5.9 7.1-4.2v-17.8l-10.4 16.1zm-39.9 3.4-7.7 13.7 15.9 9.3 6.1-3.6-14.3-19.4zm-33.9-50.8 20.9 28.5 19.7-35-7.4-12.9-33.2 19.4zm50.8-19.5-7.3 13 9.8 17.4 12.3-21.8-14.8-8.6zm-31.3 50.5-19.5-26.6v44.8l7 4.1 12.5-22.3z"
    />
  </svg>
)

export { LogoSymbolSvg }
