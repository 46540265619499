import React from 'react'
import _ from 'lodash'
import { Trans, useTranslation } from 'react-i18next'
import Icon from '../../Icons'
import { useStore } from '../../../hooks/useStore'
import SearchStore, { SearchStoreDefaults } from '../../../modules/search/store'
import { List, Info } from './styled'

const ListEmpty: React.FC = () => {
  const { t } = useTranslation()
  const searchStore = useStore(SearchStore)

  return (
    <List data-testid="fileList-empty">
      <Info>
        <Icon.SearchNoResults />
        <Trans i18nKey={'components.File.FileListEmpty.empty'}>Search engine for this customer service has been disabled....</Trans>
        &nbsp;
        {!_.isEqual(SearchStoreDefaults.searchFilter, searchStore.searchFilter) && (
          <Trans i18nKey={'components.File.FileListEmpty.emptyWithFilter.text'}>
            Do you want to enlarge the search to
            <span
              onClick={() => {
                SearchStore.setSearchFilter(SearchStoreDefaults.searchFilter)
              }}
            >
              {t('components.File.FileListEmpty.emptyWithFilter.link')}
            </span>
            ?
          </Trans>
        )}
      </Info>
    </List>
  )
}

export default ListEmpty
