import React from 'react'

interface Props {
  size?: string
  radius?: number
}

const PPT: React.FC<Props> = ({ size, radius }) => (
  <svg viewBox="0 0 600 600" preserveAspectRatio="xMinYMid" width={size} height={size}>
    <rect rx={radius} ry={radius} width="100%" height="100%" fill="#d04525" />
    <g fill="#fff">
      <path d="M334.438 447.198c.465.025.925.065 1.397.065h106.434c12.45 0 22.576-10.13 22.576-22.576V177.958c0-12.446-10.126-22.577-22.576-22.577H335.835c-.469 0-.932.047-1.397.068V126l-207.732 27.724V449.11l207.732 28.44v-30.352zm1.398-278.912H442.27c5.335 0 9.675 4.341 9.675 9.675v246.732c0 5.333-4.34 9.676-9.675 9.676H335.836c-.476 0-.938-.076-1.398-.145V317.527c5.952 22.643 26.501 39.364 51.011 39.364 29.167 0 52.808-23.64 52.808-52.804h-52.808v-52.81c-24.512 0-45.062 16.723-51.01 39.367V168.427c.458-.068.918-.141 1.397-.141zm-145.98 135.978c-4.902 4.875-12.088 7.023-20.389 6.957-1.833-.015-3.477-.126-4.75-.33v23.662l-13.595-.342v-64.614c4.202-.88 10.144-1.625 18.613-1.849 8.685-.228 14.941 1.403 19.173 4.9 4.084 3.319 6.84 8.9 6.84 15.533.004 6.637-2.092 12.271-5.892 16.083zm57.374.1c-5.334 5.093-13.165 7.326-22.199 7.26-1.99-.013-3.781-.129-5.16-.343v24.681l-14.791-.378v-67.352c4.573-.925 11.03-1.703 20.245-1.947 9.45-.246 16.264 1.441 20.877 5.085 4.44 3.464 7.453 9.29 7.453 16.212 0 6.927-2.292 12.813-6.425 16.782zm67.705-25.86l-20.582.336v59.018l-16.667-.425v-58.326l-19.226.317v-13.492l56.475-1.465v14.037z" />
      <path d="M170.473 279.073c-2.843.051-4.758.378-5.757.693v19.542c1.177.292 2.637.39 4.653.384 7.463-.014 12.115-4.1 12.115-10.96 0-6.17-3.994-9.782-11.01-9.66zM226.127 278.095c-3.087.052-5.173.397-6.253.723v20.38c1.284.306 2.868.402 5.057.402 8.128-.013 13.19-4.275 13.19-11.44 0-6.437-4.359-10.2-11.994-10.065zM392.617 295.753v.016h52.811c0-14.852-6.161-28.255-16.035-37.848l-36.776 37.832zM392.057 286.474l37.337-37.338c-10.501-10.508-23.604-15.629-37.374-15.432l.025 52.758.012.012z" />
    </g>
  </svg>
)

export default PPT
