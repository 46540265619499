import 'yet-another-abortcontroller-polyfill'
import 'url-polyfill/url-polyfill'
import 'intersection-observer'
import React from 'react'
import { createRoot } from 'react-dom/client'
import './wdyr'
import './modules/config/development'
import App from './App'

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

if (container) {
  container.classList.add('loaded')
}
