import React, { useContext, useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { Service } from '../../../modules/config/model'
import { TRouteCountryParams, Country } from '../../../modules/language/model'
import { ConfigContext, ConfigDispatchContext } from '../../../modules/config/context'
import { CountryToLanguage } from '../../../modules/language/converter'
import UrlNotFound from '../../../routes/UrlNotFound'
import { getPrimaryCaptchaProvider } from '../../../modules/rateLimiting/captchaProviders'

interface Props {
  service: Service
  country?: Country
}

const LayoutService: React.FC<Props> = ({ service, country }) => {
  const params = useParams<TRouteCountryParams>()
  const config = useContext(ConfigContext)
  const dispatchConfig = useContext(ConfigDispatchContext)

  if (!country) {
    if (!('country' in params) || !Object.values(Country).includes(params.country as Country)) {
      return <UrlNotFound />
    }

    country = params.country
  }

  useEffect(() => {
    country = country as Country
    dispatchConfig({
      service,
      country,
      language: CountryToLanguage[country],
      initialized: true,
      captchaProvider: getPrimaryCaptchaProvider(),
    })
  }, [service, country])

  return config.initialized ? <Outlet /> : null
}

export default LayoutService
