import React from 'react'
import APK from '../fileExtension/APK'
import Archive from '../fileExtension/Archive'
import Audio from '../fileExtension/Audio'
import Document from '../fileExtension/Document'
import EBook from '../fileExtension/EBook'
import EXE from '../fileExtension/EXE'
import FLAC from '../fileExtension/FLAC'
import Image from '../fileExtension/Image'
import ISO from '../fileExtension/ISO'
import M3U from '../fileExtension/M3U'
import MP3 from '../fileExtension/MP3'
import OtherFile from '../fileExtension/OtherFile'
import PDF from '../fileExtension/PDF'
import PPT from '../fileExtension/PPT'
import Subtitles from '../fileExtension/Subtitles'
import TextFile from '../fileExtension/TextFile'
import Torrent from '../fileExtension/Torrent'
import Video from '../fileExtension/Video'
import WAV from '../fileExtension/WAV'
import XLS from '../fileExtension/XLS'
import { ContentType } from '../../../modules/file/model'
import { TUndefined } from '../../../types'
import { FileIconType, resolveFileIconType } from './utils'
import { Wrapper } from './styled'

interface Props {
  extension: string
  contentType: TUndefined<ContentType>
  size?: string
  radius?: number
}

const Icon: React.FC<Props> = ({ extension, contentType, size, radius }) => {
  const iconType = resolveFileIconType(extension)
  const iconProps = {
    size,
    radius,
  }

  switch (iconType) {
    case FileIconType.PICTURE:
      return <Image {...iconProps} />
    case FileIconType.WORD:
      return <Document {...iconProps} />
    case FileIconType.TEXT:
      return <TextFile {...iconProps} />
    case FileIconType.M3U:
      return <M3U {...iconProps} />
    case FileIconType.EBOOK:
      return <EBook {...iconProps} />
    case FileIconType.PDF:
      return <PDF {...iconProps} />
    case FileIconType.PPT:
      return <PPT {...iconProps} />
    case FileIconType.EXCEL:
      return <XLS {...iconProps} />
    case FileIconType.APK:
      return <APK {...iconProps} />
    case FileIconType.ISO:
      return <ISO {...iconProps} />
    case FileIconType.ZIP:
      return <Archive {...iconProps} />
    case FileIconType.EXE:
      return <EXE {...iconProps} />
    case FileIconType.FLAC:
      return <FLAC {...iconProps} />
    case FileIconType.MP3:
      return <MP3 {...iconProps} />
    case FileIconType.WAV:
      return <WAV {...iconProps} />
    case FileIconType.SOUND:
      return <Audio {...iconProps} />
    case FileIconType.TORRENT:
      return <Torrent {...iconProps} />
    case FileIconType.SUBTITLES:
      return <Subtitles {...iconProps} />
    case FileIconType.VIDEO:
      return <Video {...iconProps} />
    case FileIconType.DEFAULT:
    case FileIconType.UNKNOWN:
    default:
      switch (contentType) {
        case ContentType.Video:
          return <Video {...iconProps} />
        case ContentType.Image:
          return <Image {...iconProps} />
        case ContentType.Audio:
          return <Audio {...iconProps} />
        case ContentType.Archive:
          return <Archive {...iconProps} />
        case ContentType.Document:
          return <Document {...iconProps} />
        case ContentType.Ebook:
          return <EBook {...iconProps} />
        case ContentType.Subtitle:
          return <Subtitles {...iconProps} />
        case ContentType.Torrent:
          return <Torrent {...iconProps} />
        case ContentType.Unknown:
        default:
          return <OtherFile {...iconProps} />
      }
  }
}

const FileIcon: React.FC<Props> = ({ extension, contentType, size = '1em', radius = 50 }) => {
  return (
    <Wrapper className="icon">
      <Icon extension={extension} contentType={contentType} size={size} radius={radius} />
    </Wrapper>
  )
}

export default FileIcon
