import React from 'react'
import { TUndefined } from '../../../../types'

export const createDefaultAttributes = (poster: TUndefined<string>): React.VideoHTMLAttributes<HTMLVideoElement> => ({
  poster,
  preload: 'none', // keep it lazy for video content
  playsInline: true, // https://webkit.org/blog/6784/new-video-policies-for-ios/
  muted: true, // necessary for playing without user interaction
  loop: true,
  autoPlay: false,
  controls: false,
})

// https://github.com/facebook/react/issues/10389
export const applyAttributes = (el: HTMLVideoElement, attrs: React.VideoHTMLAttributes<HTMLVideoElement>): void => {
  Object.entries(attrs).forEach(([key, value]) => {
    if (value) {
      el.setAttribute(key, typeof value === 'boolean' ? key : value)
    }
  })
}
export const hasMediaError = (el: HTMLVideoElement) => el.error instanceof MediaError
export const isMediaError = (el: HTMLVideoElement, code: number) => code == el.error?.code
export const isMediaAbortedError = (el: HTMLVideoElement) => hasMediaError(el) && isMediaError(el, MediaError.MEDIA_ERR_ABORTED)
export const isMediaNotSupportedError = (el: HTMLVideoElement) =>
  hasMediaError(el) && isMediaError(el, MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED)
export const isConverted = (el: HTMLVideoElement) => el.src.startsWith('blob:')

// some "preview.video" URLs not exists on CDN
export const isNotFoundError = (el: HTMLVideoElement) => isMediaNotSupportedError(el) && !isConverted(el)

export const videoReset = async (el: HTMLVideoElement, fileId: string, url: string) => {
  // revoke object url and reset video source to original URL
  if (isConverted(el)) {
    URL.revokeObjectURL(el.src)
    el.src = url
  }
  el.load()
}

export const videoPlay = async (el: HTMLVideoElement, fileId: string, url: string) => {
  try {
    await el.play()
  } catch (e) {
    const isAbortErr = e instanceof Error && e.name === 'AbortError'
    if (isMediaNotSupportedError(el)) {
      videoReset(el, fileId, url)
      return
    } else if (isMediaAbortedError(el) || isAbortErr) {
      videoPlay(el, fileId, url)
      return
    }
  }
}

export const videoStop = (el: HTMLVideoElement) => {
  el.currentTime = 0
  el.pause()
}
