import { v4, validate, version } from 'uuid'

const STORAGE_KEY = 'sessionId'

export const obtainSessionId = (): string => {
  let sessionId = localStorage.getItem(STORAGE_KEY)

  if (!sessionId || !validate(sessionId) || version(sessionId) != 4) {
    sessionId = v4()
    localStorage.setItem(STORAGE_KEY, sessionId)
  }

  return sessionId
}
