import React from 'react'

interface Props {
  size?: string
  radius?: number
}

const Audio: React.FC<Props> = ({ size, radius }) => (
  <svg viewBox="0 0 600 600" preserveAspectRatio="xMinYMid" width={size} height={size}>
    <rect rx={radius} ry={radius} width="100%" height="100%" fill="#6fccd7" />
    <path
      d="M286.4 190.4V408q0 5.2-3.8 9t-9 3.8-9-3.8L198 350.4h-52.4q-5.2 0-9-3.8t-3.8-9v-76.8q0-5.2 3.8-9t9-3.8H198l66.6-66.6q3.8-3.8 9-3.8t9 3.8 3.8 9zm76.8 108.8q0 15.2-8.5 28.3t-22.5 18.7q-2 1-5 1-5.2 0-9-3.7t-3.8-9.1q0-4.2 2.4-7.1t5.8-5 6.8-4.6 5.8-7.1 2.4-11.4-2.4-11.4-5.8-7.1-6.8-4.6-5.8-5-2.4-7.1q0-5.4 3.8-9.1t9-3.7q3 0 5 1 14 5.4 22.5 18.6t8.5 28.4zm51.2 0q0 30.6-17 56.5t-45 37.7q-2.6 1-5 1-5.4 0-9.2-3.8t-3.8-9q0-7.8 7.8-11.8 11.2-5.8 15.2-8.8 14.8-10.8 23.1-27.1t8.3-34.7-8.3-34.7-23.1-27.1q-4-3-15.2-8.8-7.8-4-7.8-11.8 0-5.2 3.8-9t9-3.8q2.6 0 5.2 1 28 11.8 45 37.7t17 56.5zm51.2 0q0 46-25.4 84.5t-67.6 56.7q-2.6 1-5.2 1-5.2 0-9-3.8t-3.8-9q0-7.2 7.8-11.8 1.4-.8 4.5-2.1t4.5-2.1q9.2-5 16.4-10.2 24.6-18.2 38.4-45.4t13.8-57.8-13.8-57.8-38.4-45.4q-7.2-5.2-16.4-10.2-1.4-.8-4.5-2.1t-4.5-2.1q-7.8-4.6-7.8-11.8 0-5.2 3.8-9t9-3.8q2.6 0 5.2 1 42.2 18.2 67.6 56.7t25.4 84.5z"
      fill="#fff"
    />
  </svg>
)

export default Audio
