import React, { FormEvent, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GOZO_SLEEPING } from '../../constants/common'
import { Form } from './styled'

export enum FormPurpose {
  Contact,
  Order,
}

export enum Product {
  Basic = 'basic',
  Premium = 'premium',
  Custom = 'custom',
}

interface Props {
  purpose: FormPurpose
  product?: string
}

const ContactInquiryForm: React.FC<Props> = ({ purpose, product }) => {
  const { t } = useTranslation()
  const [isSent, setIsSent] = useState(false)
  const formRef = useRef<HTMLFormElement | null>(null)
  const translateKey = purpose === FormPurpose.Contact ? 'contact' : 'order'

  const submitHandler = (e: FormEvent) => {
    e.preventDefault()
    if (GOZO_SLEEPING) return

    const data = new FormData(formRef.current as HTMLFormElement)
    const inputs = ['name', 'phone', ...(purpose === FormPurpose.Contact ? ['email', 'company'] : []), 'content']

    let body = ''
    body += 'Hello!\n\n'
    if (purpose === FormPurpose.Order && product) {
      body += "I'm seriously interested in product " + product.toUpperCase() + '.\n\n'
    }
    inputs.forEach((name) => {
      body += document.querySelector('label[for="' + name + '"]')?.textContent + '\n' + data.get(name) + '\n\n'
    })
    body += 'Thank you,\n' + data.get('name')

    const subject = t(`components.ContactInquiryForm.${translateKey}.subject`)
    const email = 'support@gozofinder.com'
    window.open(`mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`, '_self')
    setIsSent(true)
  }

  if (isSent) {
    return (
      <Form>
        <div className="result">The form has been submitted.</div>
      </Form>
    )
  }

  return (
    <>
      <Form>
        <form onSubmit={submitHandler} ref={formRef}>
          <div className="blocks">
            <div>
              <label htmlFor="name">Name</label>
              <input name="name" id="name" type="text" maxLength={200} minLength={3} required disabled />
            </div>
            <div>
              <label htmlFor="phone">Phone</label>
              <input
                name="phone"
                id="phone"
                type="tel"
                maxLength={150}
                pattern="((\+|00)[0-9]{2,3})? ?[0-9]{3} ?[0-9]{3} ?[0-9]{3,6}"
                title="The valid phone number was not entered."
                required
                disabled
              />
            </div>
            {purpose === FormPurpose.Contact && (
              <>
                <div>
                  <label htmlFor="company">Company name</label>
                  <input name="company" id="company" type="text" maxLength={100} minLength={3} required disabled />
                </div>
                <div>
                  <label htmlFor="email">Business e-mail</label>
                  <input name="email" id="email" type="email" maxLength={150} minLength={3} required disabled />
                </div>
              </>
            )}
          </div>
          <div>
            <label htmlFor="content">{t(`components.ContactInquiryForm.${translateKey}.content`)}</label>
            <textarea name="content" id="content" minLength={3} required disabled />
          </div>
          <div className="text">By submitting this form, you agree to GOZO Terms of Service.</div>
          <div className="buttons">
            <button type="submit" className="button" disabled>
              Contact us
            </button>
          </div>
        </form>
      </Form>
    </>
  )
}

export default ContactInquiryForm
