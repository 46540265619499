{
  "components": {
    "ErrorBoundaryScreen": {
      "title": "Bohužel, nastala neočekávaná chyba..."
    },
    "Languages": {
      "cz": "Česky",
      "sk": "Slovensky",
      "en": "English",
      "pl": "Polski"
    },
    "Theme": {
      "light": "Zapnout tmavý režim",
      "dark": "Zapnout světlý režim"
    },
    "Home": {
      "gozoPrivacySearchService": "Gozo is a privacy search service that offers a variety of products to help users safeguard their personal data online. Their products include a custom search engine, a search API, and a web search engine. Gozo does not store user search history or sell user information to advertisers.",
      "gozoCustomSearchEngine": "Gozo's custom search engine allows businesses to create their own private search engines that are tailored to their specific needs. The search API can be used to integrate search capabilities into apps and websites. The web search engine is a privacy-focused alternative to traditional search engines like Google and Bing.",
      "withGozoYouCanTake": "With Gozo, you can take control of your online privacy and search with confidence.<br /><1>Try Gozo today</1> and experience the difference!"
    },
    "Menu": {
      "settings": "Nastavení",
      "language": "Jazyk",
      "details": "Podrobnosti",
      "about": "O nás",
      "products": "Produkty",
      "pricing": "Ceník",
      "privacy": "Zásady ochrany osobních údajů",
      "terms": "Podmínky služby",
      "contact": "Kontakt",
      "help": "Nápověda"
    },
    "File": {
      "FileListEmpty": {
        "empty": "Vyhledávač pro tuto zákaznickou službu byl deaktivován...",
        "emptyWithFilter": {
          "text": "Chcete rozšířit hledání na <1><0/></1>?",
          "link": "všechny soubory"
        }
      },
      "FileListEnd": {
        "empty": "Na vyhledávací dotaz <1>\"<0/>\"</1> nebyly nalezeny žádné další výsledky."
      }
    },
    "Modal": {
      "RateLimiting": {
        "title": "Příliš mnoho požadavků",
        "boxes": {
          "browser": {
            "title": "Prohlížeč",
            "status": "V pořádku"
          },
          "cloudflare": {
            "title": "CloudFlare",
            "status": "V pořádku",
            "imNotRobot": "Nejsem robot"
          },
          "gozo": {
            "title": "GOZO Finder",
            "status": "Chyba"
          }
        },
        "captcha": {
          "error": "Ověření uživatele se nepovedlo, zkuste to prosím znovu.",
          "success": "Ověření uživatele proběhlo úspěšně, počkejte prosím.",
          "title": "Ověření uživatele",
          "waiting": "Příliš mnoho požadavků z IP adresy. Nejste náhodou bot?"
        },
        "whatHappened": {
          "title": "Co se stalo?",
          "body": "Bezpečnostní systém vyhodnotil abnormální množství požadavků z vaší lokality a pro další užívání služby musíte prokázat, že nejste robot."
        }
      }
    },
    "Search": {
      "FilterBox": {
        "all": "Vše",
        "type": {
          "all": "Vše",
          "video": "Video",
          "image": "Obrázky",
          "archive": "Archivy",
          "audio": "Audio",
          "document": "Dokumenty"
        },
        "typeBox": {
          "other": "Další",
          "filter": "Filtr"
        },
        "sort": {
          "label": "Seřadit podle",
          "values": {
            "relevance": "Relevance",
            "latest": "Nejnovější",
            "rating": "Hodnocení",
            "largest": "Od největšího",
            "smallest": "Od nejmenšího"
          }
        },
        "video": {
          "duration": {
            "label": "Délka",
            "values": {
              "longest": "Dlouhé (nad 60 minut)",
              "long": "Delší (20-60 minut)",
              "middle": "Střední (5-20 minut)",
              "short": "Krátké (do 5 minut)"
            }
          },
          "quality": {
            "label": "Kvalita videa",
            "values": {
              "fullhd": "1080p nebo více",
              "hd": "720p nebo více",
              "lq": "Nižší než 720p"
            }
          },
          "streaming": {
            "label": "Typ videa",
            "values": {
              "available": "Streamování",
              "unavailable": "Stahování"
            }
          },
          "size": {
            "label": "Velikost",
            "values": {
              "small": "Malé",
              "medium": "Střední",
              "large": "Velké"
            }
          }
        },
        "image": {
          "quality": {
            "label": "Kvalita",
            "values": {
              "hq": "Vysoká",
              "mq": "Střední",
              "lq": "Nízká"
            }
          },
          "size": {
            "label": "Velikost",
            "values": {
              "small": "Malé",
              "medium": "Střední",
              "large": "Velké"
            }
          }
        },
        "audio": {
          "quality": {
            "label": "Kvalita",
            "values": {
              "hq": "Vysoká",
              "mq": "Střední",
              "lq": "Nízká"
            }
          },
          "duration": {
            "label": "Délka",
            "values": {
              "short": "Krátké (do 5 minut)",
              "middle": "Střední (5-20 minut)",
              "long": "Dlouhé (nad 20 minut)"
            }
          },
          "size": {
            "label": "Velikost",
            "values": {
              "small": "Malé",
              "medium": "Střední",
              "large": "Velké"
            }
          }
        },
        "archive": {
          "size": {
            "label": "Velikost",
            "values": {
              "small": "Malé",
              "medium": "Střední",
              "large": "Velké"
            }
          }
        },
        "document": {
          "size": {
            "label": "Velikost",
            "values": {
              "small": "Malé",
              "medium": "Střední",
              "large": "Velké"
            }
          }
        }
      },
      "QueryBox": {
        "placeholder": "Zadejte hledaný výraz",
        "placeholderUlozto": "Hledat soubory na Ulož.to Disk"
      }
    }
  },
  "routes": {
    "Index": {
      "unsupportedBrowser": "Váš prohlížeč je příliš starý a nemusí fungovat správně, doporučujeme vám aktualizovat.",
      "FileList": {
        "poweredBy": "Powered by GozoFinder"
      }
    },
    "UrlNotFound": {
      "title": "Stránka nenalezena"
    },
    "FileNotFound": {
      "title": "Soubor nenalezen"
    }
  }
}
