import React from 'react'

const FlagPL: React.FC = () => (
  <svg viewBox="0 0 640 480" width="1em" height="1em" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd">
      <path fill="#fff" d="M640 480H0V0h640z" />
      <path fill="#dc143c" d="M640 480H0V240h640z" />
    </g>
  </svg>
)

export default FlagPL
