import React from 'react'
import { Platform } from './model'

interface AppContextValue {
  platform: Platform
  backDomain: string | null
}

const params = new URLSearchParams(window.location.search)

const AppContext = React.createContext<AppContextValue>({
  platform: Object.values(Platform).includes(params.get('platform') as Platform) ? (params.get('platform') as Platform) : Platform.Web,
  backDomain: params.get('backDomain'),
})

export default AppContext
