import { useLayoutEffect, useRef } from 'react'
import _ from 'lodash'

const useWindowReload = (timeout: number, check: number) => {
  const lastInteraction = useRef(Date.now())

  useLayoutEffect(() => {
    const update = _.debounce(() => (lastInteraction.current = Date.now()), 10000, { leading: true })
    const events = ['click', 'keydown', 'touchstart', 'scroll']

    const intervalId = setInterval(() => {
      const now = Date.now()
      if (now - lastInteraction.current > timeout) {
        window.location.reload()
      }
    }, check) // each minute

    events.forEach((name) => {
      window.addEventListener(name, update)
    })

    return () => {
      events.forEach((name) => {
        window.removeEventListener(name, update)
      })
      clearInterval(intervalId)
    }
  }, [])
}

export default useWindowReload
