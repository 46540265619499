import React, { useContext } from 'react'
import { File } from '../../../../modules/file/model'
import Icon from '../../../Icons'
import { Service } from '../../../../modules/config/model'
import { ConfigContext } from '../../../../modules/config/context'
import { formatFileSize, formatRating, getFileIcons } from './functions'
import { IconWrapper, PropertiesList, Link } from './styled'

interface Props {
  file: File
}

export const Properties: React.FC<Props> = ({ file }) => {
  const config = useContext(ConfigContext)
  const fileIcons = getFileIcons(file)

  if (!fileIcons.length && !file.filesize && !file.rating) {
    return null
  }

  return (
    <PropertiesList className="properties">
      <ul className="break">
        {fileIcons.map((Row, i) => {
          return (
            <li key={i}>
              <IconWrapper>
                <Row.icon />
              </IconWrapper>
              <span>{Row.text}</span>
            </li>
          )
        })}
        <li className="break" />
        {file.filesize && (
          <li>
            <IconWrapper>
              <Icon.Size />
            </IconWrapper>
            <span>{formatFileSize(file.filesize)}</span>
          </li>
        )}
        {file.rating !== undefined && (
          <li data-rating={formatRating(file.rating)}>
            <IconWrapper>{file.rating < 0 ? <Icon.ThumbsDown /> : <Icon.ThumbsUp />}</IconWrapper>
            <span>{formatRating(file.rating)}</span>
          </li>
        )}
        {!!file.displayUrl && config.service === Service.MG && (
          <li>
            <Link className="link">{new URL(file.displayUrl).host}</Link>
          </li>
        )}
      </ul>
    </PropertiesList>
  )
}
