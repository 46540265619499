export interface Preview {
  readonly smallImage?: string
  readonly largeImage?: string
  readonly video?: string
}

export interface File {
  readonly id?: string
  readonly url: string
  readonly name: string
  readonly displayUrl: string
  readonly extension: string
  readonly filesize: number
  readonly rating?: number
  readonly description?: string
  readonly matchingType?: MatchingType
  readonly hash?: {
    readonly contentType: ContentType
  }
  readonly format?: {
    readonly width?: number
    readonly height?: number
    readonly duration?: number
    readonly orientation?: Orientation
    readonly streamingTypes?: StreamingType[]
  }
  readonly preview?: Preview
}

export enum StreamingType {
  Free = 'free',
  Full = 'full',
  Vipdata = 'vipdata',
}

export enum Orientation {
  Normal = 'normal',
  Rotate_90 = 'rotate_90',
  Rotate_180 = 'rotate_180',
  Rotate_270 = 'rotate_270',
  Flip_horizontal = 'flip_horizontal',
  Flip_vertical = 'flip_vertical',
  Transpose = 'transpose',
  Transverse = 'transverse',
}

export enum ContentType {
  Unknown = '',
  Video = 'video',
  Image = 'image',
  Audio = 'audio',
  Archive = 'archive',
  Document = 'document',
  Ebook = 'ebook',
  Subtitle = 'subtitle',
  Torrent = 'torrent',
}

export enum MatchingType {
  Exact = 'exact',
  Partial = 'partial',
}

export enum View {
  List,
  Grid,
}
