import React from 'react'
import { NavLink } from 'react-router-dom'
import { Area } from '../styled'
import ImageWithFallback from '../../../components/ImageWithFallback'
import useDocumentTitle from '../../../hooks/useDocumentTitle'
import { useCountry } from '../../../hooks/useCountry'
import * as img from '../images'

const Help: React.FC = () => {
  const country = useCountry()
  useDocumentTitle('Help for Gozo')

  return (
    <Area className="has-image">
      <div className="text">
        <h1>Help for Gozo</h1>

        <h2>General informations for users</h2>

        <h3>How can I filter search results to make them more accurate?</h3>
        <p>
          Filters in the search engine are used to restrict search results based on user-specified criteria. They help users narrow down the
          results and find exactly what they are looking for.
        </p>
        <p>
          The Gozo search engine can display various filters, depending on the specific implementation and available data. If filtering is
          available, a filter icon will appear on the right side below the search bar. Advanced filtering options include the following:
        </p>
        <p>
          <em>Image Filter:</em> Allows filtering images based on quality and size.
        </p>
        <div className="image-preview">
          <ImageWithFallback src={img.imgFilterImageWebp} fallbackSrc={img.imgFilterImage} alt="gozo-image-filter" />
        </div>
        <p>
          <em>Video Filter:</em> This filter helps users find videos based on length, quality, size, or playback options.
        </p>
        <div className="image-preview">
          <ImageWithFallback src={img.imgFilterVideoWebp} fallbackSrc={img.imgFilterVideo} alt="gozo-video-filter" />
        </div>
        <p>
          <em>Audio Filter:</em> This filter is specific to searching for audio files and enables you to find audio files based on selected
          criteria (such as length, quality, size, and others).
        </p>
        <p>
          <em>Document Filter:</em> In some cases, you may search for documents of a specific size. For example, if you need documents
          suitable for mobile devices with limited storage space, you can use the size filter to narrow down the results to smaller
          documents.
        </p>
        <p>
          <em>Archive Filter:</em> Archives are used for easier file manipulation. The archive filter helps you categorize search results
          based on size.
        </p>
        <p>
          <em>News Filter:</em> Enter a list of news websites and blogs you want to search. You will receive the latest articles tailored to
          your query.
        </p>
        <p>
          <em>Local Filter:</em> Allows users to restrict results to a specific geographic area. For example, when searching for a
          restaurant, users can select a filter for their country, city, or district.
        </p>
        <p>
          <em>Quality Filter:</em> Focuses on quality, which can be measured by various factors such as resolution, bitrate, and more. The
          quality filter allows users to search and restrict results based on the desired quality of media content. For example, it is
          possible to search for high-resolution videos for better display quality or search for files with higher bitrate for better audio
          quality.
        </p>
        <p>
          <em>Category Filter:</em> Allows filtering results by categories. For instance, when searching for a product on an e-commerce
          website, users can use the filter for specific categories such as clothing, electronics, home appliances, and more.
        </p>
        <p>
          <em>Price Filter:</em> Enables narrowing down search results to a specific price range. This helps users find products that fit
          their financial capabilities.
        </p>
        <p>
          <em>Language Filter:</em> Allows users to restrict search results to a specific language. This is useful when seeking information
          in a specific language.
        </p>

        <h3>How can I change the sorting order of the results?</h3>
        <p>
          The results are sorted from the most relevant to the least relevant. Changing the sorting order of the results is currently not
          possible.
        </p>

        <h3>How to display as many search results as possible?</h3>
        <p>
          When viewing search results, you can utilize different presentations depending on the type of content you are searching for. For
          image and video searches, a layout commonly used is a grid that displays multiple thumbnails in a single row across the entire
          width of the page. This way, you can see more results on one screen and navigate between them more quickly.
        </p>
        <p>
          After entering and confirming your search query, the search results will be displayed. Scroll down, and as you approach the end of
          the page, additional results will be automatically loaded.
        </p>
        <p>
          The display of search results may vary depending on design and user preferences. In a list view, each result is presented on a
          separate line, allowing users to compare and browse individual results more easily. The list view provides a brief textual excerpt
          that gives users a preview of how the search result relates to their searched keywords. This display style requires more space,
          and image previews are often displayed smaller. On the other hand, the tile view of search results allows for faster browsing and
          evaluation of a large number of results.
        </p>

        <h3>Can I use Gozo on mobile devices?</h3>
        <p>You can use Gozo on all your devices, including mobile ones. The search engine is optimized for mobile devices.</p>

        <h3>On which devices can Gozo be used?</h3>
        <p>
          Gozo supports various devices, including modern smartphones, tablets, televisions, smart watches, smart fridges, smart displays
          (e.g. Amazon Echo Show) and consoles (PlayStation, Xbox etc.). However, for optimal functionality, it is necessary to have a
          device that meets certain technical requirements. For example, some older models of smart TVs may not be compatible with our
          service.
        </p>

        <h3>What to do when inappropriate content appears in search results?</h3>
        <p>
          If you come across inappropriate or unsafe content, please do not hesitate to contact us via email at
          &#097;&#098;&#117;&#115;&#101;&#064;&#103;&#111;&#122;&#111;&#102;&#105;&#110;&#100;&#101;&#114;&#046;&#099;&#111;&#109;.
        </p>

        <h3>How much does Gozo cost?</h3>
        <p>
          For end users, Gozo is available for free. If you are interested in implementing the search engine into your product or need a
          customized search integration for your company, please check our <NavLink to={`/${country}/pricing`}>pricing offer</NavLink>. We
          are ready to provide individual solutions and search integration tailored to your specific needs.
        </p>

        <h3>I am bothered by advertisements, can I disable them?</h3>
        <p>
          Web search engines are services provided to end users for free. They offer users quick and easy access to information.
          Advertisements are a way for search engines to secure funding and cover the costs associated with the operation and development of
          these services. Advertising revenues are crucial for search engines, including Gozo, to maintain their free nature, and disabling
          them is not possible.
        </p>

        <br />
        <h2>About privacy and benefits of the Gozo search engine</h2>

        <h3>What is Gozo and how does it work?</h3>
        <p>
          Gozo is a search engine that prioritizes user privacy as its fundamental principle. Our user-friendly products enable individuals
          and businesses worldwide to protect their personal data online. We provide an alternative to dominant search engines.
        </p>
        <p>
          In addition to search functionality, we also offer powerful API solutions that allow easy integration of advanced search features
          into your application, website, or internal systems.
        </p>

        <h3>Why should I use Gozo?</h3>
        <ol className="line">
          <li>Speed and convenience - Gozo provides a fast and intuitive way to easily find the right information on the internet.</li>
          <li>High relevance of results - our sophisticated algorithm guarantees high relevance of search results.</li>
          <li>Wide range of information - Gozo searches through extensive global databases.</li>
          <li>Anonymity and security - we only collect the necessary data and do not share it with third parties.</li>
          <li>Accessibility - Gozo is available to anyone who wants to receive relevant results.</li>
        </ol>

        <h3>What data does Gozo store about me?</h3>
        <p>
          We do not store your search history or sell any information to advertisers who track you online. We do not collect or share your
          personal data.
        </p>
      </div>
      <div className="image">
        <img src={img.imgProducts} alt="" />
      </div>
    </Area>
  )
}

export default Help
