import styled from 'styled-components'

export const ThumbLink = styled.a<{
  $itemWidth: number
  $itemRadius: null | number
}>`
  position: relative;

  &:focus {
    outline: none;
  }

  .wrapper {
    height: ${({ $itemWidth }) => Math.round($itemWidth / 1.5)}px;

    ${({ $itemRadius }) =>
      $itemRadius == 0 &&
      `
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  `}

    &.light {
      background: ${({ theme }) => theme.color.bg.shade5};
    }
  }

  .play {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.color.white};
    font-size: 25px;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
  }
`

export const ThumbWrapper = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.color.bg.thumb};
  border-radius: 5px;

  video {
    max-width: 100%;
    max-height: 100%;
  }

  .loader {
    position: absolute;
    z-index: 10;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.color.bg.thumb};
    opacity: 0.7;
  }
`

export const Image = styled.img`
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  transition: opacity 0.2s ease-in-out;
`
