import React from 'react'

interface Props {
  size?: string
  radius?: number
}

const Subtitles: React.FC<Props> = ({ size, radius }) => (
  <svg viewBox="0 0 600 600" preserveAspectRatio="xMinYMid" width={size} height={size}>
    <rect rx={radius} ry={radius} width="100%" height="100%" fill="#e41937" />
    <path
      d="M442.4 136.4H156.8c-22.44 0-40.8 18.36-40.8 40.8V422c0 22.44 18.36 40.8 40.8 40.8h285.6c22.44 0 40.8-18.36 40.8-40.8V177.2c0-22.44-18.36-40.8-40.8-40.8zM279.2 279.2h-30.6V269h-40.8v61.2h40.8V320h30.6v20.4c0 12.24-8.16 20.4-20.4 20.4h-61.2c-12.24 0-20.4-8.16-20.4-20.4v-81.6c0-12.24 8.16-20.4 20.4-20.4h61.2c12.24 0 20.4 8.16 20.4 20.4v20.4zm142.8 0h-30.6V269h-40.8v61.2h40.8V320H422v20.4c0 12.24-8.16 20.4-20.4 20.4h-61.2c-12.24 0-20.4-8.16-20.4-20.4v-81.6c0-12.24 8.16-20.4 20.4-20.4h61.2c12.24 0 20.4 8.16 20.4 20.4v20.4z"
      fill="#fff"
    />
  </svg>
)

export default Subtitles
