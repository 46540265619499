import React from 'react'
import { generatePath, NavLink } from 'react-router-dom'
import { Area, Boxs } from '../styled'
import Icon from '../../../components/Icons'
import { Product } from '../../../components/ContactInquiryForm'
import useDocumentTitle from '../../../hooks/useDocumentTitle'
import { useCountry } from '../../../hooks/useCountry'

const Content: React.FC = () => {
  useDocumentTitle('Pricing')
  const country = useCountry()

  return (
    <Area>
      <h1>Pricing</h1>
      <h2>GozoFinder Custom Search</h2>

      <Boxs>
        <div className="box1">
          <h3>Basic</h3>
          <ul>
            <li className="box">
              <span>Price per month</span> <strong>100$</strong>
            </li>
            <li className="box">
              <span>Requests per day</span> <strong> 10 000 + $0.001 each other</strong>
            </li>
            <li className="box">
              <span>Objects</span> <strong>1M</strong>
            </li>
          </ul>
          <ul className="line">
            <li className="red">
              <Icon.Close /> no custom ads
            </li>
          </ul>
          <div className="buttons">
            <NavLink to={generatePath(`/${country}/inquiry/` + Product.Premium)} className="button">
              Buy
            </NavLink>
          </div>
        </div>
        <div className="box2">
          <h3>Premium</h3>
          <ul>
            <li className="box">
              <span>Price per month</span> <strong>500$</strong>
            </li>
            <li className="box">
              <span>Requests per day</span> <strong>1M + $0.001 each other</strong>
            </li>
            <li className="box">
              <span>Objects</span> <strong>10M</strong>
            </li>
          </ul>
          <ul className="line">
            <li>
              <Icon.Check /> GozoAds and third party ads (Google AdWords and Microsoft Advertising currently supported)
            </li>
          </ul>
          <div className="buttons">
            <NavLink to={generatePath(`/${country}/inquiry/` + Product.Premium)} className="button">
              Buy
            </NavLink>
          </div>
        </div>
        <div className="box3">
          <h3>Custom</h3>
          <ul>
            <li className="box">
              <span>Price per month</span> <strong>custom</strong>
            </li>
            <li className="box">
              <span>Requests per day</span> <strong>custom</strong>
            </li>
            <li className="box">
              <span>Objects</span> <strong>custom</strong>
            </li>
          </ul>
          <ul className="line">
            <li>
              <Icon.Check /> Include custom ads
            </li>
            <li>
              <Icon.Check /> Custom search design
            </li>
            <li>
              <Icon.Check /> Custom localization
            </li>
            <li>
              <Icon.Check /> Powerful DDoS protection and AI firewall
            </li>
          </ul>
          <div className="buttons">
            <NavLink to={generatePath(`/${country}/inquiry/` + Product.Custom)} className="button">
              Enquire
            </NavLink>
          </div>
        </div>
      </Boxs>
    </Area>
  )
}

export default Content
