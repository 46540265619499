import React from 'react'
import { NavLink } from 'react-router-dom'
import { Area } from '../styled'
import { imgAbout } from '../images'
import useDocumentTitle from '../../../hooks/useDocumentTitle'
import { useCountry } from '../../../hooks/useCountry'

const Content: React.FC = () => {
  useDocumentTitle('About Gozo')
  const country = useCountry()

  return (
    <Area className="has-image">
      <div className="text">
        <h1>About Gozo</h1>
        <p>
          Gozo is a privacy search service centered on the fundamental principle of prioritizing privacy. Our array of user-friendly
          products enables individuals worldwide to safeguard their personal data online.
        </p>
        <p>
          There is a significant shortage of programmatic access to web search engines for businesses, while most people rely on a handful
          of dominant search engines like Google and Bing.
        </p>
        <p>
          <strong className="decor">&quot;To provide an alternative, we created Gozo.&quot;</strong>
        </p>

        <p>
          Our cutting-edge technology allows us to provide search-as-a-service to customers at any level, from individual app developers and
          start-up companies to large enterprises. Gozo Custom Search can be utilized for straightforward in-app or site-specific searches,
          search engines spanning a company's websites, or even extensive, intricate search engines focusing on specific parts of the web,
          including the entire World Wide Web itself as Gozo Web Search.
        </p>

        <p>
          In addition to our custom search offerings, we provide a suite of powerful API solutions. These APIs enable seamless integration
          of advanced search capabilities into your app, website, or internal systems, unlocking access to a wealth of information from
          billions of web pages, news articles, and images.
        </p>

        <p>
          Gozo is a privacy-focused search engine. We don't store your search history, nor do we sell any information to advertisers who
          track you online. We neither collect nor share your personal data.
        </p>

        <p>Explore our products:</p>

        <h2>Gozo Web Search</h2>
        <p>
          Gozo Web Search is crafted to optimize your web navigation experience, employing advanced algorithms and indexing for accurate,
          pertinent results. Access a vast array of websites, forums, and blogs to locate information tailored to your requirements while
          enjoying the benefits of sophisticated filtering and flexible ranking options.
        </p>
        <p>
          Stay updated with real-time news, developments, and trending topics. Prioritize privacy with Gozo's Web Search, which secures your
          search data to maintain confidentiality and protection. Delight in an elevated level of efficiency and ease as you traverse the
          web and effortlessly uncover the information you desire.
        </p>

        <h2>Custom Search - Construct large-scale, personalized web search engines.</h2>
        <p>
          Create your own subject- or domain-specific custom web search engine, such as a finance, news, or large-scale search engine
          focused on a specific portion of the World Wide Web or the entirety of the web.
        </p>

        <h2>Search API - Your portal to the entire web.</h2>
        <p>
          Enrich your app or website with data and content from billions of web pages, news articles, and images using a straightforward API
          call. Seamlessly integrate advanced search capabilities.
        </p>

        <p>
          To learn more about our products, visit the <NavLink to={`/${country}/products`}>product page</NavLink>.
        </p>
      </div>
      <div className="image">
        <img src={imgAbout} alt="" />
      </div>
    </Area>
  )
}

export default Content
