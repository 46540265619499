import React from 'react'

interface Props {
  size?: string
  radius?: number
}

const Archive: React.FC<Props> = ({ size, radius }) => (
  <svg viewBox="0 0 600 600" preserveAspectRatio="xMinYMid" width={size} height={size}>
    <rect rx={radius} ry={radius} width="100%" height="100%" fill="#f9b013" />
    <g fill="#fff">
      <path d="M493.99 181.58a19.39 19.39 0 00-.455-2.555v-.49a19.425 19.425 0 00-.875-2.52 19.39 19.39 0 00-1.19-2.31l-.28-.455a19.635 19.635 0 00-1.54-2.1l-.315-.35a19.565 19.565 0 00-1.75-1.75l-.175-.175-77.91-66.22A19.53 19.53 0 00396.97 98H195.16a19.53 19.53 0 00-12.67 4.655L105 168.735l-.175.175a19.67 19.67 0 00-1.75 1.715l-.315.35a19.565 19.565 0 00-1.54 2.1l-.28.455a19.425 19.425 0 00-1.19 2.31 19.425 19.425 0 00-1.05 2.66v.49a19.39 19.39 0 00-.455 2.555 19.705 19.705 0 000 2.065v269.5a19.53 19.53 0 0019.285 19.39H220.5l4.935-39.06H137.06V203h159.005v23.8h33.18V203H455v230.545h-88.2l4.97 38.955H474.6a19.53 19.53 0 0019.53-19.53v-269.5a19.74 19.74 0 00-.14-1.89zm-197.96-17.5v-14h-33.18v14h-92.26l31.745-27.055h93.73v12.88h33.18V137.06h60.515l31.745 27.055z" />
      <path d="M330.75 330.19a13.335 13.335 0 00-13.055-11.69h-43.26a13.335 13.335 0 00-13.055 11.83l-20.51 161.945a14 14 0 003.15 10.71 13.02 13.02 0 009.905 4.515h84.28a13.02 13.02 0 009.905-4.62 14 14 0 003.15-10.71zM318.5 483.525h-44.905a7 7 0 01-6.615-7.77l1.82-14.49a6.755 6.755 0 016.615-5.985h41.265a6.755 6.755 0 016.615 5.985l1.82 14.49a7 7 0 01-6.615 7.77zM262.85 274.54h33.18v23.8h-33.18zM296.065 250.74h33.18v23.8h-33.18zM262.85 226.94h33.18v23.8h-33.18z" />
    </g>
  </svg>
)

export default Archive
