import styled, { css } from 'styled-components'

export const SearchSuggest = styled.div<{
  $isHome?: boolean
}>`
  position: absolute;
  z-index: 100;
  top: 40px;
  width: calc(100% - 40px);
  padding-top: 3px;
  border-top: none;
  margin-top: -3px;
  background-color: ${({ theme }) => theme.color.bg.input};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 2px 2px ${({ theme }) => theme.color.shadow};

  ${({ $isHome }) =>
    $isHome &&
    css`
      @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
        top: 45px;
        width: calc(100% - 50px);
      }
    `}

  > div {
    padding: 8px 15px;
    cursor: pointer;

    &:hover,
    &.active {
      background-color: ${({ theme }) => theme.color.bg.shade5};

      &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
`
