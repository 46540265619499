import styled, { css } from 'styled-components'

export const Button = styled.div<{
  $isHome?: boolean
}>`
  position: absolute;
  z-index: 20;
  top: 21px;
  right: 10px;
  display: flex;
  cursor: pointer;
  font-size: 20px;

  ${({ $isHome }) =>
    $isHome
      ? css`
          top: 20px;
          right: 20px;
        `
      : css`
          @media screen and (min-width: ${({ theme }) => theme.layout.sizes.lg}px) {
            top: 30px;
            right: 20px;
          }
        `};
`

export const Wrapper = styled.div<{
  $hasBranding?: boolean
  $topOffset: number
  $compensationWidth: number
}>`
  position: fixed;
  z-index: 100;
  top: ${({ $topOffset }) => $topOffset}px;
  left: 0;
  width: ${({ $compensationWidth }) => ($compensationWidth > 0 ? 'calc(100% - ' + $compensationWidth + 'px)' : '100%')};
  max-width: ${({ $hasBranding, theme }) => ($hasBranding ? theme.layout.width + 'px' : 100 + '%')};
  height: 100%;
  background-color: ${({ theme }) => theme.color.transparent.overlay};
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;

  @media screen and (min-width: ${({ theme }) => theme.layout.width}px) {
    left: ${({ $hasBranding }) => ($hasBranding ? 50 + '%' : 0 + '%')};
    margin-left: ${({ $hasBranding, $compensationWidth, theme }) =>
      $hasBranding ? -theme.layout.width / 2 - $compensationWidth / 2 + 'px' : 'auto'};
  }

  &.is-open {
    opacity: 1;
    overflow-y: auto;
    pointer-events: auto;

    @media screen and (min-width: ${({ theme }) => theme.layout.width}px) {
      left: ${({ $hasBranding }) => ($hasBranding ? 50 + '%' : 0 + '%')};
      margin-left: ${({ $hasBranding, $compensationWidth, theme }) =>
        $hasBranding ? -theme.layout.width / 2 - $compensationWidth / 2 + 'px' : 'auto'};
    }
  }
`

export const Controls = styled.div<{ $topOffset?: number }>`
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  min-height: ${({ $topOffset }) => 542 + ($topOffset || 0)}px;
  box-sizing: border-box;
  padding: 15px 12px 20px 20px;
  background-color: ${({ theme }) => theme.color.bg.main};
  overflow-y: auto;

  @media screen and (min-width: ${({ theme }) => theme.layout.sizes.lg}px) {
    padding: 25px 12px 20px 20px;
  }
`

export const Sticky = styled.div`
  padding-top: 15px;
`

export const AboutUs = styled.span`
  strong {
    display: block;
    margin-bottom: 15px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      &:not(:last-child) {
        margin-bottom: 15px;
      }

      a {
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.color.text};
        gap: 10px;

        &.active {
          color: ${({ theme }) => theme.color.link};
        }
      }

      &.is-active {
        a {
          color: ${({ theme }) => theme.color.link};
        }
      }

      span {
        flex: 1;
      }
    }
  }
`
