import { Country, Language } from '../language/model'
import { Service } from './model'

interface DomainConfig {
  service: Service
  language: Language
  country: Country
}

export const getDomainsConfig = (): DomainConfig[] => {
  return [
    {
      service: Service.MG,
      language: Language.EN,
      country: Country.EN,
    },
    {
      service: Service.Ulozto,
      language: Language.CS,
      country: Country.CZ,
    },
    {
      service: Service.Ulozto,
      language: Language.SK,
      country: Country.SK,
    },
    {
      service: Service.Ulozto,
      language: Language.EN,
      country: Country.EN,
    },
    {
      service: Service.Ulozto,
      language: Language.PL,
      country: Country.PL,
    },
  ]
}

export const getDefaultDomainConfig = (): DomainConfig => getDomainsConfig()[0]
