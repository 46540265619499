import React from 'react'

interface Props {
  size?: string
  radius?: number
}

const M3U: React.FC<Props> = ({ size, radius }) => (
  <svg viewBox="0 0 600 600" preserveAspectRatio="xMinYMid" width={size} height={size}>
    <rect rx={radius} ry={radius} width="100%" height="100%" fill="#6fccd7" />
    <path
      d="M306.04 357.47c0 1.12-.18 2.2-.41 3.26-.36 2.69-1.16 5.24-2.5 7.69-7.76 13.96-29.85 16.46-49.33 5.61-19.46-10.86-28.93-30.96-21.15-44.89 7.25-12.99 26.89-16.04 45.29-7.65v-49.85l-86.93-21.96v79.63c0 .28-.08.59-.08.88.1 4.03-.75 7.95-2.71 11.46-7.77 13.96-29.86 16.46-49.33 5.6-19.46-10.84-28.95-30.96-21.16-44.92 7.23-12.95 26.82-16.02 45.2-7.67v-90.53c0-4.33 1.97-8.39 5.37-11.04 3.38-2.68 7.81-3.65 12-2.64l115.02 28.21c6.29 1.53 10.7 7.19 10.7 13.64l.02 125.17zm179.8-110.4c0-9.39-7.62-17.01-17.01-17.01h-121.9c-9.39 0-17.01 7.62-17.01 17.01 0 9.38 7.62 17.01 17.01 17.01h121.89c9.41 0 17.02-7.64 17.02-17.01zm0 53.86c0-9.38-7.62-17.01-17.01-17.01h-121.9c-9.39 0-17.01 7.62-17.01 17.01s7.62 17.01 17.01 17.01h121.89c9.41 0 17.02-7.63 17.02-17.01zm0 53.86c0-9.38-7.62-17.01-17.01-17.01h-121.9c-9.39 0-17.01 7.63-17.01 17.01s7.62 17.01 17.01 17.01h121.89c9.41.02 17.02-7.62 17.02-17.01z"
      fill="#fff"
    />
  </svg>
)

export default M3U
