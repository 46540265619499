import React, { useContext, useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SearchQueryBox from '../../../components/Search/QueryBox'
import Menu from '../../../components/Menu'
import MenuButton from '../../../components/Menu/Button'
import SearchFilterBox from '../../../components/Search/FilterBox'
import { Images } from '../../Images'
import { ConfigContext } from '../../../modules/config/context'
import useWindowSize from '../../../hooks/useWindowSize'
import { useStore } from '../../../hooks/useStore'
import { useCountry } from '../../../hooks/useCountry'
import supportedBrowsers from '../../../constants/supportedBrowsers'
import AppStore from '../../../modules/app/store'
import { getServiceRoute } from '../../../modules/router/service'
import { Service } from '../../../modules/config/model'
import { DeprecatedBrowsers, Header, Logo, Main, Wrapper, WrapperInner } from './styled'

enum ScrollDirection {
  None,
  Up,
  Down,
}

interface Props {
  withSearch?: boolean
  outlet?: JSX.Element
}

const LayoutHeader: React.FC<Props> = ({ withSearch, outlet }) => {
  const { t } = useTranslation()
  const windowSize = useWindowSize()
  const config = useContext(ConfigContext)
  const appStore = useStore(AppStore)
  const [scrollDirection, setScrollDirection] = useState(ScrollDirection.None)
  const [headerFixing, setHeaderFixing] = useState(false)
  const areToolsOpen = useRef(false)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const headerRef = useRef<HTMLElement | null>(null)
  const mainRef = useRef<HTMLElement | null>(null)
  const country = useCountry()

  const headerTop = headerRef.current ? headerRef.current.getBoundingClientRect().top : 0
  const headerHeight = headerRef.current ? parseInt(getComputedStyle(headerRef.current as HTMLDivElement).height) : 0

  const shadowClass = 'shadow'
  useEffect(() => {
    let lastScrollY = window.scrollY
    let totalDistanceScrolled = 0

    const handler = () => {
      if (headerRef.current === null || mainRef.current === null || wrapperRef.current === null) {
        return
      }

      const scrollY = window.scrollY
      const direction = scrollY > lastScrollY ? ScrollDirection.Down : ScrollDirection.Up

      // if we click on filter, scroll position changes and menu closes, even if it should stay open
      // we want to ignore this change but only once (after click is triggered)
      if (!areToolsOpen.current) {
        if (direction !== scrollDirection && Math.abs(scrollY - lastScrollY) > 10) {
          setScrollDirection(direction)
        }
      } else {
        // if filters tab is open, we want to close menu after some bigger distance to avoid closing menu
        // just by opening filters
        totalDistanceScrolled += Math.abs(scrollY - lastScrollY)
        if (totalDistanceScrolled > 100) {
          totalDistanceScrolled = 0
          // don't care filters are open, we want to hide menu normally from now
          areToolsOpen.current = false
        }
      }
      lastScrollY = scrollY > 0 ? scrollY : 0

      const isFixed = headerRef.current?.classList.contains(shadowClass)
      const wrapperTop = parseInt(getComputedStyle(wrapperRef.current as HTMLDivElement).marginTop) + headerHeight

      if ((window.scrollY == 0 || Math.round(window.scrollY) <= wrapperTop) && isFixed) {
        setHeaderFixing(false)
        headerRef.current?.classList.remove(shadowClass)
      } else if (Math.round(window.scrollY) >= wrapperTop && !isFixed) {
        setHeaderFixing(true)
        headerRef.current?.classList.remove(shadowClass)
      }
    }

    window.addEventListener('scroll', handler)

    return () => window.removeEventListener('scroll', handler)
  }, [headerRef.current, mainRef.current, wrapperRef.current])

  const isMobile = useMediaQuery({
    query: '(min-width: 0px) and (max-width: 1030px)',
  })

  let isService = ''
  if (config.service == Service.Ulozto) {
    isService = 'service'
  }

  return (
    <>
      {!supportedBrowsers.test(navigator.userAgent) && <DeprecatedBrowsers>{t('routes.Index.unsupportedBrowser')}</DeprecatedBrowsers>}
      <Wrapper>
        <WrapperInner ref={wrapperRef}>
          <Menu hasBranding={false} compensationWidth={appStore.scrollBarCompensation} topOffset={headerTop} isHome={false} />
          <Header
            ref={headerRef}
            $hasFilters={withSearch}
            $compensationWidth={appStore.scrollBarCompensation}
            $containerHeight={headerHeight}
            className={(scrollDirection === ScrollDirection.Down ? 'down' : 'up') + ' ' + (headerFixing ? shadowClass : '')}
          >
            <div className="top">
              <Logo data-testid="logo">
                <a href={getServiceRoute(config.service, country)} className={isService}>
                  {isMobile ? <Images.LogoSymbol /> : <Images.Logo />}
                </a>
              </Logo>
              {withSearch ? <SearchQueryBox isHome={false} /> : <div>&nbsp;</div>}
              <MenuButton compensationWidth={appStore.scrollBarCompensation} isHome={false} />
            </div>
            {withSearch && <SearchFilterBox wrapperRef={wrapperRef} onToolsTrigger={(isOpen) => (areToolsOpen.current = isOpen)} />}
          </Header>
          <Main className="main" $setMinHeight={windowSize.height - headerTop - headerHeight - 20} $hasFilters={withSearch} ref={mainRef}>
            {outlet ? outlet : <Outlet />}
          </Main>
        </WrapperInner>
      </Wrapper>
    </>
  )
}

export default LayoutHeader
