import React from 'react'
import { useParams } from 'react-router-dom'
import { Area } from '../styled'
import ContactInquiryForm, { FormPurpose, Product } from '../../../components/ContactInquiryForm'
import UrlNotFound from '../../UrlNotFound'
import useDocumentTitle from '../../../hooks/useDocumentTitle'

const Inquiry: React.FC = () => {
  const params = useParams<{ product: Product }>()

  if (!params.product || !Object.values(Product).includes(params.product)) {
    return <UrlNotFound />
  }

  useDocumentTitle('Inquiry')

  return (
    <Area>
      <h1>Inquiry</h1>

      <ContactInquiryForm purpose={FormPurpose.Order} product={params.product} />
    </Area>
  )
}

export default Inquiry
