import { useState, useEffect } from 'react'
import { usePageVisibility } from 'react-page-visibility'

// check whether current window was visited by user at least once
// users tend to open multiple new tabs without using them
// we can conditionally load data or show components only when the window was already visited
export const useWindowVisited = (callback?: () => void): boolean => {
  const [windowVisited, setWindowVisited] = useState(false)
  const isVisible = usePageVisibility()

  useEffect(() => {
    if (!windowVisited && isVisible) {
      setWindowVisited(true)
      // call callback if window was visited for the first time
      callback?.()
    }
  }, [isVisible])

  return windowVisited
}
