export const MaxQueryLength = 200

export type SearchQuery = string
export type SearchFilter = {
  readonly [key: string]: SearchFilterType | Record<string, string[]> | undefined
  readonly contentType: SearchFilterType
  readonly video?: {
    readonly quality: SearchFilterVideoQuality[]
    readonly duration: SearchFilterVideoDuration[]
    readonly streaming: SearchFilterVideoStreaming[]
    readonly size: SearchFilterVideoSize[]
  }
  readonly image?: {
    readonly quality: SearchFilterImageQuality[]
    readonly size: SearchFilterImageSize[]
  }
  readonly audio?: {
    readonly quality: SearchFilterAudioQuality[]
    readonly duration: SearchFilterAudioDuration[]
    readonly size: SearchFilterAudioSize[]
  }
  readonly document?: {
    readonly size: SearchFilterDocumentSize[]
  }
  readonly archive?: {
    readonly size: SearchFilterArchiveSize[]
  }
}

export enum SearchFilterType {
  All = 'all',
  Video = 'video',
  Image = 'image',
  Archive = 'archive',
  Audio = 'audio',
  Document = 'document',
}

export enum SearchFilterVideoQuality {
  Lq = 'lq',
  Hd = 'hd',
  FullHd = 'fullhd',
}

export enum SearchFilterVideoDuration {
  Short = 'short',
  Middle = 'middle',
  Long = 'long',
  Longest = 'longest',
}

export enum SearchFilterVideoStreaming {
  Available = 'available',
  Unavailable = 'unavailable',
}

export enum SearchFilterVideoSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum SearchFilterImageQuality {
  Low = 'lq',
  Middle = 'mq',
  High = 'hq',
}

export enum SearchFilterImageSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum SearchFilterAudioQuality {
  Low = 'lq',
  Middle = 'mq',
  High = 'hq',
}

export enum SearchFilterAudioDuration {
  Short = 'short',
  Middle = 'middle',
  Long = 'long',
}

export enum SearchFilterAudioSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum SearchFilterDocumentSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum SearchFilterArchiveSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}
