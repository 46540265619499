export enum FileIconType {
  PICTURE = 'picture',
  ZIP = 'file-zip',
  WORD = 'file-word',
  M3U = 'm3u',
  PPT = 'file-ppt',
  PDF = 'file-pdf',
  HTML = 'file-markdown',
  EXCEL = 'file-excel',
  TEXT = 'file-text',
  FLAC = 'flac',
  MP3 = 'mp3',
  WAV = 'wav',
  SOUND = 'file-sound',
  VIDEO = 'video',
  TORRENT = 'torrent',
  SUBTITLES = 'subtitles',
  APK = 'apk',
  ISO = 'iso',
  EBOOK = 'ebook',
  EXE = 'exe',
  UNKNOWN = 'file-unknown',
  DEFAULT = 'file',
}

export const resolveFileIconType = (extension: string): FileIconType => {
  switch (extension?.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'tiff':
      return FileIconType.PICTURE
    case 'zip':
    case 'rar':
      return FileIconType.ZIP
    case 'exe':
      return FileIconType.EXE
    case 'doc':
    case 'docx':
      return FileIconType.WORD
    case 'm3u':
      return FileIconType.M3U
    case 'azw':
    case 'lrf':
    case 'lrs':
    case 'lrx':
    case 'djvu':
    case 'epub':
    case 'pdb':
    case 'fb2':
    case 'ibooks':
    case 'kf8':
    case 'lit':
    case 'mobi':
      return FileIconType.EBOOK
    case 'ppt':
    case 'pptx':
      return FileIconType.PPT
    case 'pdf':
      return FileIconType.PDF
    case 'html':
      return FileIconType.HTML
    case 'xls':
    case 'xslx':
      return FileIconType.EXCEL
    case 'txt':
    case 'rtf':
      return FileIconType.TEXT
    case 'mp3':
      return FileIconType.MP3
    case 'wav':
      return FileIconType.WAV
    case 'flac':
      return FileIconType.FLAC
    case 'm4a':
    case 'aac':
      return FileIconType.SOUND
    case 'mp4':
    case 'avi':
    case 'mov':
    case 'mkv':
    case 'avchd':
    case 'flv':
    case 'wmv':
      return FileIconType.VIDEO
    case 'torrent':
      return FileIconType.TORRENT
    case 'srt':
    case 'sub':
    case 'sbv':
      return FileIconType.SUBTITLES
    case 'apk':
      return FileIconType.APK
    case 'iso':
      return FileIconType.ISO
    case null:
    case '':
      return FileIconType.UNKNOWN
    default:
      return FileIconType.DEFAULT
  }
}
