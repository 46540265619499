import type { URLSearchParamsInit } from 'react-router-dom'
import { createSearchParams } from 'react-router-dom'
import _ from 'lodash'
import {
  MaxQueryLength,
  SearchFilter,
  SearchFilterArchiveSize,
  SearchFilterAudioDuration,
  SearchFilterAudioQuality,
  SearchFilterAudioSize,
  SearchFilterDocumentSize,
  SearchFilterImageQuality,
  SearchFilterImageSize,
  SearchFilterType,
  SearchFilterVideoDuration,
  SearchFilterVideoQuality,
  SearchFilterVideoSize,
  SearchFilterVideoStreaming,
  SearchQuery,
} from '../../modules/search/model'
import { SearchStoreDefaults } from '../../modules/search/store'
import { createSearchFilter } from '../../modules/search/factory'

export enum URLParamName {
  Query = 'query',
  Type = 'type',
  Quality = 'quality',
  Duration = 'duration',
  Streaming = 'streaming',
  Size = 'size',
}

interface AppSearchParams {
  query: SearchQuery
  filter: SearchFilter
}

export const createAppSearchParams = (query: SearchQuery, filter: SearchFilter): AppSearchParams => ({ query, filter })

function filterValues<Type>(enumValues: string[], params: string[]): Type[] {
  return params.filter((value) => enumValues.includes(value)) as unknown as Type[]
}

export const createInitialAppSearchParams = (searchParams: URLSearchParams): AppSearchParams => {
  const initialQuery = (searchParams.get(URLParamName.Query) || SearchStoreDefaults.searchQuery).substring(0, MaxQueryLength)

  let initialFilter = SearchStoreDefaults.searchFilter
  const queryType = searchParams.get(URLParamName.Type)
  if (queryType && (Object.values(SearchFilterType) as string[]).includes(queryType)) {
    initialFilter = createSearchFilter(queryType as SearchFilterType)

    if (initialFilter.contentType === SearchFilterType.Video) {
      initialFilter = {
        ...initialFilter,
        video: {
          quality: filterValues(Object.values(SearchFilterVideoQuality), searchParams.getAll(URLParamName.Quality)),
          duration: filterValues(Object.values(SearchFilterVideoDuration), searchParams.getAll(URLParamName.Duration)),
          streaming: filterValues(Object.values(SearchFilterVideoStreaming), searchParams.getAll(URLParamName.Streaming)),
          size: filterValues(Object.values(SearchFilterVideoSize), searchParams.getAll(URLParamName.Size)),
        },
      }
    }

    if (initialFilter.contentType === SearchFilterType.Image) {
      initialFilter = {
        ...initialFilter,
        image: {
          quality: filterValues(Object.values(SearchFilterImageQuality), searchParams.getAll(URLParamName.Quality)),
          size: filterValues(Object.values(SearchFilterImageSize), searchParams.getAll(URLParamName.Size)),
        },
      }
    }

    if (initialFilter.contentType === SearchFilterType.Audio) {
      initialFilter = {
        ...initialFilter,
        audio: {
          quality: filterValues(Object.values(SearchFilterAudioQuality), searchParams.getAll(URLParamName.Quality)),
          duration: filterValues(Object.values(SearchFilterAudioDuration), searchParams.getAll(URLParamName.Duration)),
          size: filterValues(Object.values(SearchFilterAudioSize), searchParams.getAll(URLParamName.Size)),
        },
      }
    }

    if (initialFilter.contentType === SearchFilterType.Archive) {
      initialFilter = {
        ...initialFilter,
        archive: {
          size: filterValues(Object.values(SearchFilterArchiveSize), searchParams.getAll(URLParamName.Size)),
        },
      }
    }

    if (initialFilter.contentType === SearchFilterType.Document) {
      initialFilter = {
        ...initialFilter,
        document: {
          size: filterValues(Object.values(SearchFilterDocumentSize), searchParams.getAll(URLParamName.Size)),
        },
      }
    }
  }

  return { query: initialQuery, filter: initialFilter }
}

export const createSearchURL = (actualParams: AppSearchParams): string => {
  const urlParams: URLSearchParamsInit = {}
  if (actualParams.query !== SearchStoreDefaults.searchQuery) {
    urlParams[URLParamName.Query] = actualParams.query.substring(0, MaxQueryLength)
  }

  if (!_.isEqual(actualParams.filter, SearchStoreDefaults.searchFilter)) {
    urlParams[URLParamName.Type] = actualParams.filter.contentType

    if (actualParams.filter.contentType === SearchFilterType.Video) {
      urlParams[URLParamName.Duration] = actualParams.filter.video?.duration as string[]
      urlParams[URLParamName.Quality] = actualParams.filter.video?.quality as string[]
      urlParams[URLParamName.Streaming] = actualParams.filter.video?.streaming as string[]
      urlParams[URLParamName.Size] = actualParams.filter.video?.size as string[]
    }

    if (actualParams.filter.contentType === SearchFilterType.Image) {
      urlParams[URLParamName.Quality] = actualParams.filter.image?.quality as string[]
      urlParams[URLParamName.Size] = actualParams.filter.image?.size as string[]
    }

    if (actualParams.filter.contentType === SearchFilterType.Audio) {
      urlParams[URLParamName.Quality] = actualParams.filter.audio?.quality as string[]
      urlParams[URLParamName.Duration] = actualParams.filter.audio?.duration as string[]
      urlParams[URLParamName.Size] = actualParams.filter.audio?.size as string[]
    }

    if (actualParams.filter.contentType === SearchFilterType.Archive) {
      urlParams[URLParamName.Size] = actualParams.filter.archive?.size as string[]
    }

    if (actualParams.filter.contentType === SearchFilterType.Document) {
      urlParams[URLParamName.Size] = actualParams.filter.document?.size as string[]
    }
  }

  const url = createSearchParams(urlParams).toString()
  return url ? '?' + url : ''
}
