import styled from 'styled-components'

export const NameWrapper = styled.div`
  h2 {
    display: inline;
    display: -webkit-box;
    overflow: hidden;
    max-width: 100%;
    -webkit-box-orient: vertical;
    font-weight: normal;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  a {
    margin: 0;

    &:hover {
      color: ${({ theme }) => theme.color.linkHover};
    }
    &:focus {
      outline: none;
      text-decoration: underline;
    }
  }
`

export const Link = styled.span`
  display: block;
  color: ${({ theme }) => theme.color.green};
  font-size: 12px;

  @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
    font-size: 14px;
  }
`
