import { SearchFilter, SearchFilterType } from './model'

export const createSearchFilter = (contentType: SearchFilterType): SearchFilter => {
  const obj = { contentType }
  switch (contentType) {
    case SearchFilterType.Video:
      return {
        ...obj,
        video: {
          quality: [],
          duration: [],
          streaming: [],
          size: [],
        },
      }
    case SearchFilterType.Image:
      return {
        ...obj,
        image: {
          quality: [],
          size: [],
        },
      }
    case SearchFilterType.Audio:
      return {
        ...obj,
        audio: {
          quality: [],
          duration: [],
          size: [],
        },
      }
    case SearchFilterType.Archive:
      return {
        ...obj,
        archive: {
          size: [],
        },
      }
    case SearchFilterType.Document:
      return {
        ...obj,
        document: {
          size: [],
        },
      }
    default:
      return obj
  }
}
