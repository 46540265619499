import React from 'react'

interface Props {
  size?: string
  radius?: number
}

const EXE: React.FC<Props> = ({ size, radius }) => (
  <svg viewBox="0 0 600 600" preserveAspectRatio="xMinYMid" width={size} height={size}>
    <rect rx={radius} ry={radius} width="100%" height="100%" fill="#567d73" />
    <path
      d="M190.4 376q-13.2 0-22.6-9.4t-9.4-22.6V203.2q0-13.2 9.4-22.6t22.6-9.4H408q13.2 0 22.6 9.4t9.4 22.6V344q0 13.2-9.4 22.6T408 376H190.4zM184 203.2V344q0 2.6 1.9 4.5t4.5 1.9H408q2.6 0 4.5-1.9t1.9-4.5V203.2q0-2.6-1.9-4.5t-4.5-1.9H190.4q-2.6 0-4.5 1.9t-1.9 4.5zm275.2 185.6h32V408q0 8-9.4 13.6t-22.6 5.6h-320q-13.2 0-22.6-5.6t-9.4-13.6v-19.2h352zm-144 19.2q3.2 0 3.2-3.2t-3.2-3.2h-32q-3.2 0-3.2 3.2t3.2 3.2h32z"
      fill="#fff"
    />
  </svg>
)

export default EXE
