import * as Sentry from '@sentry/browser'
import axios, { AxiosHeaders } from 'axios'
import _ from 'lodash'
import type { AxiosRequestConfig, AxiosResponse, RawAxiosRequestHeaders } from 'axios'
import { SessionId } from '../user/model'
import { isDevelopment } from '../config/application_mode'
import { encode, decode, isObfuscationEnabled } from './obfuscation'
import { Method } from './model'
import { enableMocks } from './mocks/mocks'

const whatIsThis = '_ieZB!?SK{_3=|~' // do not name it to something like "key"

export interface ApiResponse<T = unknown> {
  readonly status: number
  readonly data?: T
}

interface ApiClientErrorResponse extends ApiResponse {
  readonly data: {
    readonly code: number
    readonly message: string
  }
}

export interface ApiRequestPromise<T> extends Promise<T> {
  cancel: () => void
}

export class ApiClientError extends Error {
  constructor(m: string, readonly response: ApiClientErrorResponse) {
    super(m)
    this.name = 'ApiClientError'
    Object.setPrototypeOf(this, ApiClientError.prototype)
  }
}

export const request = (url: string, sessionId: SessionId, init?: AxiosRequestConfig): ApiRequestPromise<ApiResponse> => {
  const controller = new AbortController()

  const promise = axios({
    url: window.Config.BACKEND_API_URL + (isObfuscationEnabled() ? encode(url) : url),
    method: Method.GET,
    signal: controller.signal,
    ...init,
    headers: {
      'X-Auth-Token': whatIsThis,
      'X-Session-Id': sessionId,
      ...getRawAxiosHeaders(init),
    },
  })
    .then(createApiResponse)
    .catch((error) => {
      Sentry.setExtra('Api error', { error })

      const errResponse = error.response
      if (errResponse && errResponse.status >= 400 && errResponse.status <= 499) {
        const response = createApiResponse(errResponse)

        Sentry.setExtra('Api error response', {
          request: { url },
          response: response,
        })

        const isApiError = _.isObject(response.data) && 'code' in response.data && 'message' in response.data
        if (isApiError) {
          throw new ApiClientError('Api client error', response as ApiClientErrorResponse)
        }
      }

      throw error
    }) as ApiRequestPromise<ApiResponse>

  promise.cancel = () => controller.abort()

  return promise
}

export const createRequestInit = (method: Method, data?: Record<string, unknown>): AxiosRequestConfig => {
  const init: AxiosRequestConfig = {
    method: method,
  }

  if (data) {
    init.data = isObfuscationEnabled() ? encode(JSON.stringify(data)) : data
    init.headers = {
      'Content-Type': isObfuscationEnabled() ? 'application/octet-stream' : 'application/json',
    }
  }

  return init
}

const createApiResponse = (response: AxiosResponse): ApiResponse => {
  return {
    status: response.status,
    data: response.data ? (isObfuscationEnabled() ? JSON.parse(decode(response.data)) : response.data) : undefined,
  }
}

const getRawAxiosHeaders = (requestConfig?: AxiosRequestConfig): RawAxiosRequestHeaders => {
  if (requestConfig?.headers instanceof AxiosHeaders) {
    return requestConfig.headers.toJSON()
  } else if (requestConfig?.headers) {
    return requestConfig.headers
  }

  return {}
}

export interface MockApiInputSuccess<T> {
  data: T
  delay?: number
}

export interface MockApiInputError {
  error: Error
  delay?: number
}

type MockApiInput<T> = MockApiInputSuccess<T> | MockApiInputError

/**
 * Mocks an API response with a given data
 * usage:
 *
 * const mockResponse = getMockedApiResponse<ApiResponse<UserData>>({
 *  data: mockUserApiData,
 *  delay: 100
 * });
 *
 * // error
 * const mockResponse = getMockedApiResponse<ApiResponse<UserData>>({
 *  error: new Error('Something went wrong'),
 *  delay: 100
 * });
 *
 * @param mockInput
 */
export const getMockedApiResponse = <T>(mockInput: MockApiInput<T>): ApiRequestPromise<T> => {
  const controller = new AbortController()
  const DEFAULT_DELAY = 300

  const promise = new Promise<T>((resolve, reject) => {
    setTimeout(() => {
      if ('error' in mockInput) {
        reject(mockInput.error)
        return
      } else {
        resolve({
          ...mockInput.data,
        })
        return
      }
    }, mockInput.delay || DEFAULT_DELAY)
  }) as ApiRequestPromise<T>
  promise.cancel = () => controller.abort()
  return promise
}

const MOCKS_ENABLED = process.env.MOCKS_ENABLED === 'true'
if (isDevelopment() && MOCKS_ENABLED) {
  enableMocks()
}
