import { isDevelopment } from './application_mode'

window.Config = {
	BACKEND_API_URL: process.env.BACKEND_API_URL,
	SENTRY_DSN_API: process.env.SENTRY_DSN_API,
	ENVIRONMENT: process.env.ENVIRONMENT,
	RECAPTCHA_SITE_KEY: window.Cypress ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' : process.env.RECAPTCHA_SITE_KEY,
	SEARCH_SUGGEST_SERVICES: process.env.SEARCH_SUGGEST_SERVICES.split(','),
	CAPTCHA_PROVIDERS_PRIORITY: process.env.CAPTCHA_PROVIDERS_PRIORITY,
	TURNSTILE_SITE_KEY: process.env.TURNSTILE_SITE_KEY,
}

