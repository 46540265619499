import React from 'react'
import { HotKeys, KeyMap } from 'react-hotkeys'
import Icon from '../../Icons'
import { useStore } from '../../../hooks/useStore'
import AppStore from '../../../modules/app/store'
import { Button } from './styled'

interface Props {
  isHome: boolean
  compensationWidth: number
}

const Menu: React.FC<Props> = ({ isHome, compensationWidth }) => {
  const appStore = useStore(AppStore)

  const keyMap: KeyMap = {
    ENTER: 'enter',
  }
  const handlers = {
    ENTER: () => AppStore.setIsMenuOpen(!appStore.isMenuOpen),
  }

  return (
    <HotKeys keyMap={keyMap} handlers={handlers}>
      <Button
        onClick={() => AppStore.setIsMenuOpen(!appStore.isMenuOpen)}
        tabIndex={0}
        $isHome={isHome}
        $compensationWidth={compensationWidth}
        data-testid={!appStore.isMenuOpen ? 'menu-button-open' : 'menu-button-close'}
      >
        {appStore.isMenuOpen ? <Icon.Close /> : <Icon.Menu />}
      </Button>
    </HotKeys>
  )
}

export default Menu
