import React from 'react'

interface Props {
  size?: string
  radius?: number
}

const APK: React.FC<Props> = ({ size, radius }) => (
  <svg viewBox="0 0 600 600" preserveAspectRatio="xMinYMid" width={size} height={size}>
    <rect rx={radius} ry={radius} width="100%" height="100%" fill="#97c024" />
    <path
      d="M257 206.6q3.2 0 5.5-2.3t2.3-5.5-2.3-5.5-5.5-2.3-5.4 2.3-2.2 5.5 2.2 5.5 5.4 2.3zm84.4 0q3.2 0 5.4-2.3t2.2-5.5-2.2-5.5-5.4-2.3-5.5 2.3-2.3 5.5 2.3 5.5 5.5 2.3zM179 243.4q8.4 0 14.4 6t6 14.4v86q0 8.6-5.9 14.6t-14.5 6-14.6-6-6-14.6v-86q0-8.4 6-14.4t14.6-6zm212 3.8v133.2q0 9.2-6.4 15.6t-15.4 6.4h-15v45.4q0 8.6-6 14.6t-14.6 6-14.6-6-6-14.6v-45.4h-27.6v45.4q0 8.6-6 14.6t-14.6 6q-8.4 0-14.4-6t-6-14.6l-.2-45.4h-14.8q-9.2 0-15.6-6.4t-6.4-15.6V247.2H391zm-46.4-81q21.4 11 34.2 30.7t12.8 43.1h-185q0-23.4 12.8-43.1t34.4-30.7L239.6 140q-1.4-2.6 1-4 2.6-1.2 4 1.2l14.4 26.4q19-8.4 40.2-8.4t40.2 8.4l14.4-26.4q1.4-2.4 4-1.2 2.4 1.4 1 4zm95.4 97.6v86q0 8.6-6 14.6t-14.6 6q-8.4 0-14.4-6t-6-14.6v-86q0-8.6 6-14.5t14.4-5.9q8.6 0 14.6 5.9t6 14.5z"
      fill="#fff"
    />
  </svg>
)

export default APK
