import styled from 'styled-components'

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 15px;

  @media screen and (max-width: ${({ theme }) => theme.layout.sizes.md}px) {
    &.icon {
      svg {
        width: 55px;
        height: 55px;
      }
    }
  }
`
