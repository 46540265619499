import { LogoSvg } from './header/Logo'
import { LogoHomeSvg } from './header/LogoHome'
import { LogoSymbolSvg } from './header/LogoSymbol'

const Images = {
  Logo: LogoSvg,
  LogoHome: LogoHomeSvg,
  LogoSymbol: LogoSymbolSvg,
}

export { Images }
