import { BehaviorSubject } from 'rxjs'
import { SearchQuery } from '../search/model'
import { ISearchSuggestItem } from './model'

interface IAutocompleteStoreState {
  readonly query: SearchQuery
  readonly items: ISearchSuggestItem[]
}

const initialState: IAutocompleteStoreState = {
  query: '',
  items: [],
}

const AutocompleteStoreSubject = new BehaviorSubject(initialState)

const AutocompleteStore = {
  getValue: () => AutocompleteStoreSubject.value,
  subscribe: (next: (value: IAutocompleteStoreState) => void) => AutocompleteStoreSubject.subscribe(next),
  setItems: (items: ISearchSuggestItem[]) => AutocompleteStoreSubject.next({ ...AutocompleteStore.getValue(), items }),
  reset: () => AutocompleteStoreSubject.next(initialState),
  setQuery: (query: SearchQuery) => AutocompleteStoreSubject.next({ ...AutocompleteStore.getValue(), query }),
}

export default AutocompleteStore
