import React, { RefObject, useContext, useEffect, useRef, useState } from 'react'
import * as Sentry from '@sentry/browser'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import Icon from '../../Icons'
import {
  SearchFilter,
  SearchFilterArchiveSize,
  SearchFilterAudioDuration,
  SearchFilterAudioQuality,
  SearchFilterAudioSize,
  SearchFilterDocumentSize,
  SearchFilterImageQuality,
  SearchFilterImageSize,
  SearchFilterType,
  SearchFilterVideoDuration,
  SearchFilterVideoQuality,
  SearchFilterVideoSize,
  SearchFilterVideoStreaming,
} from '../../../modules/search/model'
import { createSearchFilter } from '../../../modules/search/factory'
import useOutsideMouseClick from '../../../hooks/useOutsideMouseClick'
import useScroll from '../../../hooks/useScroll'
import { useStore } from '../../../hooks/useStore'
import SearchStore from '../../../modules/search/store'
import { ConfigContext } from '../../../modules/config/context'
import { Service } from '../../../modules/config/model'
import { SentrySeverity } from '../../../modules/sentry/model'
import { Filter, Grad, Sorting, Tabs } from './styled'

const iconMap = {
  [SearchFilterType.All]: Icon.Search,
  [SearchFilterType.Image]: Icon.TypeImage,
  [SearchFilterType.Video]: Icon.TypeVideo,
  [SearchFilterType.Document]: Icon.TypeDocument,
  [SearchFilterType.Archive]: Icon.TypeArchive,
  [SearchFilterType.Audio]: Icon.TypeAudio,
} as Record<SearchFilterType, React.FC>

const hasActiveSubFilter = (searchFilter: SearchFilter): boolean =>
  !!(
    searchFilter.video?.quality.length ||
    searchFilter.video?.streaming.length ||
    searchFilter.video?.duration.length ||
    searchFilter.video?.size.length ||
    searchFilter.audio?.duration.length ||
    searchFilter.audio?.quality.length ||
    searchFilter.audio?.size.length ||
    searchFilter.image?.quality.length ||
    searchFilter.image?.size.length ||
    searchFilter.archive?.size.length ||
    searchFilter.document?.size.length
  )

const updateMenuScrollPosition = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
  stateFunc: (position: MenuScrollPosition) => void
) => {
  const el = ref.current as HTMLDivElement
  const scrollLeft = Math.floor(el.scrollLeft)

  if (el.scrollWidth <= el.clientWidth) {
    stateFunc(MenuScrollPosition.None)
  } else if (scrollLeft === 0) {
    stateFunc(MenuScrollPosition.Left)
  } else if (scrollLeft + el.clientWidth >= el.scrollWidth - 3) {
    stateFunc(MenuScrollPosition.Right)
  } else {
    stateFunc(MenuScrollPosition.Middle)
  }
}

const getAvailableFilters = (
  service: Service
): {
  [key: string]: {
    [key: string]: string[]
  }
} => {
  switch (service) {
    case Service.MG:
      return {
        [SearchFilterType.All]: {},
        [SearchFilterType.Image]: {},
      }
    default:
      return {
        [SearchFilterType.All]: {},
        [SearchFilterType.Image]: {
          quality: Object.values(SearchFilterImageQuality),
          size: Object.values(SearchFilterImageSize),
        },
        [SearchFilterType.Video]: {
          duration: Object.values(SearchFilterVideoDuration),
          quality: Object.values(SearchFilterVideoQuality),
          streaming: Object.values(SearchFilterVideoStreaming),
          size: Object.values(SearchFilterVideoSize),
        },
        [SearchFilterType.Document]: {
          size: Object.values(SearchFilterDocumentSize),
        },
        [SearchFilterType.Archive]: {
          size: Object.values(SearchFilterArchiveSize),
        },
        [SearchFilterType.Audio]: {
          quality: Object.values(SearchFilterAudioQuality),
          duration: Object.values(SearchFilterAudioDuration),
          size: Object.values(SearchFilterAudioSize),
        },
      }
  }
}

enum MenuScrollPosition {
  None,
  Left,
  Middle,
  Right,
}

interface Props {
  wrapperRef: RefObject<HTMLDivElement>
  onToolsTrigger: (isOpen: boolean) => void
}

const FilterBox: React.FC<Props> = ({ wrapperRef, onToolsTrigger }) => {
  const config = useContext(ConfigContext)
  const searchStore = useStore(SearchStore)
  const currentTypeValue = searchStore.searchFilter[searchStore.searchFilter.contentType] as Record<string, string[]>
  const { t } = useTranslation()
  const [showMoreFilterTypes, setShowMoreFilterTypes] = useState(false)
  const [showSubFilters, setShowSubFilters] = useState(false)
  const [activeMenu, setActiveMenu] = useState<string | undefined>(undefined)
  const filterMenuRef = useRef<HTMLDivElement | null>(null)
  const subFilterMenuRef = useRef<HTMLDivElement | null>(null)
  const moreFiltersMenuRef = useRef<HTMLLIElement | null>(null)
  const [filterMenuScroll, setFilterMenuScroll] = useState(MenuScrollPosition.Left)
  const [subFilterMenuScroll, setSubFilterMenuScroll] = useState(MenuScrollPosition.Left)
  const availableFilters = getAvailableFilters(config.service)

  const menuElementMap = {} as Record<string, HTMLLIElement>
  const subMenuElementMap = {} as Record<string, HTMLDivElement>

  const searchFilterTypes = Object.keys(availableFilters)
  const hasSubFilters = Object.keys(availableFilters[searchStore.searchFilter.contentType]).length > 0

  useEffect(() => {
    if (!hasSubFilters) {
      setShowSubFilters(false)
    } else if (hasActiveSubFilter(searchStore.searchFilter)) {
      setShowSubFilters(true)
    }
  }, [searchStore.searchFilter, hasSubFilters])

  useEffect(() => {
    onToolsTrigger(showSubFilters)
  }, [showSubFilters])

  useEffect(() => {
    for (const key in subMenuElementMap) {
      if (subMenuElementMap[key]) {
        subMenuElementMap[key].style.visibility = 'hidden'
        subMenuElementMap[key].style.pointerEvents = 'none'
      }
    }

    if (activeMenu) {
      if (!(activeMenu in menuElementMap) || !(activeMenu in subMenuElementMap)) {
        Sentry.captureMessage('Filter menu/submenu html element not found in memory.', SentrySeverity.Warning)
        return
      }

      const submenuWidth = subMenuElementMap[activeMenu].getBoundingClientRect().width
      const documentWidth = document.documentElement.clientWidth + 5 // 5 for less space from right
      let left = menuElementMap[activeMenu].getBoundingClientRect().left - (wrapperRef.current?.getBoundingClientRect().left || 0)

      if (left < 25) {
        left = 25
      } else if (submenuWidth + left > documentWidth) {
        left = documentWidth - submenuWidth
      }

      subMenuElementMap[activeMenu].style.left = left + 'px'
      subMenuElementMap[activeMenu].style.visibility = 'visible'
      subMenuElementMap[activeMenu].style.pointerEvents = 'auto'
    }
  }, [activeMenu])

  useEffect(() => {
    const handler = () => {
      setActiveMenu(undefined)
    }

    window.addEventListener('touchmove', handler)
    return window.removeEventListener('touchmove', handler)
  }, [])

  useEffect(() => {
    updateMenuScrollPosition(subFilterMenuRef, setSubFilterMenuScroll)
  }, [activeMenu])

  useScroll(filterMenuRef.current, () => updateMenuScrollPosition(filterMenuRef, setFilterMenuScroll))
  useScroll(subFilterMenuRef.current, () => {
    updateMenuScrollPosition(subFilterMenuRef, setSubFilterMenuScroll)
    setActiveMenu(undefined)
  })

  useOutsideMouseClick(moreFiltersMenuRef, () => {
    setShowMoreFilterTypes(false)
  })

  const changeContentType = (type: SearchFilterType): void => {
    if (searchStore.searchFilter.contentType !== type) {
      SearchStore.setSearchFilter(createSearchFilter(type))
    }
  }

  const changeFilter = (filterName: string, filterValue: string): void => {
    setActiveMenu(undefined)
    SearchStore.setSearchFilter({
      ...searchStore.searchFilter,
      [searchStore.searchFilter.contentType]: {
        ...currentTypeValue,
        ...{
          [filterName]: currentTypeValue[filterName].includes(filterValue) ? [] : [filterValue],
        },
      },
    })
  }

  const resetFilter = (filterName: string): void => {
    setActiveMenu(undefined)
    SearchStore.setSearchFilter({
      ...searchStore.searchFilter,
      [searchStore.searchFilter.contentType]: {
        ...currentTypeValue,
        ...{
          [filterName]: [],
        },
      },
    })
  }

  const translateFilterValues = (filterName: string): string[] => {
    const translations: string[] = []
    currentTypeValue[filterName].forEach((val) => {
      translations.push(t(`components.Search.FilterBox.${searchStore.searchFilter.contentType}.${filterName}.values.${val}`))
    })

    return translations
  }

  const renderContentTypeItem = (contentType: string): JSX.Element => {
    const Icon = iconMap[contentType as SearchFilterType]

    return (
      <li key={contentType}>
        <a
          onClick={() => {
            changeContentType(contentType as SearchFilterType)
            setShowMoreFilterTypes(false)
          }}
          data-testid={'contentType-' + contentType}
          className={searchStore.searchFilter.contentType == contentType ? 'is-active' : ''}
          tabIndex={0}
        >
          <Icon /> <span>{t('components.Search.FilterBox.type.' + contentType)}</span>
        </a>
      </li>
    )
  }

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  })

  const renderMenuDesktop = () => (
    <>
      {searchFilterTypes.slice(0, 2).map(renderContentTypeItem)}
      {searchFilterTypes.length > 2 &&
        renderContentTypeItem(
          searchFilterTypes.slice(0, 3).includes(searchStore.searchFilter.contentType)
            ? searchFilterTypes[2]
            : searchStore.searchFilter.contentType
        )}
      {searchFilterTypes.length > 3 && (
        <li className="more" ref={moreFiltersMenuRef}>
          <a onClick={() => setShowMoreFilterTypes(!showMoreFilterTypes)} tabIndex={0} data-testid="filter-more">
            <Icon.More /> <span>{t('components.Search.FilterBox.typeBox.other')}</span>
          </a>
          <ul className={showMoreFilterTypes ? 'active' : ''}>
            {searchFilterTypes.slice(3, 6).includes(searchStore.searchFilter.contentType) && renderContentTypeItem(searchFilterTypes[2])}
            {searchFilterTypes
              .slice(3, 6)
              .map((contentType: string) =>
                contentType === searchStore.searchFilter.contentType ? null : renderContentTypeItem(contentType)
              )}
          </ul>
        </li>
      )}
    </>
  )

  const renderMenuMobile = () => <>{searchFilterTypes.map(renderContentTypeItem)}</>

  const shouldShowGradient = (position: MenuScrollPosition, stateMenuScroll: MenuScrollPosition): boolean => {
    return ![position, MenuScrollPosition.Middle].includes(stateMenuScroll)
  }

  return (
    <Filter>
      <div className="wrapper">
        <Tabs ref={filterMenuRef}>
          <Grad type="left" $isEnd={shouldShowGradient(MenuScrollPosition.Right, filterMenuScroll)} />
          <ul data-testid="filterBox-menu">
            {isMobile ? renderMenuMobile() : renderMenuDesktop()}
            {config.service !== Service.MG && (
              <li className="filter" tabIndex={hasSubFilters ? 0 : -1}>
                {hasSubFilters ? (
                  <a onClick={() => setShowSubFilters(!showSubFilters)}>
                    <Icon.Filter /> <span>{t('components.Search.FilterBox.typeBox.filter')}</span>
                  </a>
                ) : (
                  <span className="disabled">
                    <Icon.Filter /> <span>{t('components.Search.FilterBox.typeBox.filter')}</span>
                  </span>
                )}
              </li>
            )}
          </ul>
          <Grad type="right" $isEnd={shouldShowGradient(MenuScrollPosition.Left, filterMenuScroll)} />
        </Tabs>
      </div>
      <div className={'wrapper ' + (showSubFilters ? 'visible' : 'hidden')}>
        <Sorting ref={subFilterMenuRef}>
          <Grad type="left" $isEnd={shouldShowGradient(MenuScrollPosition.Right, subFilterMenuScroll)} />
          <ul>
            {hasSubFilters && (
              <li data-testid="filters">
                <ul>
                  {Object.keys(availableFilters[searchStore.searchFilter.contentType]).map((filterName) => (
                    <li
                      key={filterName}
                      data-testid={'filterType-' + filterName}
                      ref={(ref) => (menuElementMap[filterName] = ref as HTMLLIElement)}
                      className={
                        (activeMenu === filterName ? 'active' : '') + ' ' + (currentTypeValue[filterName].length != 0 ? 'is-active' : '')
                      }
                      onMouseEnter={() => setActiveMenu(filterName)}
                      onMouseLeave={() => setActiveMenu(undefined)}
                      tabIndex={0}
                    >
                      <span onClick={() => setActiveMenu(filterName)}>
                        {currentTypeValue[filterName].length
                          ? translateFilterValues(filterName).join(' | ')
                          : t(`components.Search.FilterBox.${searchStore.searchFilter.contentType}.${filterName}.label`)}
                        <Icon.CaretDown />
                      </span>
                      <div
                        className="wrapper"
                        ref={(ref) => {
                          subMenuElementMap[filterName] = ref as HTMLDivElement
                        }}
                      >
                        <ul>
                          <li
                            onClick={() => resetFilter(filterName)}
                            className={currentTypeValue[filterName].length == 0 ? 'is-active' : ''}
                            tabIndex={0}
                          >
                            {t('components.Search.FilterBox.all')}
                            {currentTypeValue[filterName].length == 0 && <Icon.Check />}
                          </li>
                          {availableFilters[searchStore.searchFilter.contentType][filterName].map((filterValue) => {
                            const isActive = currentTypeValue[filterName].includes(filterValue)
                            return (
                              <li
                                key={filterName + filterValue}
                                onClick={() => changeFilter(filterName, filterValue)}
                                className={isActive ? 'is-active' : ''}
                                tabIndex={0}
                              >
                                {t(
                                  `components.Search.FilterBox.${searchStore.searchFilter.contentType}.${filterName}.values.${filterValue}`
                                )}
                                {isActive && <Icon.Check />}
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </li>
                  ))}
                </ul>
              </li>
            )}
          </ul>
          <Grad type="right" $isEnd={shouldShowGradient(MenuScrollPosition.Left, subFilterMenuScroll)} />
        </Sorting>
      </div>
    </Filter>
  )
}

export default FilterBox
