import React, { useContext } from 'react'
import { Trans } from 'react-i18next'
import { Images } from '../../../components/Images'
import MenuButton from '../../../components/Menu/Button'
import Menu from '../../../components/Menu'
import { useStore } from '../../../hooks/useStore'
import AppStore from '../../../modules/app/store'
import { ConfigContext } from '../../../modules/config/context'
import { getServiceRoute } from '../../../modules/router/service'
import { useCountry } from '../../../hooks/useCountry'
import { Home, Logo, StyledNavLink, Description } from './styled'

const HomeRoute: React.FC = () => {
  const config = useContext(ConfigContext)
  const appStore = useStore(AppStore)
  const country = useCountry()

  return (
    <>
      <Menu compensationWidth={appStore.scrollBarCompensation} isHome={true} />
      <MenuButton compensationWidth={appStore.scrollBarCompensation} isHome={true} />
      <Home>
        <Logo data-testid="logo">
          <a href={getServiceRoute(config.service, config.country)}>
            <Images.LogoHome />
          </a>
        </Logo>
        <Description>
          <Trans i18nKey={'components.Home.gozoPrivacySearchService'} parent="p" />
          <Trans i18nKey={'components.Home.gozoCustomSearchEngine'} parent="p" />
          <Trans i18nKey="components.Home.withGozoYouCanTake" parent="p">
            With Gozo, you can take control of your online privacy and search with confidence. <StyledNavLink to={`/${country}/products`} />{' '}
            and experience the difference!
          </Trans>
        </Description>
      </Home>
    </>
  )
}

export default HomeRoute
