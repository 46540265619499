import styled from 'styled-components'

export const Switch = styled.div`
  margin-bottom: 30px;

  strong {
    display: block;
    margin-bottom: 15px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      &:not(:last-child) {
        margin-bottom: 15px;
      }

      a {
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.color.text};
        gap: 10px;
      }

      &.is-active {
        a {
          color: ${({ theme }) => theme.color.link};
        }
      }

      span {
        flex: 1;
      }
    }
  }

  svg {
    width: 25px;
    height: 15px;
    border-radius: 5px;
  }
`
