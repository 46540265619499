import React from 'react'
import { NavLink } from 'react-router-dom'
import { Area } from '../../styled'
import ImageWithFallback from '../../../../components/ImageWithFallback'
import useDocumentTitle from '../../../../hooks/useDocumentTitle'
import { useCountry } from '../../../../hooks/useCountry'
import * as img from '../../images'

const Help: React.FC = () => {
  const country = useCountry()
  useDocumentTitle('Nápoveda pre Gozo')

  return (
    <Area className="has-image">
      <div className="text">
        <h1>Nápoveda pre Gozo</h1>

        <h2>Základné informácie pre používateľov</h2>

        <h3>Ako môžem filtrovať výsledky vyhľadávania, aby boli presnejšie?</h3>
        <p>
          Filtre vo vyhľadávači slúžia na obmedzenie výsledkov vyhľadávania na základe kritérií, ktoré používateľ špecifikuje. Pomáhajú
          používateľom zúžiť výsledky a nájsť presne to, čo hľadajú.
        </p>
        <p>
          Vyhľadávač Gozo môže zobrazovať rôzne filtre v závislosti na konkrétnej implementácii a dostupných údajoch. Ak je filtrovanie
          dostupné, ikonka filtra sa zobrazí na pravej strane pod vyhľadávacou lištou. Medzi pokročilé možnosti filtrovania patria
          nasledovné:
        </p>
        <p>
          <em>Filter obrázkov:</em> umožňuje filtrovať obrázky podľa kvality a veľkosti.
        </p>
        <div className="image-preview">
          <ImageWithFallback src={img.imgFilterImageWebpSk} fallbackSrc={img.imgFilterImageSk} alt="gozo-image-filter" />
        </div>
        <p>
          <em>Filter videí:</em> tento filter pomáha používateľom nájsť videá podľa dĺžky, kvality, veľkosti alebo možností prehrávania.
        </p>
        <div className="image-preview">
          <ImageWithFallback src={img.imgFilterVideoWebpSk} fallbackSrc={img.imgFilterVideoSk} alt="gozo-video-filter" />
        </div>
        <p>
          <em>Audio Filter:</em> tento filter je špecifický pre vyhľadávanie zvukových súborov a umožňuje vám nájsť audio súbory podľa
          zvolených kritérií (napríklad dĺžka, kvalita, veľkosť a ďalšie).
        </p>
        <p>
          <em>Filter dokumentov:</em> v niektorých prípadoch môžete hľadať dokumenty určitej veľkosti. Napríklad, ak potrebujete dokumenty
          vhodné pre mobilné zariadenia s obmedzeným úložným priestorom, môžete použiť filter veľkosti, aby ste obmedzili výsledky na menšie
          dokumenty.
        </p>
        <p>
          <em>Filter archívov:</em> archívy sa používajú na jednoduchšiu manipuláciu so súbormi. Filter archívov vám pomôže rozdeliť
          výsledky vyhľadávania podľa veľkosti.
        </p>
        <p>
          <em>Filter správ:</em> zadajte zoznam spravodajských webov a blogov, ktoré chcete prehľadávať. Získate najnovšie články
          prispôsobené vašej otázke.
        </p>
        <p>
          <em>Lokálny filter:</em> umožňuje používateľom obmedziť výsledky na konkrétnu geografickú oblasť. Napríklad pri hľadaní
          reštaurácie si môžu používatelia vybrať filter pre ich krajinu, mesto alebo okres.
        </p>
        <p>
          <em>Filter kvality:</em> sústreďuje sa na kvalitu, ktorú možno merať rôznymi faktormi, ako je rozlíšenie, bitová rýchlosť a
          podobne. Filter kvality umožňuje používateľom vyhľadávať a obmedzovať výsledky na základe požadovanej kvality mediálneho obsahu.
          Napríklad je možné vyhľadávať videá vo vysokom rozlíšení, aby sa zobrazovanie zlepšilo, alebo vyhľadávať súbory s vyššou bitovou
          rýchlosťou pre lepšiu zvukovú kvalitu.
        </p>
        <p>
          <em>Filter kategórie:</em> umožňuje filtrovať výsledky podľa kategórií. Napríklad pri hľadaní produktu v online obchode môžu
          používatelia použiť filter pre konkrétnu kategóriu, ako je oblečenie, elektronika, domáce spotrebiče a podobne.
        </p>
        <p>
          <em>Cenový filter:</em> umožňuje obmedziť výsledky vyhľadávania na určité cenové rozpätie. Pomáha používateľom nájsť produkty,
          ktoré zodpovedajú ich finančným možnostiam.
        </p>
        <p>
          <em>Jazykový filter:</em> umožňuje používateľovi obmedziť výsledky vyhľadávania na konkrétny jazyk. Je to užitočné, ak hľadajú
          informácie v špecifickom jazyku.
        </p>

        <h3>Ako môžem zmeniť spôsob zoradenia výsledkov?</h3>
        <p>Výsledky sú zoradené od najrelevantnejších po najmenej relevantné. Zmena spôsobu zoradenia výsledkov zatiaľ nie je možná.</p>

        <h3>Ako zobraziť čo najviac výsledkov?</h3>
        <p>
          Pri zobrazení výsledkov vyhľadávania môžete využiť rôzne prezentácie, v závislosti na type obsahu, ktorý hľadáte. Pri vyhľadávaní
          obrázkov a videí sa obvykle používa rozloženie mriežky ("grid"), ktoré umožňuje zobraziť viacero miniatúr v jednom riadku po celej
          šírke stránky. Týmto spôsobom môžete vidieť viac výsledkov na jednom displeji a rýchlejšie sa medzi nimi prechádzať.
        </p>
        <p>
          Po zadaní a potvrdení vyhľadávacej požiadavky sa zobrazia výsledky vyhľadávania. Posuňte sa nadol a keď sa priblížite k spodnej
          časti stránky, automaticky sa načítajú ďalšie výsledky.
        </p>
        <p>
          Zobrazenie výsledkov vyhľadávania môže byť rôzne v závislosti na dizajnových a používateľských preferenciách. V riadkovom
          zobrazení je každý výsledok prezentovaný na samostatnom riadku. To umožňuje používateľom jednoduchšie porovnávať a prechádzať
          jednotlivé výsledky. V riadkovom zobrazení je k dispozícii stručný textový úryvok, ktorý poskytuje používateľom náhľad na to, ako
          sa výsledok vyhľadávania vzťahuje k ich hľadaným kľúčovým slovám. Tento štýl zobrazenia vyžaduje viac priestoru a ukážky obrázkov
          sa často zobrazujú menšie. Naopak, dlaždicový spôsob zobrazenia výsledkov vyhľadávania umožňuje rýchlejšie prechádzať a
          vyhodnocovať veľké množstvo výsledkov.
        </p>

        <h3>Môžem Gozo používať aj na mobilnom zariadení?</h3>
        <p>
          Gozo môžete používať na všetkých vašich zariadeniach vrátane mobilných zariadení. Vyhľadávač je prispôsobený pre mobilné
          zariadenia.
        </p>

        <h3>Na akých zariadeniach je možné používať Gozo?</h3>
        <p>
          Gozo podporuje rôzne zariadenia, vrátane moderných smartfónov, tabletov, televízorov, inteligentných hodiniek, inteligentných
          chladničiek, inteligentných displejov (napr. Amazon Echo Show) a konzol (PlayStation, Xbox atď.). Pre optimálnu funkčnosť je však
          nutné mať zariadenie, ktoré spĺňa určité technické požiadavky. Napríklad niektoré staršie modely smart televízorov nemusia byť
          kompatibilné s našou službou.
        </p>

        <h3>Čo robiť, ak sa vo výsledkoch vyhľadávania zobrazuje nevhodný obsah?</h3>
        <p>
          Ak narazíte na nevhodný alebo nebezpečný obsah, neváhajte nás kontaktovať prostredníctvom e-mailu:
          &#097;&#098;&#117;&#115;&#101;&#064;&#103;&#111;&#122;&#111;&#102;&#105;&#110;&#100;&#101;&#114;&#046;&#099;&#111;&#109;.
        </p>

        <h3>Koľko stojí Gozo?</h3>
        <p>
          Pre koncových používateľov je Gozo k dispozícii zdarma. Ak máte záujem o implementáciu vyhľadávača do vášho produktu alebo
          potrebujete prispôsobenú integráciu vyhľadávača pre vašu firmu, prečítajte si{' '}
          <NavLink to={`/${country}/pricing`}>cenovú ponuku</NavLink>. Sme pripravení poskytnúť firemným zákazníkom individuálne riešenie a
          integráciu vyhľadávača, ktorá bude vyhovovať vašim špecifickým potrebám.
        </p>

        <h3>Obťažujú ma reklamy, môžem ich vypnúť?</h3>
        <p>
          Webové vyhľadávače sú bezplatné služby dostupné pre koncových používateľov. Poskytujú rýchly a jednoduchý prístup k informáciám.
          Reklamy sú spôsobom, ako vyhľadávače financujú svoju prevádzku a vývoj týchto služieb. Reklamné príjmy sú pre vyhľadávače, vrátane
          Gozo, kľúčové pre udržanie bezplatnej povahy služieb a ich vypnutie nie je možné.
        </p>

        <br />
        <h2>O súkromí a výhodách vyhľadávača Gozo</h2>

        <h3>Čo je Gozo a ako funguje?</h3>
        <p>
          Gozo je vyhľadávač, ktorého základným princípom je ochrana súkromia používateľov. Naše užívateľsky prívetivé produkty umožňujú
          jednotlivcom aj firmám z celého sveta chrániť svoje osobné údaje online. Poskytujeme alternatívu k dominantným vyhľadávačom.
        </p>
        <p>
          Okrem vyhľadávania ponúkame aj výkonné API riešenia, ktoré umožňujú jednoduchú integráciu pokročilých vyhľadávacích funkcií do
          vašej aplikácie, webovej stránky alebo interných systémov.
        </p>

        <h3>Prečo by som mal používať Gozo?</h3>
        <ol className="line">
          <li>Rýchlosť a pohodlie - Gozo poskytuje rýchly a intuitívny spôsob, ako ľahko nájsť správne informácie na internete.</li>
          <li>Vysoká relevantnosť výsledkov - sofistikovaný algoritmus zaručuje vysokú relevantnosť výsledkov.</li>
          <li>Široká škála informácií - Gozo vyhľadáva vo veľkých celosvetových databázach.</li>
          <li>Anonymita a bezpečnosť - používame len najnutnejšie údaje a nezdieľame ich s tretími stranami.</li>
          <li>Dostupnosť - Gozo je dostupný pre každého, kto chce získať relevantné výsledky.</li>
        </ol>

        <h3>Aké údaje o mne Gozo uchováva?</h3>
        <p>
          Neukladáme históriu vášho vyhľadávania ani nepredávame žiadne informácie inzerentom, ktorí vás sledujú online. Vaše osobné údaje
          nezhromažďujeme ani nezdieľame.
        </p>
      </div>
      <div className="image">
        <img src={img.imgProducts} alt="" />
      </div>
    </Area>
  )
}

export default Help
