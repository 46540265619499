import { createContext, useLayoutEffect, useState } from 'react'
import { Country, Language } from '../language/model'
import i18next from '../language/i18next'
import useDocumentLang from '../../hooks/useDocumentLang'
import { ECaptchaProvider } from '../rateLimiting/model'
import { getDefaultProvider, getPrimaryCaptchaProvider } from '../rateLimiting/captchaProviders'
import { Service } from './model'
import { getDefaultDomainConfig } from './domains'
import { getTheme, setThemeBodyClass } from './theme'

export interface ConfigContextValue {
  service: Service
  language: Language
  country: Country
  initialized: boolean
  captchaProvider: ECaptchaProvider
}

// Default value should not be used in app, see App.tsx in root and Layout component
export const ConfigContext = createContext<ConfigContextValue>({
  ...getDefaultDomainConfig(),
  initialized: false,
  captchaProvider: getDefaultProvider(),
})
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const ConfigDispatchContext = createContext<(context: ConfigContextValue) => void>(() => {})

export const ConfigContextProvider = ({ children }: { children: JSX.Element }) => {
  const [context, setContext] = useState<ConfigContextValue>({
    ...getDefaultDomainConfig(),
    initialized: false,
    captchaProvider: getPrimaryCaptchaProvider(),
  })

  useLayoutEffect(() => {
    i18next.changeLanguage(context.language)
    setThemeBodyClass(getTheme(context.service))
  }, [context])
  useDocumentLang(context.language)

  return (
    <ConfigContext.Provider value={context}>
      <ConfigDispatchContext.Provider value={setContext}>{children}</ConfigDispatchContext.Provider>
    </ConfigContext.Provider>
  )
}
