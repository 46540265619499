import React, { useContext, useRef } from 'react'
import { HotKeys, KeyMap } from 'react-hotkeys'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SwitchLanguage from '../SwitchLanguage'
import SwitchTheme from '../SwitchTheme'
import useOutsideMouseClick from '../../hooks/useOutsideMouseClick'
import Icon from '../Icons'
import { useStore } from '../../hooks/useStore'
import AppStore from '../../modules/app/store'
import { ConfigContext } from '../../modules/config/context'
import { Button, Wrapper, Controls, Sticky, AboutUs } from './styled'

interface Props {
  hasBranding?: boolean
  compensationWidth: number
  topOffset?: number
  isHome: boolean
}

const Menu: React.FC<Props> = ({ hasBranding, compensationWidth, topOffset, isHome }) => {
  const { t } = useTranslation()
  const config = useContext(ConfigContext)
  const menuRef = useRef<HTMLDivElement | null>(null)
  const appStore = useStore(AppStore)
  const closeMenu = () => AppStore.setIsMenuOpen(false)
  useOutsideMouseClick(menuRef, closeMenu)

  if (appStore.isMenuOpen) {
    document.body.style.overflowY = 'hidden'
    document.body.style.paddingRight = `${appStore.scrollBarCompensation}px`
  } else {
    document.body.style.overflowY = 'scroll'
    document.body.style.paddingRight = `0`
  }

  const keyMap: KeyMap = {
    ENTER: 'enter',
  }
  const handlers = {
    ENTER: () => AppStore.setIsMenuOpen(!appStore.isMenuOpen),
  }

  return (
    <>
      <Wrapper
        className={appStore.isMenuOpen ? 'is-open' : ''}
        $hasBranding={hasBranding}
        $compensationWidth={compensationWidth}
        $topOffset={topOffset ? Math.round(topOffset) : 0}
      >
        <Controls ref={menuRef} $topOffset={topOffset ? Math.round(topOffset) : 0} data-testid="menu-container">
          <Sticky>
            <HotKeys keyMap={keyMap} handlers={handlers}>
              <Button
                onClick={() => AppStore.setIsMenuOpen(!appStore.isMenuOpen)}
                tabIndex={0}
                $isHome={isHome}
                data-testid={!appStore.isMenuOpen ? 'menu-button-open' : 'menu-button-close'}
              >
                {appStore.isMenuOpen ? <Icon.Close /> : <Icon.Menu />}
              </Button>
            </HotKeys>
            <SwitchTheme onChange={closeMenu} />
            <SwitchLanguage />
            <AboutUs data-testid="menu-nav-pages">
              <strong>{t('components.Menu.details')}</strong>
              <ul>
                <li>
                  <NavLink to={`/${config.country}/about`} onClick={closeMenu} tabIndex={0}>
                    {t('components.Menu.about')}
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/${config.country}/help`} onClick={closeMenu} tabIndex={0}>
                    {t('components.Menu.help')}
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/${config.country}/products`} onClick={closeMenu} tabIndex={0}>
                    {t('components.Menu.products')}
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/${config.country}/pricing`} onClick={closeMenu} tabIndex={0}>
                    {t('components.Menu.pricing')}
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/${config.country}/privacy`} onClick={closeMenu} tabIndex={0}>
                    {t('components.Menu.privacy')}
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/${config.country}/terms`} onClick={closeMenu} tabIndex={0}>
                    {t('components.Menu.terms')}
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/${config.country}/contact`} onClick={closeMenu} tabIndex={0}>
                    {t('components.Menu.contact')}
                  </NavLink>
                </li>
              </ul>
            </AboutUs>
          </Sticky>
        </Controls>
      </Wrapper>
    </>
  )
}

export default Menu
