import React from 'react'

interface Props {
  size?: string
  radius?: number
}

const EBook: React.FC<Props> = ({ size, radius }) => (
  <svg viewBox="0 0 600 600" preserveAspectRatio="xMinYMid" width={size} height={size}>
    <rect rx={radius} ry={radius} width="100%" height="100%" fill="#9d8758" />
    <path
      d="M473 188.4l31 254.4q-26.2 0-51.4-11.4-40-18.2-78.6-18.2-45.2 0-74.8 29.6-29.6-29.6-74.8-29.6-38.6 0-78.6 18.2-25.6 11.4-50.4 11.4h-1l31-254.4q44.8-25.4 96.4-25.4 46.6 0 77.4 21.2Q330 163 376.6 163q51.6 0 96.4 25.4zm-99 197.4q25.8 0 46.4 5.7t52 18.7L447.6 206q-34.2-15.6-73.6-15.6-44.8 0-74.8 28.2-30-28.2-74.8-28.2-39.4 0-73.6 15.6L126 410.2q21-8.6 33.1-13t29.7-7.9 35.6-3.5q40.4 0 74.8 21.6 34.4-21.6 74.8-21.6zm8-6.8l-11-181.4q-42.2.8-71.8 31-30.4-31-74.8-31-35.2 0-67.2 13.2L134.4 399q24.8-10.2 45.7-15.2t44.3-5q41.8 0 74.8 20.4 34.4-21.4 74.8-20.4z"
      fill="#fff"
    />
  </svg>
)

export default EBook
