import React from 'react'

const ThemeLightSvg: React.FC = () => (
  <svg viewBox="0 0 800 800" width="1em" height="1em" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M400 225c-96.484 0-175 78.516-175 175 0 96.483 78.516 175 175 175s175-78.517 175-175c0-96.484-78.516-175-175-175zM400 175c13.802 0 25-11.166 25-25v-50c0-13.802-11.198-25-25-25-13.834 0-25 11.198-25 25v50c0 13.834 11.166 25 25 25zM400 625c-13.834 0-25 11.198-25 25v50c0 13.834 11.166 25 25 25 13.802 0 25-11.166 25-25v-50c0-13.802-11.198-25-25-25zM594.402 240.886l35.352-35.352c9.766-9.766 9.766-25.586 0-35.352-9.767-9.766-25.586-9.766-35.352 0l-35.352 35.352c-9.767 9.766-9.767 25.586 0 35.352 9.766 9.764 25.584 9.764 35.352 0zM205.533 559.147l-35.348 35.352c-9.769 9.766-9.769 25.587 0 35.352 9.764 9.766 25.583 9.766 35.348 0l35.352-35.352c9.767-9.83 9.767-25.586 0-35.352-9.764-9.766-25.584-9.831-35.352 0zM175 400c0-13.802-11.198-25-25-25h-50c-13.834 0-25 11.198-25 25 0 13.834 11.166 25 25 25h50c13.802 0 25-11.166 25-25zM700 375h-50c-13.834 0-25 11.198-25 25 0 13.834 11.166 25 25 25h50c13.802 0 25-11.166 25-25 0-13.802-11.198-25-25-25zM205.502 240.886c9.764 9.766 25.586 9.766 35.352 0 9.764-9.766 9.764-25.586 0-35.352l-35.352-35.352c-9.766-9.766-25.588-9.766-35.352 0-9.766 9.766-9.766 25.586 0 35.352l35.352 35.352zM594.467 559.081c-9.833-9.766-25.587-9.766-35.352 0-9.766 9.764-9.833 25.586 0 35.352l35.352 35.352c9.764 9.764 25.584 9.764 35.352 0 9.764-9.766 9.764-25.587 0-35.352l-35.352-35.352z"
    />
  </svg>
)

export default ThemeLightSvg
