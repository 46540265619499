import styled from 'styled-components'

export const PropertiesList = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  font-weight: 500;

  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    gap: 2px 10px;
    list-style-type: none;

    @media screen and (min-width: ${({ theme }) => theme.layout.sizes.sm}px) {
      gap: 2px 15px;

      &.break {
        flex-wrap: nowrap;
      }
    }
  }

  li {
    display: flex;
    align-items: center;
    white-space: nowrap;

    &.break {
      width: 0;
      margin: 0;
    }
  }
  [data-rating^='+'] {
    color: ${({ theme }) => theme.color.green};
  }
  [data-rating^='-'] {
    color: ${({ theme }) => theme.color.red};
  }
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`

export const Link = styled.span`
  display: inline;
  display: -webkit-box;
  overflow: hidden;
  margin: 0;
  -webkit-box-orient: vertical;
  color: ${({ theme }) => theme.color.green};
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  word-break: break-all;
`
