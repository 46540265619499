import React from 'react'

interface Props {
  size?: string
  radius?: number
}

const Image: React.FC<Props> = ({ size, radius }) => (
  <svg viewBox="0 0 600 600" preserveAspectRatio="xMinYMid" width={size} height={size}>
    <rect rx={radius} ry={radius} width="100%" height="100%" fill="#3a77c3" />
    <path
      d="M235.2 235.2q0 16-11.2 27.2t-27.2 11.2-27.2-11.2-11.2-27.2 11.2-27.2 27.2-11.2T224 208t11.2 27.2zM440 312v89.6H158.4v-38.4l64-64 32 32 102.4-102.4zm19.2-140.8h-320q-2.6 0-4.5 1.9t-1.9 4.5v243.2q0 2.6 1.9 4.5t4.5 1.9h320q2.6 0 4.5-1.9t1.9-4.5V177.6q0-2.6-1.9-4.5t-4.5-1.9zm32 6.4v243.2q0 13.2-9.4 22.6t-22.6 9.4h-320q-13.2 0-22.6-9.4t-9.4-22.6V177.6q0-13.2 9.4-22.6t22.6-9.4h320q13.2 0 22.6 9.4t9.4 22.6z"
      fill="#fff"
    />
  </svg>
)

export default Image
