import styled from 'styled-components'

export const Caption = styled.div`
  margin: 0;
  line-height: 18px;

  @media screen and (max-width: ${({ theme }) => theme.layout.sizes.md}px) {
    font-size: 12px;
  }
`
