import { Subscription } from 'rxjs'
import { useEffect, useState } from 'react'

export const useSubscribe = <T>(subscribe: (next: (value: T) => void) => Subscription, initialValue?: T): T => {
  const [state, setState] = useState<T | undefined>(initialValue)

  useEffect(() => {
    const sub = subscribe(setState)

    return sub.unsubscribe.bind(sub)
  }, [subscribe])

  return state as T
}
