import React from 'react'
import { isIgnorableError } from '../modules/api/error'

// https://github.com/bvaughn/react-error-boundary
const useErrorHandler = (givenError?: unknown): ((error: unknown) => void) => {
  const [error, setError] = React.useState<unknown>(null)
  if (givenError != null) throw givenError
  if (error != null) throw error
  return (err: unknown) => {
    if (isIgnorableError(givenError)) {
      return
    }

    setError(err)
  }
}

export default useErrorHandler
