import React from 'react'

export interface SlideshowContextValue {
  currentActiveId: string | null
  setActiveId: (id: string | null) => void
}

const getDefaultSlideshow = () => {
  return {
    currentActiveId: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setActiveId: () => {},
  }
}

export const SlideshowContext = React.createContext<SlideshowContextValue>(getDefaultSlideshow())
