import React from 'react'

interface Props {
  size?: string
  radius?: number
}

const PDF: React.FC<Props> = ({ size, radius }) => (
  <svg viewBox="0 0 600 600" preserveAspectRatio="xMinYMid" width={size} height={size}>
    <rect rx={radius} ry={radius} width="100%" height="100%" fill="#bf0706" />
    <path
      d="M288.155 239.96c1.785 0 1.785 0 0 0 1.785-7.14 3.57-10.71 3.57-16.065v-3.57c1.785-8.925 1.785-16.065 0-17.85v-1.785l-1.785-1.785s0 1.785-1.785 1.785c-3.57 10.71-3.57 23.205 0 39.27zm-53.55 123.165c-3.57 1.785-7.14 3.57-8.925 5.355-12.495 10.71-21.42 23.204-23.205 28.56 10.71-1.785 21.42-12.495 32.13-33.915 1.785 0 1.785 0 0 0 1.785 0 0 0 0 0zm164.22-26.775c-1.785-1.785-8.925-7.14-33.915-7.14h-3.57v1.785c12.495 5.355 24.99 8.925 33.915 8.925H400.61v-1.785s-1.785 0-1.785-1.785zM425.6 140H175.7c-19.635 0-35.7 16.065-35.7 35.7v249.9c0 19.635 16.065 35.7 35.7 35.7h249.9c19.635 0 35.7-16.065 35.7-35.7V175.7c0-19.635-16.065-35.7-35.7-35.7zm-19.635 210.63c-3.57 1.785-8.925 3.57-16.065 3.57-14.28 0-35.7-3.57-53.55-12.495-30.345 3.57-53.55 7.14-71.4 14.28-1.785 0-1.785 0-3.57 1.784-21.42 37.486-39.27 55.336-53.55 55.336-3.57 0-5.355 0-7.14-1.786l-8.925-5.354v-1.785c-1.785-3.57-1.785-5.355-1.785-8.925 1.785-8.925 12.495-24.99 33.915-37.486 3.57-1.785 8.925-5.354 16.065-8.925 5.355-8.925 10.71-19.635 17.85-32.129 8.925-17.85 14.28-35.7 19.635-51.765-7.14-21.42-10.71-33.915-3.57-58.905 1.785-7.14 7.14-14.28 14.28-14.28h3.57c3.57 0 7.14 1.785 10.71 3.57 12.496 12.495 7.14 41.055 0 64.26v1.785c7.14 19.635 17.85 35.7 28.56 46.41 5.355 3.57 8.925 7.14 16.065 10.71 8.925 0 16.065-1.785 23.205-1.785 21.42 0 35.7 3.57 41.054 12.495 1.786 3.57 1.786 7.14 1.786 10.71-1.785 1.785-3.57 7.14-7.14 10.71zM289.94 281.015c-3.57 12.495-10.71 26.775-17.85 42.84-3.57 7.14-7.14 12.495-10.71 19.635h3.57c23.205-8.925 44.625-14.28 58.905-16.065-3.57-1.785-5.355-3.57-7.14-5.355-8.925-10.71-19.635-24.99-26.775-41.055z"
      fill="#fff"
    />
  </svg>
)

export default PDF
