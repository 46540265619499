import styled, { css } from 'styled-components'

export const Search = styled.div<{
  $isHome?: boolean
}>`
  display: flex;
  width: 100%;
  justify-content: center;
  ${({ $isHome }) =>
    $isHome &&
    css`
      max-width: ${({ theme }) => theme.size.querybox}px;
      box-sizing: border-box;
      padding: 0 20px;
      margin: 0 auto;
    `};

  form {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${({ theme }) => theme.color.bg.shade20};
    background-color: ${({ theme }) => theme.color.bg.input};
    border-radius: 5px;
    box-shadow: 0 2px 8px 1px ${({ theme }) => theme.color.bg.shade10};

    @media screen and (min-width: ${({ theme }) => theme.layout.sizes.lg}px) {
      display: inline-flex;
      margin-top: 0;
    }
    @media screen and (min-width: ${({ theme }) => theme.layout.sizes.xl + 1}px) {
      max-width: ${({ theme }) => theme.size.querybox}px;
    }
  }
`

export const Input = styled.input<{
  $isHome?: boolean
}>`
  width: calc(100% - 35px);
  height: 40px;
  padding: 0 15px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border-radius: 5px;
  font-size: 16px;

  @media screen and (min-width: ${({ theme }) => theme.layout.sizes.lg}px) {
    max-width: ${({ theme }) => theme.size.querybox - 25}px;
    font-size: 14px;
  }

  @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
    ${({ $isHome }) =>
      $isHome &&
      css`
        font-size: 18px;
      `}
  }

  &:focus {
    outline: none;
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ theme }) => theme.color.bg.shade50};
    opacity: 1; /* Firefox */
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme }) => theme.color.bg.shade50};
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme }) => theme.color.bg.shade50};
  }
`

export const Button = styled.button<{
  $isHome?: boolean
}>`
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: ${({ theme }) => theme.color.theme};
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  color: ${({ theme }) => theme.color.white};
  cursor: pointer;

  svg {
    font-size: 18px;
  }
  &:hover {
    opacity: 0.9;
  }

  @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
    ${({ $isHome }) =>
      $isHome &&
      css`
        width: 50px;
        height: 50px;

        svg {
          font-size: 22px;
        }
      `}
  }

  &:disabled {
    cursor: default;
  }
`
