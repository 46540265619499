import imgAbout from '../../public/img/static-pages/about.svg'
import imgFilterImage from '../../public/img/static-pages/help-filter-image.jpg'
import imgFilterImageWebp from '../../public/img/static-pages/help-filter-image.webp'
import imgFilterImageSk from '../../public/img/static-pages/help-filter-image-sk.jpg'
import imgFilterImageWebpSk from '../../public/img/static-pages/help-filter-image-sk.webp'
import imgFilterImageCz from '../../public/img/static-pages/help-filter-image-cz.jpg'
import imgFilterImageWebpCz from '../../public/img/static-pages/help-filter-image-cz.webp'
import imgFilterVideo from '../../public/img/static-pages/help-filter-video.jpg'
import imgFilterVideoWebp from '../../public/img/static-pages/help-filter-video.webp'
import imgFilterVideoSk from '../../public/img/static-pages/help-filter-video-sk.jpg'
import imgFilterVideoWebpSk from '../../public/img/static-pages/help-filter-video-sk.webp'
import imgFilterVideoCz from '../../public/img/static-pages/help-filter-video-cz.jpg'
import imgFilterVideoWebpCz from '../../public/img/static-pages/help-filter-video-cz.webp'
import imgProducts from '../../public/img/static-pages/products.svg'

export {
  imgAbout,
  imgFilterImage,
  imgFilterImageWebp,
  imgFilterVideo,
  imgFilterVideoWebp,
  imgProducts,
  imgFilterImageSk,
  imgFilterImageWebpSk,
  imgFilterImageCz,
  imgFilterImageWebpCz,
  imgFilterVideoSk,
  imgFilterVideoWebpSk,
  imgFilterVideoCz,
  imgFilterVideoWebpCz,
}
