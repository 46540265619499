import React, { useCallback, useState } from 'react'
import type { VirtualItem } from '@tanstack/virtual-core'
import ViewGrid from '../ViewGrid'
import ViewList from '../ViewList'
import { SlideshowContext } from '../../../modules/thumb'
import { File, View } from '../../../modules/file/model'
import useWindowSize from '../../../hooks/useWindowSize'
import { isFile } from '../View/helpers'
import { ListWrapper } from './styled'

interface PropsGrid {
  sideMargin: number
  columns: number
  itemWidth: number
  gridGap: number
  isFullyLoaded: boolean
}

export interface Props {
  rowsCount: number
  view: View
  items: File[]
  totalSize: number
  virtualItems: VirtualItem<unknown>[]
  grid: PropsGrid
  getListWrapperRef: () => HTMLDivElement | null
}

const MemoizedViewGrid = React.memo(ViewGrid)
const MemoizedViewList = React.memo(ViewList)

export const List: React.FC<Props> = ({ rowsCount, view, items, virtualItems, totalSize, grid, getListWrapperRef }) => {
  const windowSize = useWindowSize()
  const [currentActiveId, setActiveId] = useState<string | null>(null)
  const keyExtractor = useCallback(
    (index: number, row: VirtualItem<unknown>, items: File[]) => {
      const item = items[index]
      let key = row.key + '-' + windowSize.width
      if (isFile(item)) {
        key += '_' + (item as File).id
      }
      return key
    },
    [windowSize]
  ) // ensure virtual items are re-rendered either

  const startIndex = virtualItems[0].index
  let start = view === View.Grid ? startIndex * grid.columns : startIndex

  const listWrapperRef = getListWrapperRef()

  const visibleRows = React.useRef<Record<number, boolean>>({})

  const updateVisibleRows = (id: number, value: boolean) => (visibleRows.current[id] = value)

  return (
    <SlideshowContext.Provider value={{ currentActiveId, setActiveId }}>
      <ListWrapper $sideMargin={grid.sideMargin} style={{ height: `${totalSize}px` }}>
        {virtualItems.map((virtualRow) => {
          const commonProps = {
            key: keyExtractor(start, virtualRow, items),
            items,
            virtualRow,
            index: start,
            listWrapperRef,
            visibleRows: visibleRows.current,
            updateVisibleRows,
          }
          const gridProps = {
            columns: grid.columns,
            itemWidth: grid.itemWidth,
            gridGap: grid.gridGap,
            allowSkeletons: !grid.isFullyLoaded || virtualRow.index !== rowsCount - 1,
          }

          switch (view) {
            case View.Grid:
              start += grid.columns
              return <MemoizedViewGrid {...commonProps} {...gridProps} />
            case View.List:
              start++
              return <MemoizedViewList {...commonProps} />
            default:
              return null
          }
        })}
      </ListWrapper>
    </SlideshowContext.Provider>
  )
}
