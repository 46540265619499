var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import PropTypes from 'prop-types';
import React from 'react';

import { getHandlerArgs, isSupported, visibility } from './utils';

var PageVisibility = function (_React$Component) {
    _inherits(PageVisibility, _React$Component);

    function PageVisibility(props) {
        _classCallCheck(this, PageVisibility);

        var _this = _possibleConstructorReturn(this, (PageVisibility.__proto__ || Object.getPrototypeOf(PageVisibility)).call(this, props));

        _this.state = {
            isSupported: isSupported && visibility
        };
        return _this;
    }

    _createClass(PageVisibility, [{
        key: 'componentDidMount',
        value: function componentDidMount() {
            if (!this.state.isSupported) {
                return;
            }

            this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
            document.addEventListener(visibility.event, this.handleVisibilityChange);
        }
    }, {
        key: 'componentWillUnmount',
        value: function componentWillUnmount() {
            if (!this.state.isSupported) {
                return;
            }
            document.removeEventListener(visibility.event, this.handleVisibilityChange);
        }
    }, {
        key: 'handleVisibilityChange',
        value: function handleVisibilityChange() {
            if (typeof this.props.onChange === 'function') {
                var _props;

                // propagate change to callback
                (_props = this.props).onChange.apply(_props, _toConsumableArray(getHandlerArgs()));
            }
            if (typeof this.props.children === 'function') {
                // we pass the props directly to the function as children
                this.forceUpdate();
            }
        }
    }, {
        key: 'render',
        value: function render() {
            if (!this.props.children) {
                return null;
            }
            // function as children pattern support
            if (typeof this.props.children === 'function') {
                var _props2;

                if (!this.state.isSupported) {
                    // don't pass any arguments if PageVisibility is not supported
                    return this.props.children();
                }
                return (_props2 = this.props).children.apply(_props2, _toConsumableArray(getHandlerArgs()));
            }

            return React.Children.only(this.props.children);
        }
    }]);

    return PageVisibility;
}(React.Component);

PageVisibility.displayName = 'PageVisibility';

PageVisibility.propTypes = {
    onChange: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
};

export default PageVisibility;