import React from 'react'
import { Area } from '../styled'
import Icon from '../../../components/Icons'
import ContactInquiryForm, { FormPurpose } from '../../../components/ContactInquiryForm'
import useDocumentTitle from '../../../hooks/useDocumentTitle'

const Contact: React.FC = () => {
  useDocumentTitle('Contact')

  return (
    <Area className="has-form">
      <div className="text">
        <h1>Contact</h1>
        <p>Got a question? We'll help you find the right solution for your business.</p>
        <ul className="line">
          <li>
            <Icon.Check /> Get a Business Assessment
          </li>
          <li>
            <Icon.Check /> Ask a technology question
          </li>
          <li>
            <Icon.Check /> Find the best plan
          </li>
          <li>
            <Icon.Check /> Send a general inquiry
          </li>
        </ul>
      </div>
      <div className="form">
        <ContactInquiryForm purpose={FormPurpose.Contact} />
      </div>
    </Area>
  )
}

export default Contact
