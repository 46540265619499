import styled, { css } from 'styled-components'

export const RowWrapper = styled.div<{
  $itemWidth: number
  $gridGap: number
  $gridColumns: number
}>`
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  width: 100%;
  height: ${({ $itemWidth }) => ($itemWidth < 200 ? $itemWidth + 40 : $itemWidth + 5)}px;
  padding-bottom: 15px;
  grid-gap: ${({ $gridGap }) => $gridGap}px;
  grid-template-columns: repeat(${({ $gridColumns }) => $gridColumns}, 1fr);

  @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
    padding-bottom: 20px;
  }
`

export const Item = styled.div`
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.size.borderRadius}px;
  box-shadow: 0 0 5px ${({ theme }) => theme.color.bg.shade50};
  container-type: inline-size;

  .name {
    h2 {
      margin: 10px;
      font-size: 14px;
    }

    @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
      font-size: 16px;
    }

    .link {
      padding: 0 10px 10px;
    }
  }

  .properties {
    position: absolute;
    bottom: 10px;
    left: 0;
    box-sizing: border-box;
    padding: 0 10px;
    font-size: 11px;

    ul {
      gap: 2px 5px;
    }
    li {
      &.break {
        @media screen and (max-width: ${({ theme }) => theme.layout.sizes.sm}px) {
          flex-basis: 100%;
        }
        @media screen and (min-width: ${({ theme }) => theme.layout.sizes.sm}px) {
          position: absolute;
        }
      }
    }
  }

  ${css`
    @container (max-width: 235px) {
      .properties {
        font-size: 70%;
      }
    }
    @container (min-width: 235px) and (max-width: 250px) {
      .properties {
        font-size: 75%;
      }
    }
  `}
`
