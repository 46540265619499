import React, { useContext } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getCountriesForServiceWithoutPL } from '../../modules/config/language'
import Icon from '../Icons'
import { Country } from '../../modules/language/model'
import { ConfigContext } from '../../modules/config/context'
import { getServiceRoute } from '../../modules/router/service'
import { Switch } from './styled'

interface IconProps {
  country: Country
}

const LangIcon: React.FC<IconProps> = ({ country }) => {
  const IconComponent = {
    [Country.CZ]: Icon.FlagCZ,
    [Country.SK]: Icon.FlagSK,
    [Country.EN]: Icon.FlagEN,
    [Country.PL]: Icon.FlagPL,
  }[country]

  return <IconComponent />
}

const SwitchLanguage: React.FC = () => {
  const { t } = useTranslation()
  const config = useContext(ConfigContext)
  const [searchParams] = useSearchParams()

  const getPathForCountry = (country: Country) => {
    return `${getServiceRoute(config.service, country)}?${searchParams.toString()}`
  }

  const countries = getCountriesForServiceWithoutPL(config.service)
  if (countries.length === 1) {
    return null
  }

  return (
    <Switch>
      <strong>{t('components.Menu.language')}</strong>
      <ul>
        {countries.map((country) => {
          return (
            <li key={country} className={country === config.country ? 'is-active' : ''}>
              <Link to={getPathForCountry(country)} reloadDocument={true} data-testid={`menu-set-country-${country}`}>
                <LangIcon country={country} /> <span>{t('components.Languages.' + country)}</span>
                {country === config.country ? <Icon.Check /> : ''}
              </Link>
            </li>
          )
        })}
      </ul>
    </Switch>
  )
}

export default SwitchLanguage
