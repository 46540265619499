import React from 'react'
import { Area } from '../styled'
import { imgProducts } from '../images'
import useDocumentTitle from '../../../hooks/useDocumentTitle'

const Content: React.FC = () => {
  useDocumentTitle('Products')

  return (
    <Area className="has-image">
      <div className="text">
        <h1>Products</h1>

        <h2>Gozo Web Search</h2>
        <p>
          Experience the power of Gozo's Web Search, a feature designed to revolutionize the way you navigate the entire web. Our advanced
          search algorithms and indexing capabilities ensure you receive the most accurate and relevant results, making your online search
          endeavors simple and efficient.
        </p>
        <p>Gozo's Web Search offers:</p>
        <ul className="dot">
          <li>
            <p>
              Unparalleled Coverage: Gain access to an extensive array of websites, forums, and blogs to help you find the best information
              and resources that cater to your specific needs.
            </p>
          </li>
          <li>
            <p>
              Advanced Filtering: Narrow down your search results by applying filters such as content type, domain, language, and more. This
              ensures that you receive results tailored to your preferences.
            </p>
          </li>
          <li>
            <p>
              Customizable Ranking: Prioritize or deprioritize websites, domains, and pages based on your personal preferences, helping you
              discover the most valuable information quickly.
            </p>
          </li>
          <li>
            <p>
              Real-time Updates: Stay informed about the latest news, developments, and trending topics with our real-time search
              capabilities.
            </p>
          </li>
          <li>
            <p>
              Secure and Private: Gozo's Web Search is designed with user privacy in mind, ensuring that your search data remains
              confidential and protected at all times.
            </p>
          </li>
        </ul>
        <p>
          Experience a new level of web search efficiency and convenience with Gozo's Web Search, your ultimate solution for exploring the
          web and finding the perfect information to meet your needs.
        </p>

        <br />
        <h2>Gozo Custom Search</h2>
        <h3>Revolutionize your web experience</h3>
        <p>
          Gozo Custom Search is the ultimate solution for discovering relevant information across the internet. Confine your search to
          pertinent domains, filter results by subject or sector, and tailor rankings to align with your objectives.
        </p>
        <h3>Focus on the most significant areas of the web</h3>
        <p>
          Gozo empowers you to select the domains you wish to explore. Ensure your findings originate from the most insightful domains that
          truly matter to you.
        </p>
        <h3>Take command of rankings</h3>
        <p>Gozo provides you with full control over rankings. Promote or downplay domains and webpages according to your preferences.</p>
        <h3>Create your search engine in 2 effortless steps</h3>
        <p>1. Incorporate a list of websites</p>
        <p>Simply copy-paste into our dialogue box or upload a list of all the websites you want your new engine to crawl and index.</p>
        <p>2. Personalize your settings</p>
        <p>Utilize our highly adaptable settings panel to craft your engine's appearance and functionality to fit your specific needs.</p>
        <h3>Select your search engine variety</h3>
        <p>We provide multiple versions of Custom Search, ensuring you find exactly what you're looking for.</p>
        <h3>Custom web search</h3>
        <p>
          Incorporate your own site or any group of sites into your search engine. Experience the simplest way to create a fully
          customizable, ad-free site search.
        </p>
        <h3>Custom image search</h3>
        <p>
          Submit a list of image-rich websites you want included. We'll examine alt tags and meta descriptions to locate the most relevant
          images for your query.
        </p>
        <h3>Custom video search </h3>
        <p>
          Provide a list of video-centric websites you'd like to include. We'll analyze metadata and descriptions to identify the most
          pertinent videos related to your search query.
        </p>
        <h3>Custom document search </h3>
        <p>
          Supply a list of document-heavy websites you wish to incorporate. We'll scrutinize file metadata and descriptions to uncover the
          most relevant documents corresponding to your search inquiry.
        </p>
        <h3>Custom news search</h3>
        <p>Provide a list of news sites and blogs you'd like to search. Receive the latest articles tailored to your query.</p>

        <h3>Access every feature you need</h3>
        <p>Your search engine is 100% yours to customize. Feel free to modify any aspect, including:</p>
        <h3>Search Suggestions</h3>
        <p>Desire Google-style search suggestions? Just activate the feature!</p>
        <h3>Add/Remove Sites</h3>
        <p>Adjust the list of websites you want to crawl at any time &ndash; simply upload a file!</p>
        <h3>Colors</h3>
        <p>
          Personalize the color scheme for every element of your search engine, from the search field and button to the results. Custom logo
          is integration is seamless.
        </p>
        <h3>Layouts</h3>
        <p>Interested in results only? No problem. Prefer a two-column setup? Prefer tiles setup? We've got you covered.</p>

        <br />
        <h2>Search webpages with our powerfull API</h2>
        <p>
          Effortlessly integrate advanced web search capabilities within minutes using our API. With real-time responsiveness, Gozo reacts
          to your search requests instantly.
        </p>
        <h3>Web search API</h3>
        <p>
          Gozo Web Search API empowers you to enhance your app, website, or internal database with data and content from billions of web
          pages through a straightforward API call. Incorporate cutting-edge web search features in no time.
        </p>
        <h3>News API</h3>
        <p>
          Explore articles and breaking news from across the web. Obtain comprehensive live and historical results utilizing Gozo's
          user-friendly REST API. With contextual comprehension and natural language processing (NLP) capabilities, we enable you to display
          articles on any subject or industry of your choice.
        </p>
        <h3>Image search API</h3>
        <p>Gain access to one of the world's largest image databases. Discover the exact image you seek.</p>
        <h3>Document search API</h3>
        <p>
          Incorporate the ability to search extensive document repositories with Gozo's Document Search API. Enhance your app, website, or
          internal database with access to a wide range of document formats from countless web sources. Find the specific documents you need
          by seamlessly integrating this powerful API.
        </p>
        <h3>Autosuggest API</h3>
        <p>
          Offer your users automatic search suggestions while they type. The Autosuggest API can anticipate queries in real-time, saving
          time and enhancing query precision.
        </p>
        <h3>Spell check API</h3>
        <p>
          The Spell Check API rectifies spelling errors in various types of queries, including brand names, slang, and colloquial
          expressions. Effortlessly connect to your app or internal systems via an API.
        </p>
      </div>
      <div className="image">
        <img src={imgProducts} alt="" />
      </div>
    </Area>
  )
}

export default Content
