import { BehaviorSubject } from 'rxjs'
import { SearchFilter, SearchFilterType, SearchQuery } from './model'
import { createSearchFilter } from './factory'

export interface SearchStoreState {
  readonly searchQuery: SearchQuery
  readonly searchFilter: SearchFilter
  readonly forceReload: boolean
}

export const SearchStoreDefaults: SearchStoreState = {
  searchQuery: '',
  searchFilter: createSearchFilter(SearchFilterType.All),
  forceReload: false,
}

const SearchStoreSubject = new BehaviorSubject(SearchStoreDefaults)

const updateStore = (value: Partial<SearchStoreState>) => SearchStoreSubject.next({ ...SearchStoreSubject.value, ...value })

const SearchStore = {
  getValue: () => SearchStoreSubject.value,
  subscribe: (next: (value: SearchStoreState) => void) => SearchStoreSubject.subscribe(next),
  reset: () => SearchStoreSubject.next(SearchStoreDefaults),
  set: (values: Partial<SearchStoreState>) => updateStore(values),
  setSearchQuery: (searchQuery: SearchQuery) => updateStore({ searchQuery }),
  setSearchFilter: (searchFilter: SearchFilter) => updateStore({ searchFilter }),
  makeForceReload: () => updateStore({ forceReload: !SearchStoreSubject.value.forceReload }),
}

export default SearchStore
