import { File, View } from '../../../modules/file/model'
import { SearchFilterType } from '../../../modules/search/model'
import { GOZO_SLEEPING } from '../../../constants/common'
import { MAX_FILES } from './index'

export const decideView = (searchContentType: SearchFilterType): View =>
  [SearchFilterType.Image, SearchFilterType.Video].includes(searchContentType) ? View.Grid : View.List

export const insertFiles = (list: File[], index: number, files: File[]): File[] => {
  if (GOZO_SLEEPING) return []
  list = [...list]
  files = [...files]

  let item
  while (index < MAX_FILES && (item = files.shift())) {
    list[index] = item
    index++
  }

  return list
}
