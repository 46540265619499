import React, { useContext } from 'react'
import FileIcon from '../../../Icons/fileIcon'
import { File } from '../../../../modules/file/model'
import { shouldDisplayThumbnail } from '../helpers'
import { ConfigContext } from '../../../../modules/config/context'
import UserContext from '../../../../modules/user/context'
import AppContext from '../../../../modules/app/context'
import { createFileDetailLink } from '../../../../modules/file/api'
import { Service } from '../../../../modules/config/model'
import SearchStore from '../../../../modules/search/store'
import { Image, ThumbLink, ThumbWrapper } from './styled'
import VideoPreview from './VideoPreview'

interface Props {
  file: File
  itemWidth: number
  itemRadius: number
}

const Thumb: React.FC<Props> = ({ file, itemWidth, itemRadius }) => {
  const config = useContext(ConfigContext)
  const user = useContext(UserContext)
  const app = useContext(AppContext)
  const searchQuery = SearchStore.getValue().searchQuery

  const fileLink = createFileDetailLink(
    user.sessionId,
    config.service,
    file.matchingType,
    searchQuery,
    config.language,
    config.country,
    app.platform,
    app.backDomain,
    file.url,
    file.id || null
  )

  if (!shouldDisplayThumbnail(file, config.service)) {
    return null
  }

  const renderComponent = () => {
    const isUlozto = file.id && [Service.Ulozto].includes(config.service)
    const hasVideoPreview = file.preview && file.preview.video
    const hasImagePreview = file.preview && (file.preview.smallImage || file.preview.largeImage)
    const isStreamable = !!file.format?.streamingTypes?.length

    if (isUlozto && hasVideoPreview) {
      return <VideoPreview id={file.id} preview={file.preview} fileName={file.name} isStreamable={isStreamable} />
    } else if (hasImagePreview) {
      return (
        <ThumbWrapper className="wrapper">
          <Image src={file.preview.smallImage || file.preview.largeImage} alt={file.name} loading="lazy" />
        </ThumbWrapper>
      )
    } else {
      return (
        <ThumbWrapper className="wrapper">
          <FileIcon extension={file.extension} contentType={file.hash?.contentType} size={'75'} />
        </ThumbWrapper>
      )
    }
  }

  return (
    <ThumbLink className={'image ' + (file.preview ? 'thumb' : 'light')} href={fileLink} $itemWidth={itemWidth} $itemRadius={itemRadius}>
      {renderComponent()}
    </ThumbLink>
  )
}

export default Thumb
