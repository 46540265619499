import React from 'react'

const TypeArchiveSvg: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em">
    <path
      fill="currentColor"
      d="M464 32h-416C21.49 32 0 53.49 0 80v64C0 152.8 7.25 160 16 160H32v256c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V160h16C504.8 160 512 152.8 512 144V80C512 53.49 490.5 32 464 32zM448 416c0 17.6-14.4 32-32 32H96c-17.6 0-32-14.4-32-32V160h384V416zM480 128H32V80C32 71.25 39.25 64 48 64h416C472.8 64 480 71.25 480 80V128zM176 256h160C344.8 256 352 248.8 352 240S344.8 224 336 224h-160C167.2 224 160 231.2 160 240S167.2 256 176 256z"
    />
  </svg>
)

export default TypeArchiveSvg
