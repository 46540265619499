import { useState, useEffect } from 'react'
import { useWindowVisited } from './useWindowVisited'

// check whether captcha provider was initialized
// it gets initialized when user visits the tab and recaptcha is in visible viewport
export const useCaptchaInitialized = (isInViewport: boolean): boolean => {
  const windowVisited = useWindowVisited()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (!initialized && windowVisited && isInViewport) {
      setInitialized(true)
    }
  }, [windowVisited, isInViewport])

  return initialized
}
