import React from 'react'

const FilterSvg: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em">
    <path
      fill="currentColor"
      d="M249.1,444c1.4,2.5,4.1,4,6.9,4c2.8,0,5.5-1.5,6.9-3.9l27.6,16.2c-7.2,12.2-20.3,19.8-34.5,19.8c-14.2,0-27.3-7.5-34.5-19.8 l-216-368c-7.3-12.3-7.3-27.6-0.2-40.1C12.4,39.7,25.7,32,40,32h432c14.3,0,27.6,7.7,34.7,20.1c7.1,12.5,7,27.8-0.2,40.1l-216,368 l-27.6-16.2l216-367.2c1.4-3.3,1.5-6.4,0-8c-1.4-3.4-4.1-4.9-6.9-4.9H40c-2.9,0-5.5,1.5-6.9,4.9c-1.5,1.6-1.5,4.7,0,8L249.1,444z"
    />
  </svg>
)

export default FilterSvg
