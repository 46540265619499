import styled from 'styled-components'
import { Theme } from '../../modules/config/theme'

export const Switch = styled.div`
  margin-bottom: 30px;

  strong {
    display: block;
    margin-bottom: 15px;
  }
`

export const Button = styled.div<{ selected?: Theme }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;

  svg {
    width: 25px;
    height: 20px;

    path {
      fill: ${(props) => (props.selected === Theme.Dark ? ({ theme }) => theme.color.gold : ({ theme }) => theme.color.black)};
    }
  }
`
