import * as Sentry from '@sentry/react'
import React from 'react'
import { SessionId } from './model'
import { obtainSessionId } from './sessionId'

interface UserContextValue {
  sessionId: SessionId
}

const sessionId = obtainSessionId()

Sentry.setTag('userSessionId', sessionId)
const UserContext = React.createContext<UserContextValue>({ sessionId })

export default UserContext
