import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { isDevelopment } from '../config/application_mode'
import { isIgnorableError } from '../api/error'
import ErrorBoundaryScreen from '../../components/ErrorBoundaryScreen'
import { GOZO_SLEEPING } from '../../constants/common'

if (!GOZO_SLEEPING) {
  Sentry.init({
    debug: isDevelopment(),
    dsn: window.Config.SENTRY_DSN_API,
    release: process.env.VERSION,
    environment: window.Config.ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()],
    autoSessionTracking: false,
    tracesSampleRate: 0.001,
    normalizeDepth: 8,
    ignoreErrors: [
      // #415704
      'ibFindAllVideos',
      'vc_request_action',
      'BetterJsPop',
      'originalPrompt',
      'translate_fp',
      'MyAppGetAllDatas',
      'doJDSubmit',
      'UnhandledRejection: Non-Error promise rejection captured with value: Timeout',
      // #447684
      'Can not do with retry',
      'request is not allowed by the user agent',
      'WebAssembly',
      'NotSupportedError',
    ],
    beforeSend(event) {
      // ignore network errors from google bot - #416360
      if (
        event &&
        event.message &&
        event.message.match(/network error/i) &&
        event.request?.headers &&
        event.request?.headers['User-Agent']?.includes('Googlebot')
      ) {
        return null
      }

      // ignore errors from google recaptcha
      if (event.exception && event.exception.values) {
        for (const exception of event.exception.values) {
          if (exception.value?.includes('recaptcha')) {
            return null
          }

          for (const frame of exception.stacktrace?.frames || []) {
            if (frame.filename && frame.filename.includes('recaptcha')) {
              return null
            }
          }
        }
      }

      return event
    },
  })
}

// https://docs.sentry.io/platforms/javascript/guides/react/features/error-boundary/
export const errorBoundaryFallback = ({ error, resetError }: { error: Error; resetError: () => void }): JSX.Element => {
  if (GOZO_SLEEPING) return <></>
  if (isIgnorableError(error)) {
    resetError()
  }

  return <ErrorBoundaryScreen />
}
