import React from 'react'

const ThemeDarkSvg: React.FC = () => (
  <svg viewBox="0 0 800 800" width="1em" height="1em" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M383.983 416.045c-63.759-63.803-80.87-155.969-52.634-235.743-30.848 10.912-60.013 27.981-84.662 52.634-88.5 88.498-88.5 231.95 0 320.404 88.456 88.458 231.866 88.502 320.361 0 24.737-24.65 41.762-53.816 52.636-84.662-79.73 28.236-171.938 11.125-235.7-52.631z"
    />
  </svg>
)

export default ThemeDarkSvg
