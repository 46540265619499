import { Subscription } from 'rxjs'
import { useSubscribe } from './useSubscribe'

interface Store<T> {
  getValue: () => T
  subscribe: (next: (value: T) => void) => Subscription
}

export const useStore = <T>(store: Store<T>): T => {
  return useSubscribe(store.subscribe, store.getValue())
}
