import React, { useCallback, CSSProperties, useContext } from 'react'
import { useInView } from 'react-intersection-observer'
import _ from 'lodash'
import type { VirtualItem } from '@tanstack/virtual-core'
import { File } from '../../../modules/file/model'
import Skeleton from '../Skeleton'
import { isFile, shouldDisplayThumbnail } from '../View/helpers'
import Thumb from '../View/Thumb'
import { Name } from '../View/Name'
import { Properties } from '../View/Properties'
import { ConfigContext, ConfigContextValue } from '../../../modules/config/context'
import theme from '../../../constants/GlobalTheme'
import { Item, RowWrapper } from './styled'

interface Props {
  items: File[]
  virtualRow: VirtualItem<unknown>
  index: number
  columns: number
  itemWidth: number
  gridGap: number
  allowSkeletons: boolean
  listWrapperRef: HTMLDivElement | null
  visibleRows: Record<number, boolean>
  updateVisibleRows: (id: number, value: boolean) => void
}

const renderItem = (
  config: ConfigContextValue,
  allowSkeletons: boolean,
  itemWidth: number,
  item: File | undefined,
  index: number
): JSX.Element | null => {
  switch (true) {
    case isFile(item):
      const file = item as File
      const fileType = file.hash?.contentType
      return (
        <Item
          key={index}
          className={!shouldDisplayThumbnail(file, config.service) ? 'no-thumb' : ''}
          data-testid={`fileList-item-${fileType}`}
        >
          <Thumb file={file} itemWidth={itemWidth} itemRadius={0} />
          <Name file={file} isGridView={true} />
          <Properties file={file} />
        </Item>
      )
    case allowSkeletons:
      return <Skeleton key={index} style={{ width: 100 + '%' }} />
    default:
      return null
  }
}

const ViewGrid: React.FC<Props> = ({ allowSkeletons, columns, gridGap, index, items, itemWidth, updateVisibleRows, virtualRow }) => {
  const { ref: inViewRef, inView } = useInView({
    threshold: 0,
    triggerOnce: false,
  })
  const rowIndexes = _.range(index, index + columns)
  const config = useContext(ConfigContext)
  const inViewTypedRef = inViewRef as React.Ref<Element | Node | undefined>
  const measureElementTypedRef = virtualRow.measureElement as React.Ref<HTMLDivElement>

  const style: CSSProperties = {
    width: '100%',
    transform: `translateY(${virtualRow.start}px)`,
    zIndex: 1,
    background: theme.color.bg.main,
  }

  const combinedRef = useCallback<React.RefCallback<HTMLDivElement>>(
    (node) => {
      if (_.isFunction(inViewTypedRef)) inViewTypedRef(node)
      if (_.isFunction(measureElementTypedRef)) measureElementTypedRef(node)
    },
    [inViewRef, measureElementTypedRef]
  )

  return (
    <RowWrapper ref={combinedRef} $itemWidth={itemWidth} $gridGap={gridGap} $gridColumns={columns} style={style}>
      {rowIndexes.map((index) => {
        updateVisibleRows(index, inView)
        return renderItem(config, allowSkeletons, itemWidth, items[index], index)
      })}
    </RowWrapper>
  )
}

export default ViewGrid
