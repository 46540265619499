import { useState, useEffect } from 'react'
import { TUndefined } from '../types'

declare global {
  interface Window {
    [script: string]: unknown
  }
}

// load external script and return it's value
export const useScript = <T>(
  src: string,
  name: string,
  onLoad?: (script: T) => void,
  onLoadError?: (err: Event | string) => void
): TUndefined<T> => {
  const [lib, setLib] = useState<TUndefined<T>>(undefined)

  useEffect(() => {
    // if aleady loaded, return cached version
    const scriptExists = document.querySelector(`script[src="${src}"]`)
    if (lib || scriptExists) return

    const script = document.createElement('script')
    script.src = src
    script.async = true
    script.onload = () => {
      setLib(window[name] as T)
      onLoad?.(window[name] as T)
    }

    script.onerror = (err) => onLoadError?.(err)

    document.body.appendChild(script)
    return () => {
      if (script) document.body.removeChild(script)
    }
  }, [src, onLoad])

  return lib
}
