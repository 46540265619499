import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import _ from 'lodash'
import Icon from '../../Icons'
import { MaxQueryLength, SearchQuery } from '../../../modules/search/model'
import SearchStore, { SearchStoreDefaults } from '../../../modules/search/store'
import { useStore } from '../../../hooks/useStore'
import { ConfigContext } from '../../../modules/config/context'
import { createAppSearchParams, createSearchURL, URLParamName } from '../../../routes/Index/searchParams'
import { getServiceRoute } from '../../../modules/router/service'
import { Service } from '../../../modules/config/model'
import SearchSuggestStore from '../../../modules/searchSuggest/store'
import SuggestBox from '../SuggestBox'
import { Button, Input, Search } from './styled'

interface Props {
  isHome: boolean
}

const getInputPlaceholder = (service: Service): string => {
  switch (service) {
    case Service.Ulozto:
      return 'components.Search.QueryBox.placeholderUlozto'
    default:
      return 'components.Search.QueryBox.placeholder'
  }
}

const QueryBox: React.FC<Props> = ({ isHome }) => {
  const { t } = useTranslation()
  const config = useContext(ConfigContext)
  const searchStore = useStore(SearchStore)
  const searchSuggestStore = useStore(SearchSuggestStore)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [input, setInput] = useState<SearchQuery>(searchStore.searchQuery)
  const [activeSuggestItem, setActiveSuggestItem] = useState(-1)
  const [urlSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const throttledSearchSuggest = useCallback(_.throttle(SearchSuggestStore.setQuery, 200, { trailing: true }), [])

  useEffect(() => {
    setInput(searchStore.searchQuery)
  }, [searchStore.searchQuery, searchStore.forceReload])

  useEffect(() => {
    setActiveSuggestItem(-1)
  }, [searchSuggestStore.items])

  const searchSuggestItems = SearchSuggestStore.getValue().items
  // TODO: SearchSuggest temporary disabled for Cypress tests,
  // we need to find a way tests do not fail when search suggest is enabled because of multiple requests
  const isSearchSuggestEnabled = !window.Cypress && window.Config.SEARCH_SUGGEST_SERVICES.includes(config.service)

  return (
    <Search $isHome={isHome}>
      <form
        data-testid="form"
        onSubmit={(e) => {
          e.preventDefault()

          const searchQuery = activeSuggestItem > -1 ? searchSuggestItems[activeSuggestItem].suggestion : input.substring(0, MaxQueryLength)

          throttledSearchSuggest.cancel()
          inputRef.current?.blur()
          SearchStore.set({ searchQuery })
          SearchStore.makeForceReload()
          SearchSuggestStore.reset()
          const appParams = createAppSearchParams(input, SearchStoreDefaults.searchFilter)
          if (!urlSearchParams.has(URLParamName.Query)) {
            navigate(`${getServiceRoute(config.service, config.country)}${createSearchURL(appParams)}`, { replace: false })
          }
        }}
      >
        <Input
          type="search"
          enterKeyHint="search"
          placeholder={t(getInputPlaceholder(config.service))}
          data-testid="input"
          value={input}
          onChange={(e) => {
            setInput(e.target.value)
            if (isSearchSuggestEnabled) {
              throttledSearchSuggest(e.target.value.substring(0, MaxQueryLength))
            }
          }}
          onFocus={(e) => {
            if (input && isSearchSuggestEnabled) {
              throttledSearchSuggest(e.target.value.substring(0, MaxQueryLength))
            }
          }}
          onKeyDown={(e) => {
            if (e.code === 'ArrowUp' && activeSuggestItem > -1) {
              e.preventDefault()
              setActiveSuggestItem(activeSuggestItem - 1)
            } else if (e.code === 'ArrowDown' && activeSuggestItem < searchSuggestItems.length - 1) {
              e.preventDefault()
              setActiveSuggestItem(activeSuggestItem + 1)
            } else if (e.code === 'Escape') {
              e.preventDefault()
              SearchSuggestStore.reset()
            }
          }}
          autoFocus={isHome}
          maxLength={MaxQueryLength}
          ref={inputRef}
          $isHome={isHome}
          disabled
        />
        <Button type="submit" data-testid="button" $isHome={isHome} disabled>
          <Icon.Search />
        </Button>
        <SuggestBox
          isHome={isHome}
          activeItem={activeSuggestItem}
          onScroll={(hasItems: boolean) => {
            if (hasItems) {
              inputRef.current?.blur()
            }
          }}
        />
      </form>
    </Search>
  )
}

export default QueryBox
