import type { Resource } from 'i18next'
import i18next, { use } from 'i18next'
import { initReactI18next } from 'react-i18next'
import csTranslation from './resources/cs.json'
import enTranslation from './resources/en.json'
import skTranslation from './resources/sk.json'
import { Language } from './model'

const resources = {
  [Language.CS]: {
    translation: csTranslation,
  },
  [Language.EN]: {
    translation: enTranslation,
  },
  // we use EN translation for PL language
  [Language.PL]: {
    translation: enTranslation,
  },
  [Language.SK]: {
    translation: skTranslation,
  },
} as Resource

use(initReactI18next).init({
  resources,
  lng: Language.CS,
  fallbackLng: Object.values(Language),
  interpolation: {
    escapeValue: false,
  },
  simplifyPluralSuffix: true,
  returnNull: false,
  react: {
    useSuspense: true,
  },
})

export default i18next
