import React, { CSSProperties, forwardRef } from 'react'
import { Item } from './styled'

interface Props {
  style: CSSProperties
  ref?: React.ForwardedRef<HTMLDivElement>
}

const Skeleton: React.FC<Props> = forwardRef<HTMLDivElement, Props>(({ style }, ref) => <Item style={style} ref={ref} />)

export default Skeleton
