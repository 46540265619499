import { Country } from '../language/model'
import { getDomainsConfig } from './domains'
import { Service } from './model'

export const getCountriesForService = (service: Service): Country[] => {
  return getDomainsConfig()
    .filter((entry) => entry.service === service)
    .map((entry) => entry.country)
}

export const getCountriesForServiceWithoutPL = (service: Service): Partial<Country>[] => {
  return getDomainsConfig()
    .filter((entry) => entry.service === service)
    .filter((entry) => entry.country !== Country.PL)
    .map((entry) => entry.country)
}
