import React from 'react'

interface Props {
  size?: string
  radius?: number
}

const XLS: React.FC<Props> = ({ size, radius }) => (
  <svg viewBox="0 0 600 600" preserveAspectRatio="xMinYMid" width={size} height={size}>
    <rect rx={radius} ry={radius} width="100%" height="100%" fill="#1e7145" />
    <path
      d="M126.706 153.724V449.11l207.732 28.44v-30.349c.465.032.925.069 1.397.069h106.434c12.447 0 22.576-10.133 22.576-22.577V177.961c0-12.446-10.129-22.577-22.576-22.577H335.835c-.472 0-.932.046-1.397.074V126l-207.732 27.724zm207.732 185.35h47.102v20.963h-47.102v-20.964zm0-27.415v-20.965h47.102v20.965h-47.102zm-154.069 24.822l-5.795-12.807c-2.348-4.89-3.844-8.496-5.612-12.513l-.19-.003c-1.296 3.946-2.868 7.462-4.793 12.167l-5.102 12.1-15.414-.752 17.32-33.528-16.714-32.874 15.53-.638 5.387 12.247a223.003 223.003 0 014.717 11.303h.183c1.498-4.408 2.714-7.5 4.31-11.532l5.41-12.84 16.98-.703-18.507 34.691 19.51 36.523-17.22-.84zm70.933 3.471l-45.44-2.22v-72.007l15.939-.659v59.494l29.498.939v14.453h.003zm30.117 2.643c-9.316-.473-18.324-3.21-22.756-5.815l3.608-14.248c4.828 2.544 12.353 5.215 20.252 5.48 8.673.29 13.323-2.999 13.323-8.23 0-4.995-4.025-7.91-14.059-11.45-13.5-4.64-22.087-11.76-22.087-22.938 0-13.11 11.321-23.631 30.747-24.463 9.588-.41 16.795 1.21 22.012 3.176l-4.439 14.991c-3.495-1.483-9.64-3.604-17.95-3.392-8.157.212-12.06 3.813-12.06 7.885 0 5.01 4.639 7.183 15.442 10.995 15.22 5.238 22.565 12.784 22.565 24.33.001 13.748-11.356 24.85-34.598 23.679zm54.417-174.31H442.27c5.335 0 9.675 4.342 9.675 9.676v246.732c0 5.336-4.34 9.676-9.675 9.676H335.836c-.479 0-.938-.07-1.398-.139v-70.967h103.815v-99.984H334.438v-94.847c.46-.07.92-.146 1.398-.146zm99.192 122.408v20.966h-50.26v-20.966h50.26zm-50.26-3.225v-20.964h50.26v20.964h-50.26zm50.26 51.605v20.964h-50.26v-20.964h50.26z"
      fill="#fff"
    />
  </svg>
)

export default XLS
