import { useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { useScript } from '../../hooks/useScript'
import { TNullable, TUndefined } from '../../types'
import { ITurnstile, ITurnstileCallbacks, ITurnstileOptions } from './model'
import { Container } from './styled'

type TProps = React.PropsWithChildren<Partial<ITurnstileOptions> & Partial<ITurnstileCallbacks>>

const LIB_SRC = 'https://challenges.cloudflare.com/turnstile/v0/api.js'
const LIB_NAME = 'turnstile'

export const TurnstileCaptcha = forwardRef<TUndefined<ITurnstile>, TProps>(
  ({ onVerify, onError, onTimeout, onExpire, onLoad, onBeforeInteractive, onAfterInteractive, children, ...turnstileOptions }, ref) => {
    const widgetId = useRef<string>('')
    const internalRef = useRef<TNullable<HTMLDivElement>>(null)
    const turnstile = useScript<ITurnstile>(LIB_SRC, LIB_NAME)

    // expose turnstile instance so it can be used outside of this component
    useImperativeHandle(ref, () => turnstile)

    const config: ITurnstileOptions = {
      callback: (tokenValue: string) => onVerify?.(tokenValue),
      'error-callback': () => onError?.(),
      'expired-callback': () => onExpire?.(),
      'timeout-callback': () => onTimeout?.(),
      'after-interactive-callback': () => onAfterInteractive?.(),
      'before-interactive-callback': () => onBeforeInteractive?.(),
      appearance: 'always',
      size: 'normal',
      refreshExpired: 'never', // no refresh on expired token
      execution: 'render',
      retry: 'never',
      ...turnstileOptions,
      sitekey: window.Config.TURNSTILE_SITE_KEY,
    }

    const renderWidget = (instance?: ITurnstile, ref?: TNullable<HTMLDivElement>) => {
      if (!instance || !ref) return
      const id = instance.render(ref, config)
      widgetId.current = id
      onLoad?.(widgetId.current)
    }

    const removeWidget = (instance?: ITurnstile, id?: string) => {
      if (!instance || !id) return
      instance.remove(id)
    }

    useEffect(() => {
      renderWidget(turnstile, internalRef.current)
      return () => removeWidget(turnstile, widgetId.current)
    }, [turnstile])

    return (
      <Container>
        <div ref={internalRef} />
        {children}
      </Container>
    )
  }
)
