const theme = {
  layout: {
    width: 1030,
    sizes: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1030,
      xxl: 1400,
    },
  },
  font: 'Montserrat, Open Sans, sans-serif',
  color: {
    bg: {
      main: 'var(--c-bg)',
      input: 'var(--c-bg-input)',
      thumb: 'var(--c-bg-thumb)',
      shade5: 'var(--c-bg-shd-5)',
      shade10: 'var(--c-bg-shd-10)',
      shade20: 'var(--c-bg-shd-20)',
      shade30: 'var(--c-bg-shd-30)',
      shade40: 'var(--c-bg-shd-40)',
      shade50: 'var(--c-bg-shd-50)',
      shade60: 'var(--c-bg-shd-60)',
      shade70: 'var(--c-bg-shd-70)',
      shade80: 'var(--c-bg-shd-80)',
      shade90: 'var(--c-bg-shd-90)',
    },
    shadow: 'var(--c-shadow)',
    transparent: {
      overlay: 'hsla(0, 0%, 50%, .75)',
    },
    text: 'var(--c-text)',
    link: 'var(--c-link)',
    linkHover: 'var(--c-link-hover)',
    red: 'var(--c-red)',
    green: 'var(--c-green)',
    blue: 'var(--c-blue)',
    gold: 'var(--c-gold)',
    white: 'var(--c-white)',
    black: 'var(--c-black)',
    theme: 'var(--c-theme)',
    plan1: 'var(--c-plan-1)',
    plan2: 'var(--c-plan-2)',
    plan3: 'var(--c-plan-3)',
  },
  size: {
    querybox: 650,
    borderRadius: 5,
  },
}

export default theme
