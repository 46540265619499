import { createGlobalStyle } from 'styled-components'
import fontMontserratLightWoff2 from 'url:../public/font/Montserrat-Light.woff2'
import fontMontserratLightWoff from 'url:../public/font/Montserrat-Light.woff'
import fontMontserratRegularWoff2 from 'url:../public/font/Montserrat-Regular.woff2'
import fontMontserratRegularWoff from 'url:../public/font/Montserrat-Regular.woff'
import fontMontserratMediumWoff2 from 'url:../public/font/Montserrat-Medium.woff2'
import fontMontserratMediumWoff from 'url:../public/font/Montserrat-Medium.woff'

export const GlobalStyles = createGlobalStyle`
  :root {
    --c-bg: #fff;
    --c-bg-shd-5: hsla(0, 0%, 97%, 1);
    --c-bg-shd-10: hsla(0, 0%, 90%, 1);
    --c-bg-shd-20: hsla(0, 0%, 80%, 1);
    --c-bg-shd-30: hsla(0, 0%, 70%, 1);
    --c-bg-shd-40: hsla(0, 0%, 60%, 1);
    --c-bg-shd-50: hsla(0, 0%, 50%, 1);
    --c-bg-shd-60: hsla(0, 0%, 40%, 1);
    --c-bg-shd-70: hsla(0, 0%, 30%, 1);
    --c-bg-shd-80: hsla(0, 0%, 20%, 1);
    --c-bg-shd-90: hsla(0, 0%, 10%, 1);
    --c-bg-input: #fff;
    --c-bg-thumb: hsla(0, 0%, 17%, 1);
    --c-text: #333;
    --c-link: #3969ef;
    --c-link-hover: #50a7ff;
    --c-red: #bf1010;
    --c-green: #0d7b0d;
    --c-blue: #6babe0;
    --c-gold: #edba1e;
    --c-white: #fff;
    --c-black: #000;
    --c-theme: #7aa5d2;
    --c-plan-1: #5990d9;
    --c-plan-2: #a84ace;
    --c-plan-3: #c4555a;
    --c-shadow: hsla(0, 0%, 80%, 1);
    color-scheme: light;
  }

  .dark-theme {
    --c-bg: #000;
    --c-bg-shd-5: hsla(0, 0%, 10%, 1);
    --c-bg-shd-10: hsla(0, 0%, 17%, 1);
    --c-bg-shd-20: hsla(0, 0%, 27%, 1);
    --c-bg-shd-30: hsla(0, 0%, 37%, 1);
    --c-bg-shd-40: hsla(0, 0%, 47%, 1);
    --c-bg-shd-50: hsla(0, 0%, 57%, 1);
    --c-bg-shd-60: hsla(0, 0%, 67%, 1);
    --c-bg-shd-70: hsla(0, 0%, 77%, 1);
    --c-bg-shd-80: hsla(0, 0%, 87%, 1);
    --c-bg-shd-90: hsla(0, 0%, 97%, 1);
    --c-bg-input: #000;
    --c-bg-thumb: hsla(0, 0%, 17%, 1);
    --c-text: #ccc;
    --c-link: #7aa5d2;
    --c-link-hover: #2e95ff;
    --c-red: #d43023;
    --c-green: #67a953;
    --c-blue: #6babe0;
    --c-gold: #edba1e;
    --c-white: #fff;
    --c-black: #000;
    --c-theme: #7aa5d2;
    --c-plan-1: #5990d9;
    --c-plan-2: #a84ace;
    --c-plan-3: #c4555a;
    --c-shadow: hsla(0, 0%, 30%, 1);
  }

  /** Montserrat Light **/
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    src: url(${fontMontserratLightWoff2}) format("woff2"),
    url(${fontMontserratLightWoff}) format("woff");
  }

  /** Montserrat Regular **/
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: url(${fontMontserratRegularWoff2}) format("woff2"),
    url(${fontMontserratRegularWoff}) format("woff");
  }

  /** Montserrat Medium **/
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    src: url(${fontMontserratMediumWoff2}) format("woff2"),
    url(${fontMontserratMediumWoff}) format("woff");
  }

  body, html {
    height: 100%;
  }

  body {
    box-sizing: border-box;
    overflow-y: scroll;
    padding: 0;
    margin: 0;
    background-color: ${({ theme }) => theme.color.bg.main};
    color: ${({ theme }) => theme.color.text};
    font-family: ${({ theme }) => theme.font};
    font-size: 14px;
  }

  a {
    color: ${({ theme }) => theme.color.link};
    text-decoration: none;
  }

  input, textarea, select {
    color: ${({ theme }) => theme.color.text};
    font-family: ${({ theme }) => theme.font};
  }

  label,
  label input {
    &:hover {
      cursor: pointer;
    }
  }

  input,
  textarea,
  button {
    &:disabled {
      opacity: 0.6;
    }
  }

  #root {
    height: 100%;
  }
`
