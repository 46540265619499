// ISO 639-1
export enum Language {
  CS = 'cs',
  SK = 'sk',
  EN = 'en',
  PL = 'pl',
}

// ISO 3166-1
export enum Country {
  CZ = 'cz',
  SK = 'sk',
  EN = 'en',
  PL = 'pl',
}

export type TRouteCountryParams = {
  country?: Country
}
