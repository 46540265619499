import { Language, Country } from './model'

export const LanguageToCountry = {
  [Language.CS]: Country.CZ,
  [Language.SK]: Country.SK,
  [Language.EN]: Country.EN,
  [Language.PL]: Country.PL,
} as Record<Language, Country>

export const CountryToLanguage = {
  [Country.CZ]: Language.CS,
  [Country.SK]: Language.SK,
  [Country.EN]: Language.EN,
  [Country.PL]: Language.PL,
} as Record<Country, Language>
