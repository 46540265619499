import React from 'react'

const TypeLiveMachrSvg: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600" width="1em" height="1em">
    <g fill="#F7911A">
      <path d="m552.5 47.5v505h-505v-505h505m20-20h-545v545h545v-545z" />
      <path d="m161.7 161.3-109.2 109.2-1.2-65.2 153.2-153.2h66.2l-109 109.2zm51.6 51.6-160.8 160.9-0.6-33.5-0.4-33.5 127.1-127.3 127.3-127.4h68.2l-160.8 160.8zm155.8-54.2c-58.5 58.9-154 154.6-211.9 213.1l-105.5 106.3-0.2-34.7v-34.5l177.6-178.4 177.8-178.4h68.6l-106.4 106.6zm179.6-91.6v15.2l-465 465h-32.2v-36l459.2-459.2h38v15zm-2 335v33l-78.6-77.8-78.4-78 16.6-17.2 16.6-17 123.8 123.8v33.2zm-86.1-18 88.1 88.3-0.4 33.5-0.6 33.5-104.9-104.8-104.9-104.9 15.8-17c8.8-9.4 16.6-17 17.2-17 0.8 0.1 41.1 39.9 89.7 88.4zm-39 62.4c55 55 99.8 100.4 99.8 101 0 0.4-15.6 0.6-34.7 0.2l-34.7-0.6-81.3-82.7c-44.8-45.4-81.5-83.5-81.3-84.2 0.2-2.1 29.1-33.7 30.8-33.7 0.8 0 46.4 45 101.4 100zm-37.1 100.4-33.5 0.4-57.1-56.9c-31.4-31.4-56.9-57.7-56.7-58.5 0.4-0.6 7.6-8.8 16-18.1l15.4-17 149.6 149.6-33.7 0.5z" />
    </g>
  </svg>
)

export default TypeLiveMachrSvg
