import React, { useContext } from 'react'
import { File } from '../../../../modules/file/model'
import { ConfigContext } from '../../../../modules/config/context'
import UserContext from '../../../../modules/user/context'
import AppContext from '../../../../modules/app/context'
import { createFileDetailLink } from '../../../../modules/file/api'
import SearchStore from '../../../../modules/search/store'
import { NameWrapper, Link } from './styled'

interface Props {
  file: File
  isGridView: boolean
}

export const Name: React.FC<Props> = ({ file, isGridView }) => {
  const config = useContext(ConfigContext)
  const user = useContext(UserContext)
  const app = useContext(AppContext)
  const searchQuery = SearchStore.getValue().searchQuery

  const fileLink = createFileDetailLink(
    user.sessionId,
    config.service,
    file.matchingType,
    searchQuery,
    config.language,
    config.country,
    app.platform,
    app.backDomain,
    file.url,
    file.id || null
  )

  return (
    <NameWrapper className="name">
      <h2>
        <a href={fileLink} title={file.name}>
          {file.name}
        </a>
      </h2>
      {!!file.displayUrl && !isGridView && <Link className="link">{new URL(file.displayUrl).host}</Link>}
    </NameWrapper>
  )
}
