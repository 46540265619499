import { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { ConfigContext, ConfigDispatchContext } from '../modules/config/context'
import { Country } from '../modules/language/model'
import { CountryToLanguage } from '../modules/language/converter'
import { getPrimaryCaptchaProvider } from '../modules/rateLimiting/captchaProviders'

// set init value for country from URL params or config
// get actual value
export const useCountry = (): Country => {
  const location = useLocation()
  const config = useContext(ConfigContext)
  const dispatchConfig = useContext(ConfigDispatchContext)
  const [country, setCountry] = useState<Country>(config.country)

  const getCountry = (): Country => {
    const pathCountry = location.pathname?.split('/')?.[1] as Country
    if ([Country.EN, Country.PL, Country.SK, Country.CZ].includes(pathCountry)) {
      return pathCountry
    }
    return config.country || Country.EN
  }

  useEffect(() => {
    const country = getCountry()
    setCountry(country)
    dispatchConfig({
      country,
      language: CountryToLanguage[country],
      initialized: true,
      service: config.service,
      captchaProvider: getPrimaryCaptchaProvider(),
    })
  }, [location.pathname])

  return country
}
