import React, { useState } from 'react'

const MIN_SWIPE_DISTANCE = 50

interface Handlers {
  onTouchStart: (e: React.TouchEvent<HTMLElement>) => void
  onTouchMove: (e: React.TouchEvent<HTMLElement>) => void
  onTouchEnd: (e: React.TouchEvent<HTMLElement>) => void
}

const useSwipeActivated = (start: () => void): Handlers => {
  const [touchStart, setTouchStart] = useState<number | null>(null)
  const [touchEnd, setTouchEnd] = useState<number | null>(null)

  const onTouchStart = (e: React.TouchEvent<HTMLElement>) => {
    setTouchEnd(null) // otherwise the swipe is fired even with touch
    setTouchStart(e.targetTouches[0].clientX) // remember starting position
  }

  const onTouchMove = (e: React.TouchEvent<HTMLElement>) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) {
      return
    }

    // we don't care if swipe is left or right
    if (Math.abs(touchStart - touchEnd) > MIN_SWIPE_DISTANCE) {
      start()
    }
  }

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  }
}

export default useSwipeActivated
