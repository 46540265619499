import React from 'react'

interface Props {
  size?: string
  radius?: number
}

const TextFile: React.FC<Props> = ({ size, radius }) => (
  <svg viewBox="0 0 600 600" preserveAspectRatio="xMinYMid" width={size} height={size}>
    <rect rx={radius} ry={radius} width="100%" height="100%" fill="#999" />
    <path
      d="M439.2 196q5.6 5.6 9.6 15.2t4 17.6v230.4q0 8-5.6 13.6t-13.6 5.6H164.8q-8 0-13.6-5.6t-5.6-13.6v-320q0-8 5.6-13.6t13.6-5.6H344q8 0 17.6 4t15.2 9.6zm-88.8-48.8v75.2h75.2q-2-5.8-4.4-8.2l-62.6-62.6q-2.4-2.4-8.2-4.4zm76.8 305.6V248H344q-8 0-13.6-5.6t-5.6-13.6v-83.2H171.2v307.2h256zM222.4 280q0-2.8 1.8-4.6t4.6-1.8h140.8q2.8 0 4.6 1.8t1.8 4.6v12.8q0 2.8-1.8 4.6t-4.6 1.8H228.8q-2.8 0-4.6-1.8t-1.8-4.6V280zm147.2 44.8q2.8 0 4.6 1.8t1.8 4.6V344q0 2.8-1.8 4.6t-4.6 1.8H228.8q-2.8 0-4.6-1.8t-1.8-4.6v-12.8q0-2.8 1.8-4.6t4.6-1.8h140.8zm0 51.2q2.8 0 4.6 1.8t1.8 4.6v12.8q0 2.8-1.8 4.6t-4.6 1.8H228.8q-2.8 0-4.6-1.8t-1.8-4.6v-12.8q0-2.8 1.8-4.6t4.6-1.8h140.8z"
      fill="#fff"
    />
  </svg>
)

export default TextFile
