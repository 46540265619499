import { ECaptchaProvider } from './model'

const DEFAULT_CAPTCHA_PROVIDERS_PRIORITY = 'recaptcha,turnstile'

const getCaptchaProviders = (): ECaptchaProvider[] => Object.values<ECaptchaProvider>(ECaptchaProvider)

export const getDefaultProvider = (): ECaptchaProvider => ECaptchaProvider.Recaptcha

export const getPrimaryCaptchaProvider = (): ECaptchaProvider => {
  const allProviders = getCaptchaProviders()
  const envProviders = window.Config.CAPTCHA_PROVIDERS_PRIORITY || DEFAULT_CAPTCHA_PROVIDERS_PRIORITY
  const envProvidersArr = envProviders.split(',')
  const primaryProvider = envProvidersArr[0] as ECaptchaProvider
  const isAllowedProvider = allProviders.includes(primaryProvider)
  if (isAllowedProvider) return primaryProvider
  return getDefaultProvider()
}

export const isCaptchaProvider = (provider: ECaptchaProvider, value: ECaptchaProvider): boolean => value === provider
