import styled from 'styled-components'

export const Form = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.color.bg.shade5};
  border-radius: ${({ theme }) => theme.size.borderRadius}px;
  box-shadow: 0 0 20px ${({ theme }) => theme.color.shadow};

  div {
    margin-bottom: 10px;
  }
  label {
    display: block;
    padding: 5px 0;
    font-size: 14px;
    font-weight: 500;
  }
  input,
  textarea {
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
    border: 1px solid ${({ theme }) => theme.color.bg.shade20};
    border-radius: ${({ theme }) => theme.size.borderRadius}px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
  }
  textarea {
    min-height: 100px;
    resize: vertical;
  }
  .text {
    font-size: 11px;
  }
  .buttons {
    text-align: right;
  }
  .result {
    padding: 5px 10px;
    margin: 0;
    background-color: ${({ theme }) => theme.color.green};
    border-radius: ${({ theme }) => theme.size.borderRadius}px;
    color: ${({ theme }) => theme.color.white};
  }
  button {
    font-size: 16px;
  }
`
