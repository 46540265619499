import { css } from 'styled-components'

const logoHome = css`
  width: 280px;
  max-width: 280px;
  height: 170px;
  vertical-align: middle;
`

const logo = css`
  width: 135px;
  max-width: 135px;
  height: 40px;
  vertical-align: middle;

  @media screen and (min-width: 0px) and (max-width: ${({ theme }) => theme.layout.sizes.xl}px) {
    width: 35px;
    max-width: 35px;
    height: 35px;
  }
`

const mixins = {
  logoHome,
  logo,
}

export default mixins
