import { useState } from 'react'

interface IProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string
  fallbackSrc: string
}

const ImageWithFallback: React.FC<IProps> = ({ src, fallbackSrc, ...imgProps }) => {
  const [imgSrc, setImgSrc] = useState(src)
  const handleError = () => setImgSrc(fallbackSrc)
  return <img src={imgSrc} onError={handleError} {...imgProps} />
}

export default ImageWithFallback
