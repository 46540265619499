import React from 'react'

const TypeLiveDmSvg: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="1em" height="1em">
    <g fill="currentColor" transform="scale(1.3) translate(0 -6)">
      <path d="m63.5 80.7c-11.2-3-20.9-0.2-29.5 7.6 1.8-4 3.9-7.6 5.1-11.5 1.1-3.5 1.6-7.2 1.8-10.9 0.2-3.4-1.3-4.1-4.5-3.4-2 0.5-4.2 0.7-6.3 0.7-2.8 0-4.1-1.5-4.5-4.3-0.1-0.8-0.8-1.5-1.2-2.2-0.5-0.8-0.8-1.7-1.5-2.2-1.8-1.5-1.6-3.2-0.9-5.1 0.1-0.3-0.9-1.2-1.5-1.5-1.9-1.2-2.1-1.9-0.5-3.5 2.5-2.4 3.2-5.2 2.3-8.5-0.4-1.3-0.4-2.7-0.7-4.2-0.8 0.5-1.4 0.9-2.3 1.4 0.4-7 2.7-13.2 9.3-15.6 6.3-2.2 12.9-4.9 20-3.9 9.8 1.4 16.2 7.2 18.3 16.9 0.5 2.5 1.7 3.2 4.2 2.8 3.6-0.6 6.7 0.4 9 3.3 2.6 3.3 3.2 7.1 1.5 10.8-1.1 2.5-2.9 4.7-4.6 6.9-1.2 1.6-2.9 2.9-4.2 4.5-2.8 3.4-2.4 6.7 1 9.5 1.3 1 2.7 1.8 4.1 2.7 0 0.2-0.1 0.4-0.1 0.5-1.3-0.3-2.7-0.5-3.9-1-6.2-2.4-8.6-8.1-5.7-14.2 1-2.1 2.6-4 3.6-6.1 1.1-2 2.2-4.1 2.7-6.3 0.8-3.6-0.9-5.9-4.4-7.1-0.3 0.4-0.5 1.1-0.8 1.2-2.2 0.3-1.9 2.1-2.2 3.4-0.9 4.2-3 7.9-5.2 11.4-1.2 1.9-2.4 3.8-3.4 5.8-3.4 6.7-2.4 13.5 2.9 18.9 0.8 0.8 1.7 1.6 2.6 2.4-0.3 0.4-0.4 0.6-0.5 0.8z" />
    </g>
  </svg>
)

export default TypeLiveDmSvg
