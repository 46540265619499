import styled from 'styled-components'

export const List = styled.div`
  padding: 0 20px;
  font-size: 16px;
`

export const Info = styled.div`
  position: relative;
  padding: 20px 0 20px 30px;
  line-height: 20px;

  svg {
    position: absolute;
    left: 0;
    font-size: 22px;
  }

  span {
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
  div {
    margin: 25px 0 0;
    font-size: 14px;
  }
`
