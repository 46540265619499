import React from 'react'

interface Props {
  size?: string
  radius?: number
}

const Torrent: React.FC<Props> = ({ size, radius }) => (
  <svg viewBox="0 0 600 600" preserveAspectRatio="xMinYMid" width={size} height={size}>
    <rect rx={radius} ry={radius} width="100%" height="100%" fill="#80c774" />
    <path
      d="M472.752 290.484c0-95.742-77.634-173.358-173.376-173.358-95.76 0-173.376 77.634-173.376 173.358 0 73.8 46.206 136.674 111.186 161.676l-32.724-152.1-9.252-42.984-16.38-76.032 63.45-13.662 23.454 109.062c6.336 29.448 19.692 45.252 41.688 40.5 17.406-3.744 24.786-17.91 26.766-28.746.882-4.104.234-9.18-.846-14.148l-25.974-120.69 63.432-13.626 29.628 137.718c11.916 55.386 44.172 63.864 44.172 63.864s-43.542 9.378-61.794 13.284c-18.216 3.942-30.492-28.638-30.492-28.638l-1.26.27c-5.184 14.13-11.034 37.08-56.286 46.836-3.312.702-6.552 1.152-9.756 1.494l19.152 89.064c93.492-2.538 168.588-79.02 168.588-173.142z"
      fill="#fff"
    />
  </svg>
)

export default Torrent
