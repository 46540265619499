import React from 'react'
import { NavLink } from 'react-router-dom'
import { Area } from '../../styled'
import ImageWithFallback from '../../../../components/ImageWithFallback'
import useDocumentTitle from '../../../../hooks/useDocumentTitle'
import { useCountry } from '../../../../hooks/useCountry'
import * as img from '../../images'

const Help: React.FC = () => {
  const country = useCountry()
  useDocumentTitle('Nápověda pro Gozo')

  return (
    <Area className="has-image">
      <div className="text">
        <h1>Nápověda pro Gozo</h1>

        <h2>Základní informace pro uživatele</h2>

        <h3>Jak mohu filtrovat výsledky hledání, aby byly přesnější?</h3>
        <p>
          Filtry ve vyhledávači slouží k omezení výsledků vyhledávání na základě uživatelem specifikovaných kritérií. Pomáhají uživatelům
          zúžit výsledky a najít přesně to, co hledají.
        </p>
        <p>
          Vyhledávač Gozo může zobrazovat různé filtry, v závislosti na konkrétní implementaci a dostupných datech. Je-li filtrování
          dostupné, ikonka filtru se zobrazí v pravé části pod vyhledávací lištou. Mezi pokročilé možnosti filtrování patří následující:
        </p>
        <p>
          <em>Filtr obrázků:</em> umožňuje filtrovat obrázky podle kvality a velikosti.
        </p>
        <div className="image-preview">
          <ImageWithFallback src={img.imgFilterImageWebpCz} fallbackSrc={img.imgFilterImageCz} alt="gozo-image-filter" />
        </div>
        <p>
          <em>Filtr videí:</em> tento filtr pomáhá uživatelům najít videa podle délky, kvality, velikosti nebo možností pro přehrávání.
        </p>
        <div className="image-preview">
          <ImageWithFallback src={img.imgFilterVideoWebpCz} fallbackSrc={img.imgFilterVideoCz} alt="gozo-video-filter" />
        </div>
        <p>
          <em>Audio filtr:</em> tento filtr je specifický pro vyhledávání zvukových souborů a umožňuje vám najít audio soubory dle zvolených
          kritérií (např. délka kvalita, velikost a jiné).
        </p>
        <p>
          <em>Filtr dokumentů:</em> v některých případech můžete hledat dokumenty určité velikosti. Například pokud potřebuje dokumenty
          vhodné pro mobilní zařízení s omezeným úložným prostorem, může použít filtr velikosti, aby omezil výsledky na menší dokumenty.
        </p>
        <p>
          <em>Filtr archivů:</em> archivy jsou používány pro snazší manipulaci se soubory. Filtr archivů vám pomůže rozdělit výsledky
          vyhledávání podle velikosti.
        </p>
        <p>
          <em>Filtr zpráv:</em> zadejte seznam zpravodajských webů a blogů, které chcete prohledávat. Obdržíte nejnovější články uzpůsobené
          vašemu dotazu.
        </p>
        <p>
          <em>Místní filtr:</em> umožňuje uživatelům omezit výsledky na konkrétní geografickou oblast. Například při hledání restaurace si
          mohou uživatelé vybrat filtr pro jejich zemi, město nebo okres.
        </p>
        <p>
          <em>Filtr kvality:</em> se zaměřuje na kvalitu, kterou lze měřit různými faktory, jako je rozlišení, bitová rychlost a pod. Filtr
          kvality umožňuje uživatelům vyhledávat a omezovat výsledky na základě požadované kvality mediálního obsahu. Například je možné
          vyhledávat vysoké rozlišení videa, aby bylo zobrazení kvalitnější, nebo vyhledávat soubory s vyšší bitovou rychlostí pro lepší
          zvukovou kvalitu.
        </p>
        <p>
          <em>Filtr kategorie:</em> umožňuje filtrovat výsledky podle kategorií. Například při hledání produktu v e-shopu mohou uživatelé
          použít filtr pro konkrétní kategorii, jako jsou oblečení, elektronika, domácí spotřebiče a jiné.
        </p>
        <p>
          <em>Cenový filtr:</em> umožňuje omezit výsledky vyhledávání na určité cenové rozpětí. To pomáhá uživatelům najít produkty, které
          odpovídají jejich finančním možnostem.
        </p>
        <p>
          <em>Jazykový filtr:</em> umožňuje uživateli omezit výsledky vyhledávání na konkrétní jazyk. To je užitečné, pokud hledají
          informace ve specifickém jazyce.
        </p>

        <h3>Jak mohu změnit způsob řazení výsledků?</h3>
        <p>Výsledky jsou řazeny od nejvíce relevantních k nejméně relevantním. Změna způsobu řazení výsledků zatím není možná.</p>

        <h3>Jak zobrazit co nejvíce výsledků vyhledávání?</h3>
        <p>
          Při zobrazení výsledků vyhledávání můžete využít různé prezentace, v závislosti na typu obsahu, který hledáte. U vyhledávání
          obrázků a videí se obvykle používá rozložení, které využívá mřížku ("grid") a umožňuje zobrazení několika miniatur v jedné řadě
          přes celou šířku stránky. Tímto způsobem můžete vidět více výsledků na jedné obrazovce a rychleji mezi nimi procházet.
        </p>
        <p>
          Po zadání a potvrzení vyhledávacího dotazu se vám zobrazí výsledky vyhledávání. Scrolujte dolů a jakmile se blížíte ke konci
          stránky, automaticky se budou načítat další výsledky.
        </p>
        <p>
          Zobrazení výsledků vyhledávání může být rozdílné v závislosti na designových a uživatelských preferencích. V řádkovém zobrazení je
          každý výsledek prezentován na samostatném řádku. To umožňuje uživatelům snadněji porovnávat a procházet jednotlivé výsledky. V
          řádkovém zobrazení je k dispozici stručný textový úryvek, který poskytuje uživatelům náhled na to, jakým způsobem se výsledek
          vyhledávání váže k jejich hledaným klíčovým slovům. Tento styl zobrazení vyžaduje více prostoru a ukázky obrázků se často
          zobrazují menší. Oproti tomu dlaždicový způsob zobrazení výsledků vyhledávání umožňuje rychleji procházet a vyhodnocovat velké
          množství výsledků.
        </p>

        <h3>Mohu Gozo používat i na mobilním zařízení? </h3>
        <p>Gozo můžete používat na všech svých zařízeních, včetně mobilních. Vyhledávač je přizpůsobený pro mobilní zařízení.</p>

        <h3>Na jakých zařízeních je možné používat Gozo?</h3>
        <p>
          Gozo podporuje různá zařízení, včetně moderních smartphonů, tabletů, televizí, chytrých hodinek, chytrých lednic, smart displays
          (např. Amazon Echo Show) a konzolí (PlayStation, Xbox apod.). Pro optimální funkčnost je však nutné mít zařízení, které splňuje
          určité technické požadavky. Např. některé starší modely chytrých televizí nemusí být s naší službou kompatibilní.
        </p>

        <h3>Co dělat, když se objeví nevhodný obsah ve výsledcích hledání?</h3>
        <p>
          Narazíte-li na nevhodný nebo nebezpečný obsah, neváhejte nás kontaktovat na e-mailu:
          &#097;&#098;&#117;&#115;&#101;&#064;&#103;&#111;&#122;&#111;&#102;&#105;&#110;&#100;&#101;&#114;&#046;&#099;&#111;&#109;.
        </p>

        <h3>Kolik stojí Gozo?</h3>
        <p>
          Pro koncové uživatele je Gozo k dispozici zdarma. Máte-li zájem o implementaci vyhledávače do svého produktu nebo potřebujete
          integraci vyhledávače na míru pro vaši firmu, prohlédněte si <NavLink to={`/${country}/pricing`}>cenovou nabídku</NavLink>. Jsme
          připraveni poskytnout firmám individuální řešení a integraci vyhledávače, která bude odpovídat vašim specifickým potřebám.
        </p>

        <h3>Obtěžují mne reklamy, mohu je vypnout?</h3>
        <p>
          Webové vyhledávače jsou služby, dostupné pro koncové uživatele zdarma. Poskytují uživatelům rychlý a snadný přístup k informacím.
          Reklamy jsou způsobem, jak vyhledávače zajišťují financování a pokrývají náklady spojené s provozem a vývojem těchto služeb.
          Reklamní příjmy jsou pro vyhledávače, včetně Gozo, zásadní pro udržení jejich bezplatného charakteru a vypnout je není možné.
        </p>

        <br />
        <h2>O soukromí a výhodách Gozo vyhledávače</h2>

        <h3>Co je Gozo a jak funguje?</h3>
        <p>
          Gozo je vyhledávač, jehož základním principem je prioritizace soukromí uživatelů. Naše uživatelsky přívětivé produkty umožňují
          jednotlivcům i firmám po celém světě chránit svá osobní data online. Poskytujeme alternativu k dominantním vyhledávačům.
        </p>
        <p>
          Kromě vyhledávání nabízíme také výkonná API řešení, která umožňují snadnou integraci pokročilých vyhledávacích funkcí do vaší
          aplikace, webové stránky nebo interních systémů.
        </p>

        <h3>Proč bych měl používat Gozo?</h3>
        <ol className="line">
          <li>Rychlost a pohodlí - Gozo poskytuje rychlý a intuitivní způsob, jak snadno najít správné informace na internetu</li>
          <li>Vysoká relevance výsledků - sofistikovaný algoritmus zaručuje vysokou relevanci výsledků</li>
          <li>Široká škála informací - Gozo vyhledává v rozsáhlých celosvětových databázích</li>
          <li>Anonymita a bezpečí - využíváme jen nejnutnější data a nesdílíme je s třetími stranami</li>
          <li>Dostupnost - Gozo je dostupný komukoliv, kdo chce dostávat relevantní výsledky</li>
        </ol>

        <h3>Jaké údaje o mě Gozo uchovává?</h3>
        <p>
          Neukládáme historii vašich vyhledávání ani neprodáváme žádné informace inzerentům, kteří vás sledují online. Nesbíráme ani
          nesdílíme vaše osobní údaje.
        </p>
      </div>
      <div className="image">
        <img src={img.imgProducts} alt="" />
      </div>
    </Area>
  )
}

export default Help
