import { MutableRefObject, useEffect } from 'react'

const useOutsideMouseClick = (ref: MutableRefObject<HTMLElement | null>, callback: () => void): void => {
  const handler = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handler)

    return () => document.removeEventListener('mousedown', handler)
  }, [ref])
}

export default useOutsideMouseClick
