import styled, { css } from 'styled-components'

export const Button = styled.div<{
  $isHome?: boolean
  $compensationWidth: number
}>`
  display: flex;
  cursor: pointer;
  float: right;
  font-size: 20px;

  ${({ $isHome, $compensationWidth }) =>
    $isHome &&
    css`
      position: absolute;
      z-index: 20;
      top: 20px;
      right: ${$compensationWidth ? 'calc(20px + ' + $compensationWidth + 'px)' : $compensationWidth + '20px'};
    `};
`
