import styled from 'styled-components'

export const List = styled.div`
  position: relative;
  margin: 0 10px;

  @media screen and (min-width: ${({ theme }) => theme.layout.sizes.lg}px) {
    margin: 0 20px;
  }
`

export const Item = styled.div`
  display: grid;
  padding-bottom: 25px;
  gap: 5px 10px;

  &:not(.no-thumb) {
    grid-template-areas:
      'name name'
      'image properties'
      'image caption';
    grid-template-columns: 120px 1fr;
    grid-template-rows: auto auto 1fr;

    @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
      gap: 7px 15px;
      grid-template-areas:
        'image name'
        'image properties'
        'image caption';
      grid-template-columns: 160px 1fr;
    }
  }
  &.no-thumb {
    grid-template-areas:
      'name'
      'caption';
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }

  .image {
    grid-area: image;
  }

  .caption {
    overflow: hidden;
    max-height: 70px;
    grid-area: caption;

    p {
      display: inline;
      display: -webkit-box;
      overflow: hidden;
      margin: 0;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      word-break: break-word;
    }

    &.row-2 {
      p {
        -webkit-line-clamp: 2;

        @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
          -webkit-line-clamp: 3;
        }
      }
    }
    &.row-3 {
      margin-bottom: 10px;

      p {
        -webkit-line-clamp: 3;
      }
    }

    a {
      &:focus {
        outline: none;
        text-decoration: underline;
      }
    }
  }

  .name {
    grid-area: name;

    h2 {
      margin: 0;
      font-size: 16px;
    }

    @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
      font-size: 18px;
    }
  }

  .properties {
    grid-area: properties;

    ul {
      @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
        gap: 2px 15px;
      }
    }

    li {
      font-size: 12px;

      &.break {
        @media screen and (max-width: ${({ theme }) => theme.layout.sizes.md}px) {
          flex-basis: 100%;
        }
        @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
          position: absolute;
        }
      }
    }
  }
`
