import styled from 'styled-components'

export const NotFound = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo {
    margin-bottom: 20px;

    svg {
      width: 280px;
      max-width: 280px;
      height: 170px;
      color: ${({ theme }) => theme.color.text};
    }
  }
`
