import React from 'react'

const FileSvg: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="1em" height="1em">
    <path
      fill="currentColor"
      d="M374.6 150.6l-141.3-141.3c-6-6.002-14.14-9.373-22.62-9.373L64-.0001c-35.35 0-64 28.65-64 64l.0065 384c0 35.35 28.65 64 64 64H320c35.35 0 64-28.65 64-64V173.3C384 164.8 380.6 156.6 374.6 150.6zM224 22.63l137.4 137.4H248c-13.22 0-24-10.77-24-24V22.63zM368 447.1c0 26.47-21.53 48-48 48H64c-26.47 0-48-21.53-48-48v-384c0-26.47 21.53-48 48-48h144v119.1c0 22.06 17.94 40 40 40h120V447.1zM96 263.1c0 4.406 3.594 8 8 8h176c4.406 0 8-3.594 8-8s-3.594-8-8-8h-176C99.59 255.1 96 259.6 96 263.1zM280 319.1h-176c-4.406 0-8 3.594-8 8s3.594 8 8 8h176c4.406 0 8-3.594 8-8S284.4 319.1 280 319.1zM280 383.1h-176c-4.406 0-8 3.594-8 8s3.594 8 8 8h176c4.406 0 8-3.594 8-8S284.4 383.1 280 383.1z"
    />
  </svg>
)

export default FileSvg
