import React from 'react'

const ThumbsDownSvg: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em">
    <path
      fill="currentColor"
      d="M128 280v-208C128 49.94 110.1 32 88 32h-48C17.94 32 0 49.94 0 72v208C0 302.1 17.94 320 40 320h48C110.1 320 128 302.1 128 280zM96 280C96 284.4 92.41 288 88 288h-48C35.59 288 32 284.4 32 280v-208C32 67.59 35.59 64 40 64h48C92.41 64 96 67.59 96 72V280zM477.9 235.7c2.469-6.672 3.75-13.77 3.75-20.98c0-22.97-12.75-43.42-32.06-53.94c1.141-4.672 1.734-9.469 1.734-14.27c0-22.06-11.78-41.94-30.33-52.8C421 59.84 393.4 32 359.5 32H303.9c-77.41 0-143.9 63.11-143.9 80.07c0 8.767 7.133 15.96 16 15.96C195.8 128 222.2 64 303.9 64h55.63c16.27 0 29.5 13.22 29.5 29.47c0 4.826-1.185 6.491-1.185 10.01c0 20.94 31.51 12.09 31.51 43.05c0 12.84-6.765 14.68-6.765 23.56c0 9.347 7.255 14.66 13.06 15.75c13.92 2.594 24.03 14.75 24.03 28.89c0 17.77-12.06 18.45-12.06 30.63c0 8.314 6.443 15.38 14.91 15.96C467.9 262.4 480 275.2 480 290.5C480 306.8 466.8 320 450.5 320h-128.3c-8.273 0-15.1 6.658-15.1 16.01c0 2.699 .68 5.398 2.046 7.83c17.73 31.53 26.62 65.39 26.62 75.48C334.9 431.2 325.6 448 304.9 448c-16.7 0-19.66-4.953-28.78-32.78c-21.79-66.41-36.14-69.47-88.81-122.5C184.2 289.6 180.1 288 176 288C167.4 288 160 294.9 160 303.1c0 23.25 57.94 36.53 85.73 121.2C254.2 450.9 263.6 480 304.9 480c38.77 0 62.01-30.84 62.01-60.67c0-13.84-6.844-40.27-18.83-67.33h102.5C484.4 352 512 324.4 512 290.5C512 266.8 498.2 245.8 477.9 235.7z"
    />
  </svg>
)

export default ThumbsDownSvg
