import styled from 'styled-components'

export const Item = styled.div`
  box-sizing: border-box;
  animation: fade 2s linear infinite;
  background-color: ${({ theme }) => theme.color.bg.shade5};
  border-radius: 5px;
  opacity: 1;

  @keyframes fade {
    0%,
    100% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
  }
`
