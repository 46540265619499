import { CSSProperties } from 'react'
import { getTheme, Theme } from '../../../modules/config/theme'
import { Service } from '../../../modules/config/model'

const CAPTCHA_RATE_LIMITED_AT = 'captcha-rate-limited-at'
const CAPTCHA_VERIFIED_AT = 'captcha-verified-at'
const CAPTCHA_TIME_FRAME = 1000 * 60 * 10 // 10 mins

export const setRateLimitedAt = () => localStorage.setItem(CAPTCHA_RATE_LIMITED_AT, new Date().getTime().toString())

export const getRateLimitedAt = () => localStorage.getItem(CAPTCHA_RATE_LIMITED_AT)

export const removeRateLimitedAt = () => localStorage.removeItem(CAPTCHA_RATE_LIMITED_AT)

export const setVerifiedAt = () => localStorage.setItem(CAPTCHA_VERIFIED_AT, new Date().getTime().toString())

export const getVerifiedAt = (): string => localStorage.getItem(CAPTCHA_VERIFIED_AT) || ''

export const removeVerifiedAt = () => localStorage.removeItem(CAPTCHA_VERIFIED_AT)

// check if captcha was verified in last ${CAPTCHA_TIME_FRAME} mins
// if so revalidate the data (run callback)
export const shouldRevalidateData = (): boolean => {
  const verifiedAt = getVerifiedAt()
  const rateLimitedAt = getRateLimitedAt()
  const isWithinTimeFrame = verifiedAt && Date.now() - parseInt(verifiedAt) < CAPTCHA_TIME_FRAME
  if (!isWithinTimeFrame || rateLimitedAt) {
    removeVerifiedAt()
    return false
  }
  return true
}

export const getRecaptchaStyle = (): CSSProperties => {
  // https://github.com/dozoisch/react-google-recaptcha/issues/248
  return {
    width: 300,
    height: 76,
    borderRadius: 3,
    overflow: 'hidden',
  }
}
