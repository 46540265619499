import React, { CSSProperties, useContext } from 'react'
import { useInView } from 'react-intersection-observer'
import type { VirtualItem } from '@tanstack/virtual-core'
import { File } from '../../../modules/file/model'
import Skeleton from '../Skeleton'
import { isFile, shouldDisplayThumbnail } from '../View/helpers'
import { Name } from '../View/Name'
import Thumb from '../View/Thumb'
import { Properties } from '../View/Properties'
import Description from '../View/Description'
import { ConfigContext, ConfigContextValue } from '../../../modules/config/context'
import useWindowSize from '../../../hooks/useWindowSize'
import theme from '../../../constants/GlobalTheme'
import { getFileIcons } from '../View/Properties/functions'
import { Item } from './styled'

interface Props {
  items: File[]
  virtualRow: VirtualItem<unknown>
  index: number
  listWrapperRef?: HTMLDivElement | null
  visibleRows: Record<number, boolean>
  updateVisibleRows: (id: number, value: boolean) => void
}

const MemoizedItem = React.memo(Item)
const MemoizedSkeleton = React.memo(Skeleton)

const renderItem = (
  config: ConfigContextValue,
  itemWidth: number,
  virtualRow: VirtualItem<unknown>,
  item: File | undefined
): JSX.Element => {
  switch (true) {
    case isFile(item):
      const file = item as File
      const fileType = file.hash?.contentType
      return (
        <MemoizedItem
          ref={virtualRow.measureElement}
          className={!shouldDisplayThumbnail(file, config.service) ? 'no-thumb' : ''}
          data-testid={`fileList-item-${fileType}`}
        >
          <Name file={file} isGridView={false} />
          <Thumb file={file} itemWidth={itemWidth} itemRadius={1} />
          <Properties file={file} />
          <Description description={file.description} hasFileIcons={getFileIcons(file).length > 0} />
        </MemoizedItem>
      )
    default:
      return (
        <MemoizedSkeleton
          ref={virtualRow.measureElement}
          style={{
            height: `${virtualRow.size - 10}px`,
            display: 'flex',
            maxWidth: '990px',
          }}
        />
      )
  }
}

const ViewList: React.FC<Props> = ({ items, virtualRow, index, updateVisibleRows }) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: false,
  })
  updateVisibleRows(index, inView)
  const config = useContext(ConfigContext)
  const windowSize = useWindowSize()
  const item = items[index]

  const itemWidth = windowSize.width >= theme.layout.sizes.md ? 160 : 120
  const style: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    transform: `translateY(${virtualRow.start}px)`,
    zIndex: 1,
    background: theme.color.bg.main,
  }

  return (
    <div style={style} data-testid="fileList-item" ref={ref}>
      {renderItem(config, itemWidth, virtualRow, item)}
    </div>
  )
}

export default ViewList
