{
  "components": {
    "ErrorBoundaryScreen": {
      "title": "Bohužiaľ, došlo k neočakávanej chybe..."
    },
    "Languages": {
      "cz": "Česky",
      "sk": "Slovensky",
      "en": "English",
      "pl": "Polski"
    },
    "Theme": {
      "light": "Zapnúť tmavý režim",
      "dark": "Zapnúť svetlý režim"
    },
    "Home": {
      "gozoPrivacySearchService": "Gozo is a privacy search service that offers a variety of products to help users safeguard their personal data online. Their products include a custom search engine, a search API, and a web search engine. Gozo does not store user search history or sell user information to advertisers.",
      "gozoCustomSearchEngine": "Gozo's custom search engine allows businesses to create their own private search engines that are tailored to their specific needs. The search API can be used to integrate search capabilities into apps and websites. The web search engine is a privacy-focused alternative to traditional search engines like Google and Bing.",
      "withGozoYouCanTake": "With Gozo, you can take control of your online privacy and search with confidence.<br /><1>Try Gozo today</1> and experience the difference!"
    },
    "Menu": {
      "settings": "Nastavenie",
      "language": "Jazyk",
      "details": "Detaily",
      "about": "O nás",
      "products": "Produkty",
      "pricing": "Cenová politika",
      "privacy": "Zásady ochrany súkromia",
      "terms": "Podmienky služby",
      "contact": "Kontakt",
      "help": "Nápoveda"
    },
    "File": {
      "FileListEmpty": {
        "empty": "Vyhľadávač pre túto zákaznícku službu bol vypnutý...",
        "emptyWithFilter": {
          "text": "Chcete rozšíriť hľadanie na <1><0/></1>?",
          "link": "všetky súbory"
        }
      },
      "FileListEnd": {
        "empty": "Na vyhľadávaný výraz <1>\"<0/>\"</1> neboli nájdené žiadne ďalšie výsledky."
      }
    },
    "Modal": {
      "RateLimiting": {
        "title": "Príliš veľa požiadavkov",
        "boxes": {
          "browser": {
            "title": "Prehliadač",
            "status": "Funguje"
          },
          "cloudflare": {
            "title": "CloudFlare",
            "status": "Funguje",
            "imNotRobot": "Nie som robot"
          },
          "gozo": {
            "title": "GOZO Finder",
            "status": "Chyba"
          }
        },
        "captcha": {
          "error": "Overenie užívateľa sa nepodarilo, skúste to prosím znova.",
          "success": "Overenie užívateľa prebehlo úspešne, počkajte prosím.",
          "title": "Overenie používateľa",
          "waiting": "Príliš veľa požiadaviek z IP adresy. Nie ste náhodou bot?"
        },
        "whatHappened": {
          "title": "Čo sa stalo?",
          "body": "Bezpečnostný systém vyhodnotil abnormálne množstvo požiadavkov z vašej lokality a ak chcete pokračovať v používaní služby, musíte preukázať, že nie ste robot."
        }
      }
    },
    "Search": {
      "FilterBox": {
        "all": "Všetko",
        "type": {
          "all": "Všetko",
          "video": "Video",
          "image": "Obrázky",
          "archive": "Archívy",
          "audio": "Audio",
          "document": "Dokumenty"
        },
        "typeBox": {
          "other": "Ďalšia",
          "filter": "Filter"
        },
        "sort": {
          "label": "Radenie",
          "values": {
            "relevance": "Relevantnosť",
            "latest": "Najnovšie",
            "rating": "Hodnotenie",
            "largest": "Od najväčšieho",
            "smallest": "Od najmenšieho"
          }
        },
        "video": {
          "duration": {
            "label": "Dĺžka videa",
            "values": {
              "longest": "Dlhé (viac ako 60 minút)",
              "long": "Dlhšie (20-60 minút)",
              "middle": "Stredné (5-20 minút)",
              "short": "Krátke (menej ako 5 minút)"
            }
          },
          "quality": {
            "label": "Kvalita videa",
            "values": {
              "fullhd": "1080p alebo viac",
              "hd": "720p alebo viac",
              "lq": "Menšie ako 720p"
            }
          },
          "streaming": {
            "label": "Typ videa",
            "values": {
              "available": "Streamovanie",
              "unavailable": "Sťahovanie"
            }
          },
          "size": {
            "label": "Veľkosť",
            "values": {
              "small": "Malé",
              "medium": "Stredné",
              "large": "Veľké"
            }
          }
        },
        "image": {
          "quality": {
            "label": "Kvalita",
            "values": {
              "hq": "Vysoká",
              "mq": "Stredná",
              "lq": "Nízka"
            }
          },
          "size": {
            "label": "Veľkosť",
            "values": {
              "small": "Malé",
              "medium": "Stredné",
              "large": "Veľké"
            }
          }
        },
        "audio": {
          "quality": {
            "label": "Kvalita",
            "values": {
              "hq": "Vysoká",
              "mq": "Stredná",
              "lq": "Nízka"
            }
          },
          "duration": {
            "label": "Dĺžka",
            "values": {
              "short": "Krátke (menej ako 5 minút)",
              "middle": "Stredné (5-20 minút)",
              "long": "Dlhé (viac ako 60 minút)"
            }
          },
          "size": {
            "label": "Veľkosť",
            "values": {
              "small": "Malé",
              "medium": "Stredné",
              "large": "Veľké"
            }
          }
        },
        "archive": {
          "size": {
            "label": "Veľkosť",
            "values": {
              "small": "Malé",
              "medium": "Stredné",
              "large": "Veľké"
            }
          }
        },
        "document": {
          "size": {
            "label": "Veľkosť",
            "values": {
              "small": "Malé",
              "medium": "Stredné",
              "large": "Veľké"
            }
          }
        }
      },
      "QueryBox": {
        "placeholder": "Hľadať video, audio, obrázky a ďalšie súbory",
        "placeholderUlozto": "Hľadať súbory na Ulož.to Disk"
      }
    }
  },
  "routes": {
    "Index": {
      "unsupportedBrowser": "Váš prehliadač je príliš starý a nemusí správne fungovať, odporúčame vám aktualizovať.",
      "FileList": {
        "poweredBy": "Powered by GozoFinder"
      }
    },
    "UrlNotFound": {
      "title": "Stránka nenájdená"
    },
    "FileNotFound": {
      "title": "Súbor nenájdený"
    }
  }
}
