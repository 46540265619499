import React from 'react'

interface Props {
  size?: string
  radius?: number
}

const FLAC: React.FC<Props> = ({ size, radius }) => (
  <svg viewBox="0 0 600 600" preserveAspectRatio="xMinYMid" width={size} height={size}>
    <rect rx={radius} ry={radius} width="100%" height="100%" fill="#6fccd7" />
    <g fill="#fff">
      <path d="M426.79 157.535c-7.35 0-14.42 1.12-20.93 2.73v86.59c6.51 1.61 13.58 2.73 20.93 2.73 17.99 0 34.3-5.46 45.71-14.42v-63.21c-11.41-8.68-27.79-14.42-45.71-14.42zM297.99 203.525c0 38.92 42.21 70.49 93.94 70.49v-140.98c-51.73 0-93.94 31.57-93.94 70.49zM349.72 352.695c0 13.09 8.47 20.16 18.48 20.16 10.36 0 18.48-7.07 18.48-20.16v-64.82c-13.09-.56-25.83-2.73-37.31-6.79v71.61h.35zM201.11 133.035c-39.48 0-71.61 32.13-71.61 71.61s32.13 71.61 71.61 71.61 71.61-32.13 71.61-71.61-32.13-71.61-71.61-71.61zm0 106.47c-19.32 0-34.86-15.82-34.86-34.86s15.82-34.86 34.86-34.86 34.86 15.82 34.86 34.86-15.54 34.86-34.86 34.86zM177.38 286.405v62.51c0 13.09 10.64 23.66 23.66 23.66 13.09 0 23.66-10.64 23.66-23.66v-62.51c-15.4 4.48-31.85 4.48-47.32 0zM192.36 385.385v81.62c0 4.9 3.78 8.68 8.68 8.68s8.68-3.78 8.68-8.68v-81.62c-5.6 1.47-11.62 1.47-17.36 0zM359.52 385.665v81.62c0 4.9 3.78 8.68 8.68 8.68s8.68-3.78 8.68-8.68v-81.62c-5.6 1.4-11.62 1.4-17.36 0z" />
    </g>
  </svg>
)

export default FLAC
