var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

import { useEffect, useState } from 'react';

import { getHandlerArgs, isSupported, visibility } from './utils';

var isSupportedLocal = isSupported && visibility;

var usePageVisibility = function usePageVisibility() {
    var _getHandlerArgs = getHandlerArgs(),
        _getHandlerArgs2 = _slicedToArray(_getHandlerArgs, 1),
        initiallyVisible = _getHandlerArgs2[0];

    var _useState = useState(initiallyVisible),
        _useState2 = _slicedToArray(_useState, 2),
        isVisible = _useState2[0],
        setIsVisible = _useState2[1];

    useEffect(function () {
        if (isSupportedLocal) {
            var handler = function handler() {
                var _getHandlerArgs3 = getHandlerArgs(),
                    _getHandlerArgs4 = _slicedToArray(_getHandlerArgs3, 1),
                    currentlyVisible = _getHandlerArgs4[0];

                setIsVisible(currentlyVisible);
            };

            document.addEventListener(visibility.event, handler);

            return function () {
                document.removeEventListener(visibility.event, handler);
            };
        }
    }, []);

    return isVisible;
};

export default usePageVisibility;