import { Route } from 'react-router-dom'

import AboutCZ from '../About/cz'
import AboutEN from '../About'
import AboutSK from '../About/sk'
import ContactCZ from '../Contact/cz'
import ContactEN from '../Contact'
import ContactSK from '../Contact/sk'
import HelpCZ from '../Help/cz'
import HelpEN from '../Help'
import HelpSK from '../Help/sk'
import InquiryCZ from '../Inquiry/cz'
import InquiryEN from '../Inquiry'
import InquirySK from '../Inquiry/sk'
import PricingCZ from '../Pricing/cz'
import PricingEN from '../Pricing'
import PricingSK from '../Pricing/sk'
import PrivacyCZ from '../Privacy/cz'
import PrivacyEN from '../Privacy'
import PrivacySK from '../Privacy/sk'
import ProductsCZ from '../Products/cz'
import ProductsEN from '../Products'
import ProductsSK from '../Products/sk'
import TermsCZ from '../Terms/cz'
import TermsEN from '../Terms'
import TermsSK from '../Terms/sk'

export const StaticRoutes = (
  <>
    <Route path="/about" element={<AboutEN />} />
    <Route path="/en/about" element={<AboutEN />} />
    <Route path="/pl/about" element={<AboutEN />} />
    <Route path="/cz/about" element={<AboutCZ />} />
    <Route path="/sk/about" element={<AboutSK />} />

    <Route path="/contact" element={<ContactEN />} />
    <Route path="/en/contact" element={<ContactEN />} />
    <Route path="/pl/contact" element={<ContactEN />} />
    <Route path="/cz/contact" element={<ContactCZ />} />
    <Route path="/sk/contact" element={<ContactSK />} />

    <Route path="/help" element={<HelpEN />} />
    <Route path="/en/help" element={<HelpEN />} />
    <Route path="/pl/help" element={<HelpEN />} />
    <Route path="/cz/help" element={<HelpCZ />} />
    <Route path="/sk/help" element={<HelpSK />} />

    <Route path="/inquiry/:product" element={<InquiryEN />} />
    <Route path="/en/inquiry/:product" element={<InquiryEN />} />
    <Route path="/pl/inquiry/:product" element={<InquiryEN />} />
    <Route path="/cz/inquiry/:product" element={<InquiryCZ />} />
    <Route path="/sk/inquiry/:product" element={<InquirySK />} />

    <Route path="/pricing" element={<PricingEN />} />
    <Route path="/en/pricing" element={<PricingEN />} />
    <Route path="/pl/pricing" element={<PricingEN />} />
    <Route path="/cz/pricing" element={<PricingCZ />} />
    <Route path="/sk/pricing" element={<PricingSK />} />

    <Route path="/privacy" element={<PrivacyEN />} />
    <Route path="/en/privacy" element={<PrivacyEN />} />
    <Route path="/pl/privacy" element={<PrivacyEN />} />
    <Route path="/cz/privacy" element={<PrivacyCZ />} />
    <Route path="/sk/privacy" element={<PrivacySK />} />

    <Route path="/products" element={<ProductsEN />} />
    <Route path="/en/products" element={<ProductsEN />} />
    <Route path="/pl/products" element={<ProductsEN />} />
    <Route path="/cz/products" element={<ProductsCZ />} />
    <Route path="/sk/products" element={<ProductsSK />} />

    <Route path="/terms" element={<TermsEN />} />
    <Route path="/en/terms" element={<TermsEN />} />
    <Route path="/pl/terms" element={<TermsEN />} />
    <Route path="/cz/terms" element={<TermsCZ />} />
    <Route path="/sk/terms" element={<TermsSK />} />
  </>
)
