import styled from 'styled-components'

export const Area = styled.div`
  display: block;
  width: 100%;
  max-width: 1300px;
  box-sizing: border-box;
  padding: 20px;
  margin: 0 auto;
  font-size: 16px;

  .image-preview {
    display: block;
    width: 100%;
    margin: 10px 0 25px;

    @media screen and (min-width: ${({ theme }) => theme.layout.sizes.lg}px) {
      max-width: 60%;
    }

    img {
      width: 100%;
    }
  }

  &.has-image,
  &.has-form {
    display: grid;
    gap: 20px;

    @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
      grid-template-columns: 1fr 400px;
    }

    .image {
      padding-top: 55px;
      text-align: center;

      img {
        max-width: 100vh;
        max-height: 50vh;
      }
    }
  }

  &.has-form {
    @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  h1 {
    margin: 0 0 20px;
    color: ${({ theme }) => theme.color.theme};
    font-size: 28px;
    font-weight: 400;
  }
  h2 {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 500;
  }
  h3 {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 500;
  }
  .decor {
    display: block;
    margin: 40px 0;
    color: ${({ theme }) => theme.color.theme};
    font-size: 18px;
    font-style: italic;
    font-weight: 500;
  }
  p {
    margin: 0 0 15px;

    &.center {
      text-align: center;
    }
  }
  .button {
    display: inline-block;
    min-width: 120px;
    padding: 10px 20px;
    border: none;
    background-color: ${({ theme }) => theme.color.theme};
    border-radius: ${({ theme }) => theme.size.borderRadius}px;
    color: ${({ theme }) => theme.color.white};
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.color.linkHover};
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    &:not(.line) {
      text-align: center;

      li {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }
  ul.dot {
    padding-bottom: 25px;
    padding-left: 20px;
    list-style-type: disc;
    text-align: left;

    li {
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
  ul.line {
    padding: 15px 0;
    margin-top: 15px;

    li {
      position: relative;
      padding-left: 15px;

      svg {
        position: absolute;
        top: 3px;
        left: 0;
        font-size: 10px;

        path {
          fill: ${({ theme }) => theme.color.green};
        }
      }

      &.red {
        svg {
          path {
            fill: ${({ theme }) => theme.color.red};
          }
        }
      }
    }
  }
  li {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  ol {
    padding: 0;
    margin-top: 5px;
    margin-bottom: 25px;
    counter-reset: item;

    &:first-child {
      margin-bottom: 15px;
    }

    &.nocounter {
      li:before {
        display: none;
      }
    }

    li {
      position: relative;
      display: block;
      padding-left: 35px;
      margin-bottom: 5px;

      &:before {
        position: absolute;
        left: 0;
        display: inline-block;
        width: auto;
        height: auto;
        margin-right: 15px;
        background-color: transparent;
        color: var(--color-text);
        content: counters(item, '.') '';
        counter-increment: item;
      }
    }

    > li {
      padding-left: 35px;
      margin-top: 25px;
      font-weight: 600;

      &:before {
        content: counters(item, '.') '.';
      }

      p {
        font-weight: normal;
      }

      ol {
        margin-left: -35px;

        li {
          padding-left: 35px;
          margin-top: 0;
          font-weight: normal;

          ol {
            padding-left: 35px;

            li {
              padding-left: 50px;
            }
          }
        }
      }
    }

    &.line {
      li {
        margin: 0;
        font-weight: normal;
        line-height: 20px;
      }
    }
  }
`

export const Boxs = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 30px 0;
  gap: 20px 40px;

  @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
    flex-direction: row;
    gap: 40px 20px;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.sizes.lg}px) {
    gap: 40px 60px;
  }

  > div {
    flex-basis: 300px;
    padding: 5px;
    background-color: ${({ theme }) => theme.color.bg.shade5};
    border-radius: ${({ theme }) => theme.size.borderRadius}px;
    box-shadow: 0 0 20px ${({ theme }) => theme.color.shadow};

    @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
      display: grid;
      grid-template-rows: 80px 185px auto 65px;
    }
  }

  h3 {
    padding: 10px;
    margin: 20px 0;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
  }
  span {
    font-size: 14px;
  }
  ul {
    margin: 0 10px;

    &.line {
      border-top: 1px solid ${({ theme }) => theme.color.bg.shade20};
      font-size: 14px;
    }
    &:not(.line):not(:last-child) {
      li {
        margin-bottom: 30px;
      }
    }
  }
  .box {
    display: grid;
    gap: 5px;
  }
  .box1 {
    h3 {
      color: ${({ theme }) => theme.color.plan1};
    }
  }
  .box2 {
    h3 {
      color: ${({ theme }) => theme.color.plan2};
    }
  }
  .box3 {
    h3 {
      color: ${({ theme }) => theme.color.plan3};
    }
  }
  .buttons {
    padding: 15px 0;
    text-align: center;
  }
`
