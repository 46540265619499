import styled from 'styled-components'

export const Modal = styled.dialog`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: block;
  overflow: auto;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  background-color: ${({ theme }) => theme.color.bg.main};

  &::backdrop {
    display: none;
  }
`
export const Centered = styled.div`
  display: table;
  width: 100%;
  height: 100%;
`
export const CenteredCell = styled.div`
  display: table-cell;
  vertical-align: middle;

  > div {
    position: relative;
    width: 100%;
    max-width: 1100px;
    min-height: 150px;
    box-sizing: border-box;
    padding: 20px 0;
    margin: 0 auto;
    background: ${({ theme }) => theme.color.bg.main};
    color: ${({ theme }) => theme.color.text};
    text-align: center;

    h1 {
      margin: 0;
      font-weight: normal;
    }
    h2 {
      margin: 0;
      font-weight: normal;
    }
    .captcha {
      margin-top: 25px;

      > div {
        display: flex;
        justify-content: center;
      }
    }
    .boxes {
      display: flex;
      justify-content: center;
      margin: 25px 0;
      background: ${({ theme }) => theme.color.bg.shade5};
      gap: 5px;

      > div {
        position: relative;
        padding: 25px 5px;
        text-align: center;

        @media screen and (min-width: ${({ theme }) => theme.layout.sizes.sm}px) {
          padding: 25px 15px;
        }

        span {
          display: block;
        }
        &.ok,
        &.error {
          svg {
            &:first-child {
              margin-bottom: 5px;
              font-size: 40px;

              @media screen and (min-width: ${({ theme }) => theme.layout.sizes.sm}px) {
                font-size: 60px;
              }

              path {
                fill: ${({ theme }) => theme.color.theme};
              }
            }

            &:last-child {
              display: block;
              width: 30px;
              margin: 0 auto;
              font-size: 15px;

              @media screen and (min-width: ${({ theme }) => theme.layout.sizes.sm}px) {
                font-size: 25px;
              }
            }
          }
        }

        &.ok {
          svg:last-child {
            path {
              fill: ${({ theme }) => theme.color.green};
            }
          }
          span {
            color: ${({ theme }) => theme.color.green};
          }
        }
        &.error {
          svg:last-child {
            path {
              fill: ${({ theme }) => theme.color.red};
            }
          }
          span {
            color: ${({ theme }) => theme.color.red};
          }
        }

        &:not(.ok, .error) {
          padding-top: 40px;
          font-size: 15px;

          @media screen and (min-width: ${({ theme }) => theme.layout.sizes.sm}px) {
            font-size: 30px;
          }

          svg {
            path {
              fill: ${({ theme }) => theme.color.bg.shade50};
            }
          }
        }
      }
    }

    .icons {
      margin-bottom: 10px;
    }
    .what {
      display: grid;
      gap: 10px;
      grid-template-columns: 1fr;

      @media screen and (min-width: ${({ theme }) => theme.layout.sizes.sm}px) {
        grid-template-columns: 1fr 1fr;
      }

      > div {
        padding: 10px;
      }
    }
  }
`

export const Close = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${({ theme }) => theme.color.bg.shade50};
  cursor: pointer;
  font-size: 20px;
`
