import React from 'react'
import { NavLink } from 'react-router-dom'
import { Area } from '../styled'
import useDocumentTitle from '../../../hooks/useDocumentTitle'
import { useCountry } from '../../../hooks/useCountry'

const Content: React.FC = () => {
  useDocumentTitle('Terms of Service')
  const country = useCountry()

  return (
    <Area>
      <h1>Terms of Service</h1>

      <p>
        Gozo, a web search engine designed to help users find relevant and useful information on the internet. These Terms of Service
        ("Terms") govern your access to and use of the Gozo website, search engine, and any related services (collectively, the "Services").
        By accessing or using the Services, you agree to be bound by these Terms.
      </p>

      <ol>
        <li>
          Acceptance of Terms
          <ol className="nocounter">
            <li>
              By using the Services, you are agreeing to these Terms. If you do not agree to these Terms, you may not access or use the
              Services. Gozo reserves the right to update and modify the Terms at any time without notice. Continued use of the Services
              after any such changes constitutes your acceptance of the new Terms.
            </li>
          </ol>
        </li>

        <li>
          Eligibility
          <ol className="nocounter">
            <li>
              By using the Services, you represent and warrant that you are at least 13 years of age. If you are under the age of 13, you
              must have permission from a parent or legal guardian to access and use the Services.
            </li>
          </ol>
        </li>

        <li>
          User Conduct
          <ol>
            <li>
              As a condition of your use of the Services, you agree not to engage in any conduct that is harmful, illegal, or otherwise
              inappropriate, including but not limited to:
              <ol>
                <li>Violating any applicable laws or regulations.</li>
                <li>
                  Using the Services to transmit or distribute any content that is abusive, harassing, defamatory, libelous, invasive of
                  another's privacy, or otherwise objectionable.
                </li>
                <li>
                  Infringing on the intellectual property rights of others, including copyrights, trademarks, trade secrets, or patents.
                </li>
                <li>Using the Services to send unsolicited messages, spam, or other forms of commercial solicitation.</li>
                <li>
                  Interfering with the normal operation of the Services or other users' enjoyment of the Services, including by attempting
                  to gain unauthorized access to the Services or using automated tools or scripts to search, index, or manipulate the
                  Services.
                </li>
                <li>Intentionally introducing viruses, worms, or other malicious software to the Services.</li>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          Intellectual Property
          <ol className="nocounter">
            <li>
              All content provided through the Services, including but not limited to text, graphics, logos, images, and software, is the
              property of Gozo or its licensors and is protected by international copyright, trademark, and other intellectual property
              laws. You agree not to reproduce, distribute, modify, or otherwise use any such content without the prior written consent of
              Gozo or the applicable licensor.
            </li>
          </ol>
        </li>

        <li>
          Links to Third-Party Websites
          <ol className="nocounter">
            <li>
              The Services may contain links to third-party websites or resources. You acknowledge and agree that Gozo is not responsible or
              liable for the content, products, or services available from such third-party websites or resources. You further acknowledge
              that your access to and use of such third-party websites or resources is at your own risk and subject to the terms and
              conditions of those websites or resources.
            </li>
          </ol>
        </li>

        <li>
          API (Application Programming Interface)
          <ol>
            <li>
              API Access
              <ol>
                <li>
                  Gozo may provide access to its API for users to develop applications, products, or services that interact with the
                  Services. By using the Gozo API, you agree to be bound by these Terms, as well as any additional terms or guidelines
                  applicable to the API.
                </li>
              </ol>
            </li>

            <li>
              API Usage
              <ol>
                <li>
                  You may use the Gozo API solely to develop and distribute applications, products, or services that enhance or extend the
                  functionality of the Services, subject to the following conditions:
                </li>
                <ol>
                  <li>You must obtain a valid API key from Gozo and use it in accordance with Gozo's guidelines.</li>
                  <li>
                    You must not use the API to develop applications, products, or services that compete with, or replicate the
                    functionality of, the Services.
                  </li>
                  <li>
                    You must not use the API to access, store, or distribute any content or data in violation of applicable laws or
                    regulations or these Terms.
                  </li>
                  <li>
                    You must not use the API in a manner that adversely affects the performance or functionality of the Services or that
                    consumes an unreasonable amount of resources.
                  </li>
                </ol>
              </ol>
            </li>

            <li>
              API Modifications
              <ol>
                <li>
                  Gozo reserves the right to modify, update, or discontinue the API, or any part thereof, at any time and without notice.
                  You acknowledge and agree that Gozo is not liable for any such modifications, updates, or discontinuations, and that your
                  continued use of the API is at your own risk.
                </li>
              </ol>
            </li>

            <li>
              API Limitations and Restrictions
              <ol>
                <li>
                  Gozo may impose limitations and restrictions on the use of the API, including but not limited to rate limits, usage
                  quotas, and feature restrictions. You agree to comply with any such limitations and restrictions and not to attempt to
                  circumvent or bypass them.
                </li>
              </ol>
            </li>

            <li>
              API License Grant
              <ol>
                <li>
                  Subject to your compliance with these Terms, Gozo grants you a limited, non-exclusive, non-transferable,
                  non-sublicensable, and revocable license to access and use the API solely for the purpose of developing and distributing
                  applications, products, or services that interact with the Services. This license may be terminated by Gozo at any time
                  and for any reason, without notice.
                </li>
              </ol>
            </li>

            <li>
              API License Restrictions
              <ol>
                <li>Except as expressly permitted under these Terms, you may not:</li>
                <ol>
                  <li>Sell, rent, lease, sublicense, or otherwise transfer your API access or rights to any third party.</li>
                  <li>
                    Modify, decompile, reverse engineer, or otherwise attempt to derive the source code or underlying algorithms of the API
                    or any part thereof.
                  </li>
                  <li>
                    Use the API in any manner that would violate any applicable laws or regulations, or that would infringe on the
                    intellectual property rights of Gozo or any third party.
                  </li>
                </ol>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          Crawling Policy
          <ol>
            <li>
              Crawling and Indexing
              <ol>
                <li>
                  Gozo uses automated systems, including web crawlers or bots, to crawl, index, and analyze publicly accessible web pages
                  and other content on the internet. The primary purpose of this crawling and indexing process is to improve the relevance
                  and accuracy of search results provided by the Services.
                </li>
              </ol>
            </li>

            <li>
              Respecting Robots.txt
              <ol>
                <li>
                  Gozo respects the robots.txt protocol and will not crawl or index any web pages or content that are disallowed by the
                  robots.txt file present on a website. If you wish to exclude your website or specific web pages from being crawled or
                  indexed by Gozo, you must create and maintain a properly formatted robots.txt file in the root directory of your website.
                </li>
              </ol>
            </li>

            <li>
              Removal Requests
              <ol>
                <li>
                  If you believe that content from your website has been crawled or indexed by Gozo in violation of your robots.txt file or
                  applicable law, you may submit a removal request by contacting us through the appropriate channels provided on the
                  Services. Upon receipt of a valid removal request, Gozo will promptly remove the identified content from its search
                  results and take appropriate measures to prevent future crawling or indexing of such content, subject to the limitations
                  and conditions set forth in these Terms and applicable law.
                </li>
              </ol>
            </li>

            <li>
              No Guarantee of Inclusion or Ranking
              <ol>
                <li>
                  Gozo does not guarantee that any specific web pages or content will be included in its search results or that they will
                  achieve a particular rank or position. The crawling, indexing, and ranking of web pages and content are determined by
                  Gozo's proprietary algorithms, which take into account various factors such as relevance, quality, and user preferences.
                </li>
              </ol>
            </li>

            <li>
              Limitations on Crawling
              <ol>
                <li>
                  Gozo reserves the right to limit, restrict, or block crawling of certain websites or content, including but not limited
                  to:
                  <ol>
                    <li>Websites or content that violate these Terms or applicable law.</li>
                    <li>Websites or content that pose a security risk or contain malicious software.</li>
                    <li>
                      Websites or content that consume an excessive amount of resources or otherwise interfere with the normal operation of
                      the Services.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          Third-Party Content
          <ol className="nocounter">
            <li>
              You acknowledge and agree that any content crawled, indexed, or included in the search results provided by the Services is the
              property of the respective content owners and is not endorsed, sponsored, or affiliated with Gozo. Your access to and use of
              such third-party content is at your own risk and subject to the terms and conditions of the respective content owners.
            </li>
          </ol>
        </li>

        <li>
          Copyright Dispute Resolution Policy
          <ol className="nocounter">
            <li>
              The Digital Millennium Copyright Act of 1998 (the "DMCA") provides a remedy for copyright owners who believe that material
              appearing on the Internet infringes their rights under U.S. copyright law. If you believe in good faith that any Content
              provided in connection with the Service infringes your copyright, you (or your agent) may send us a notice requesting that we
              remove or disable access to the Content as described below (see https://www.copyright.gov/ for more details). It is
              GozoFinder's policy to (1) block access to or remove material that it believes in good faith to be copyrighted material that
              has been illegally copied and distributed by any of our advertisers, affiliates, content providers, members or users; and (2)
              remove and discontinue service to repeat offenders. The Designated Agent to Receive Notification of Claimed Infringement
              ("Designated Agent") is CC RTP Software Limited,
              &#097;&#098;&#117;&#115;&#101;&#064;&#103;&#111;&#122;&#111;&#102;&#105;&#110;&#100;&#101;&#114;&#046;&#099;&#111;&#109;.
            </li>
          </ol>
        </li>

        <li>
          Privacy
          <ol className="nocounter">
            <li>
              Your privacy is important to us. To understand how we collect, use, and protect your personal information, please refer to our{' '}
              <NavLink to={`/${country}/privacy`}>Privacy Policy section</NavLink>, which is incorporated into these Terms by reference. By
              using the Services, you acknowledge and agree to the terms and conditions set forth in the Privacy Policy.
            </li>
          </ol>
        </li>

        <li>
          Termination
          <ol className="nocounter">
            <li>
              Gozo reserves the right, in its sole discretion, to terminate your access to the Services or any portion thereof, at any time
              and for any reason, without notice. This includes, but is not limited to, instances where Gozo believes that you have violated
              these Terms or engaged in any unauthorized activity.
            </li>
          </ol>
        </li>

        <li>
          Disclaimer of Warranties
          <ol className="nocounter">
            <li>
              The Services are provided on an "as is" and "as available" basis. Gozo disclaims all warranties, express or implied, including
              but not limited to, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. Gozo
              makes no warranty that the Services will be uninterrupted, secure, or error-free, or that the results obtained from the use of
              the Services will be accurate, timely, or reliable.
            </li>
          </ol>
        </li>

        <li>
          Limitation of Liability
          <ol className="nocounter">
            <li>
              In no event shall Gozo, its affiliates, or their respective officers, directors,employees, or agents be liable for any direct,
              indirect, incidental, special, consequential, or punitive damages, including but not limited to, loss of profits, data, use,
              or goodwill, or other intangible losses, resulting from (a) your access to or use of or inability to access or use the
              Services; (b) any content obtained from the Services; or (c) unauthorized access, use, or alteration of your transmissions or
              content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not Gozo has
              been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed its essential
              purpose.
            </li>
          </ol>
        </li>

        <li>
          Indemnification
          <ol className="nocounter">
            <li>
              You agree to indemnify, defend, and hold harmless Gozo, its affiliates, and their respective officers, directors, employees,
              and agents from and against any and all claims, liabilities, damages, losses, or expenses, including reasonable attorneys'
              fees and costs, arising out of or in any way connected with your access to or use of the Services, your violation of these
              Terms, or your infringement of any intellectual property or other rights of any third party.
            </li>
          </ol>
        </li>

        <li>
          Severability
          <ol className="nocounter">
            <li>
              If any provision of these Terms is found to be invalid or unenforceable by a court of competent jurisdiction, the remaining
              provisions shall remain in full force and effect, and the invalid or unenforceable provision shall be deemed modified to the
              minimum extent necessary to make it valid and enforceable.
            </li>
          </ol>
        </li>

        <li>
          Waiver
          <ol className="nocounter">
            <li>
              No waiver by Gozo of any term or condition set forth in these Terms shall be deemed a further or continuing waiver of such
              term or condition or a waiver of any other term or condition, and any failure by Gozo to assert a right or provision under
              these Terms shall not constitute a waiver of such right or provision.
            </li>
          </ol>
        </li>

        <li>
          Entire Agreement
          <ol className="nocounter">
            <li>
              These Terms constitute the entire agreement between you and Gozo with respect to the Services and supersede any prior
              agreements, oral or written, between you and Gozo regarding the Services.
            </li>
          </ol>
        </li>

        <li>
          Contacting Us
          <ol className="nocounter">
            <li>
              If you have any questions about this Terms or Gozo handling of your personal information, please write to
              &#115;&#117;&#112;&#112;&#111;&#114;&#116;&#064;&#103;&#111;&#122;&#111;&#102;&#105;&#110;&#100;&#101;&#114;&#046;&#099;&#111;&#109;.
            </li>
          </ol>
        </li>
      </ol>
    </Area>
  )
}

export default Content
