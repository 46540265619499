import React from 'react'

interface Props {
  size?: string
  radius?: number
}

const WAV: React.FC<Props> = ({ size, radius }) => (
  <svg viewBox="0 0 600 600" preserveAspectRatio="xMinYMid" width={size} height={size}>
    <rect rx={radius} ry={radius} width="100%" height="100%" fill="#6fccd7" />
    <g fill="#fff">
      <path d="M106.6 277.456a9.1 9.1 0 00-9.1 9.1v30.089a9.1 9.1 0 1018.2 0v-30.089a9.1 9.1 0 00-9.1-9.1zM142.055 277.456a9.1 9.1 0 00-9.1 9.1v30.089a9.1 9.1 0 1018.2 0v-30.089c0-5.027-4.073-9.1-9.1-9.1zM177.51 264.726a9.1 9.1 0 00-9.1 9.1v55.549a9.1 9.1 0 1018.2 0v-55.55a9.1 9.1 0 00-9.1-9.1zM212.963 249.682a9.1 9.1 0 00-9.1 9.1v85.637a9.1 9.1 0 1018.2 0v-85.637c0-5.027-4.072-9.1-9.1-9.1zM248.42 223.064a9.1 9.1 0 00-9.1 9.1v138.872a9.1 9.1 0 1018.2 0V232.164a9.1 9.1 0 00-9.1-9.1zM283.873 159.992a9.1 9.1 0 00-9.1 9.1v265.014a9.1 9.1 0 1018.2 0V169.092a9.1 9.1 0 00-9.1-9.1zM319.328 182.559a9.1 9.1 0 00-9.1 9.1v219.882a9.1 9.1 0 1018.2 0V191.659a9.1 9.1 0 00-9.1-9.1zM354.782 227.692a9.1 9.1 0 00-9.1 9.1v129.614a9.1 9.1 0 1018.2 0V236.792a9.1 9.1 0 00-9.1-9.1zM390.238 254.887a9.1 9.1 0 00-9.1 9.1v75.223a9.1 9.1 0 1018.2 0v-75.223a9.1 9.1 0 00-9.1-9.1zM425.693 266.462a9.1 9.1 0 00-9.1 9.1v52.077a9.1 9.1 0 1018.2 0v-52.077a9.1 9.1 0 00-9.1-9.1zM461.146 277.456a9.1 9.1 0 00-9.1 9.1v30.089a9.1 9.1 0 1018.2 0v-30.089a9.1 9.1 0 00-9.1-9.1zM496.6 277.456a9.1 9.1 0 00-9.1 9.1v30.089a9.1 9.1 0 1018.2 0v-30.089a9.1 9.1 0 00-9.1-9.1z" />
    </g>
  </svg>
)

export default WAV
