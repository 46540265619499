import { Service } from '../config/model'
import { Country } from '../language/model'

export const getServiceRoute = (service: Service, country: Country): string => {
  switch (service) {
    case Service.Ulozto:
      return `/cse/${service}/${country}`
    case Service.Pinkfile:
      return `/cse/${service}`
    case Service.MG:
      return `/`
    default:
      throw Error('Unknown service ' + service)
  }
}
