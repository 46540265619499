import React, { useCallback, useContext, useMemo, useRef, useState, useEffect } from 'react'
import { Preview } from '../../../../modules/file/model'
import { SlideshowContext } from '../../../../modules/thumb'
import useSwipeActivated from '../../../../hooks/useSwipeActivated'
import { TNullable } from '../../../../types'
import Icon from '../../../Icons'
import { Image, ThumbWrapper } from './styled'
import { applyAttributes, createDefaultAttributes, videoPlay, videoStop } from './video'

interface Props {
  id: string
  preview: Preview
  fileName: string
  isStreamable: boolean
}

const VideoPreview: React.FC<Props> = ({ id, preview, fileName, isStreamable }) => {
  const { currentActiveId, setActiveId } = useContext(SlideshowContext)
  const currentActiveIdRef = useRef<TNullable<string>>(null)
  const [isPosterDisplayed, setIsPosterDisplayed] = useState(true)
  const videoRef = useRef<TNullable<HTMLVideoElement>>(null)
  const defaultAttributes = useMemo(() => createDefaultAttributes(preview.smallImage || preview.largeImage), [preview])

  const start = useCallback(async () => {
    const video = videoRef.current
    if (!video) {
      return
    }
    setActiveId(id)
    currentActiveIdRef.current = id

    applyAttributes(video, defaultAttributes)
    await videoPlay(video, id, preview.video as string)
  }, [videoRef, id, currentActiveId])

  const touchEvents = useSwipeActivated(start)
  const isActive = currentActiveId === id
  const showPoster = !isActive && !!defaultAttributes.poster
  const videoSrc = preview.video

  useEffect(() => {
    // we need to pause video and hide transcoding loader after the poster is rendered
    // if not user will see a glitch (two different video screens)
    if (isPosterDisplayed && videoRef.current) {
      videoStop(videoRef.current)
    }
  }, [isPosterDisplayed])

  return (
    <ThumbWrapper onMouseEnter={start} onMouseLeave={() => setActiveId(null)} {...touchEvents} className="wrapper">
      {/* we need own poster because the video tag can not display poster again after video was played */}
      {showPoster && <Image src={defaultAttributes.poster} alt={fileName} onLoad={() => setIsPosterDisplayed(true)} loading="lazy" />}
      {isStreamable && !isActive && (
        <div className="play">
          <Icon.PlaySolid />
        </div>
      )}
      <video ref={videoRef} src={videoSrc} {...defaultAttributes} />
    </ThumbWrapper>
  )
}

export default VideoPreview
