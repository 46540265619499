import { ApiRequestPromise, ApiResponse, createRequestInit, getMockedApiResponse, request } from '../api/request'
import { Method } from '../api/model'
import { SessionId } from '../user/model'
import { Service } from '../config/model'
import { GOZO_SLEEPING } from '../../constants/common'
import { ECaptchaProvider } from './model'

export enum ErrorCodes {
  TooManyRequests = 70001,
  NotVerified = 70002,
}

export const rateLimitingVerify = (
  sessionId: SessionId,
  service: Service,
  type: ECaptchaProvider,
  token: string
): ApiRequestPromise<ApiResponse> => {
  if (GOZO_SLEEPING) {
    return getMockedApiResponse({
      error: new Error('Inavalid captcha token'),
    })
  }

  return request(`v2/rate-limiting`, sessionId, createRequestInit(Method.POST, { service, type, token })) as ApiRequestPromise<ApiResponse>
}
