import React from 'react'

interface Props {
  size?: string
  radius?: number
}

const MP3: React.FC<Props> = ({ size, radius }) => (
  <svg viewBox="0 0 600 600" preserveAspectRatio="xMinYMid" width={size} height={size}>
    <rect rx={radius} ry={radius} width="100%" height="100%" fill="#6fccd7" />
    <g fill="#fff">
      <path d="M303.766 389.494c-15.292 0-27.733 12.44-27.733 27.734 0 15.294 12.44 27.734 27.733 27.734 15.296 0 27.735-12.44 27.735-27.734 0-15.294-12.439-27.734-27.735-27.734z" />
      <path d="M401.04 104H206.495c-17.231 0-31.2 13.969-31.2 31.2v329.135c0 17.231 13.969 31.2 31.2 31.2H401.04c17.231 0 31.2-13.969 31.2-31.2V135.2c0-17.231-13.969-31.2-31.2-31.2zm-97.274 367.064c-29.683 0-53.836-24.152-53.836-53.836s24.152-53.836 53.836-53.836c29.686 0 53.836 24.152 53.836 53.836s-24.15 53.836-53.836 53.836zM404.098 322.83c0 7.795-6.314 14.108-14.108 14.108H217.546c-7.795 0-14.11-6.314-14.11-14.108V150.386c0-7.793 6.315-14.108 14.11-14.108H389.99c7.794 0 14.108 6.315 14.108 14.108V322.83z" />
      <path d="M238.69 201.457h32.393v92.346H238.69zM287.568 174.673h32.4v119.13h-32.4zM336.453 234.238h32.393v59.565h-32.393z" />
    </g>
  </svg>
)

export default MP3
