import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import mixins from '../../../constants/GlobalStyleMixins'

export const Home = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.bg.shade5};

  a {
    display: block;
    margin-bottom: 20px;
  }
`

export const Logo = styled.div`
  a {
    position: relative;
    display: block;
  }
  svg {
    ${mixins.logoHome}
    margin-bottom: 20px;
    color: ${({ theme }) => theme.color.text};
  }
`

export const Description = styled.div`
  max-width: 60%;
  text-align: justify;

  p {
    display: block;
    margin-bottom: 20px;
  }
`

export const StyledNavLink = styled(NavLink)`
  && {
    display: inline-block;
    margin-bottom: 0;
  }
`
