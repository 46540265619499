import React from 'react'
import DOMPurify from 'dompurify'
import { Caption } from './styled'

interface FileDescriptionProps {
  description: string | undefined
  hasFileIcons: boolean
}

const URL_REGEX = /(\bhttps?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi

const nl2br = (text: string) => {
  return text.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2')
}

const fixTagsFromDb = (text: string) => {
  return text.replace(/&lt;br ?\/?&gt;/, '<br>')
}

const sanitizeDescription = (description: string): string => {
  description = nl2br(description)
  description = fixTagsFromDb(description)

  // fix URLs
  description = description.replace(URL_REGEX, (url) => {
    return `<a href="${url}">${url}</a>`
  })
  return DOMPurify.sanitize(description)
}

const Description: React.FC<FileDescriptionProps> = ({ description, hasFileIcons }) => {
  return (
    <Caption className={hasFileIcons ? 'caption row-2' : 'caption row-3'}>
      {description && <p dangerouslySetInnerHTML={{ __html: sanitizeDescription(description) }} />}
    </Caption>
  )
}

export default Description
