import { BehaviorSubject } from 'rxjs'

export interface AppStoreState {
  readonly isMenuOpen: boolean
  readonly scrollBarCompensation: number
}

const AppStoreSubject = new BehaviorSubject({
  isMenuOpen: false,
  scrollBarCompensation: 0,
} as AppStoreState)

const AppStore = {
  getValue: () => AppStoreSubject.value,
  subscribe: (next: (value: AppStoreState) => void) => AppStoreSubject.subscribe(next),
  setIsMenuOpen: (isMenuOpen: boolean) =>
    AppStoreSubject.next({
      ...AppStore.getValue(),
      isMenuOpen,
      scrollBarCompensation: isMenuOpen ? window.innerWidth - document.body.offsetWidth : 0,
    }),
}

export default AppStore
