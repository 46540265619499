import MockAdapter from 'axios-mock-adapter'
import axios from 'axios'

// mock responses
import mockResults from './data/search-results.json'
import mockRateLimiting from './data/search-rate-limiting.json'
import mockNoResults from './data/search-no-results.json'
import mockVerifyCaptcha from './data/verify-recaptcha.json'

// mocked axios instance
// mock enabled API routes defined in enableMocks()
// otherwise passthrough to real API
const mockApiClient = new MockAdapter(axios, { onNoMatch: 'passthrough' })

export const enableMocks = () => {
  // URL regexes
  const REGEX_URL_SEARCH = new RegExp(/[A-Za-z0-9]+/)

  // [search] rate limiting -> recaptcha
  mockApiClient.onGet(REGEX_URL_SEARCH).reply(mockRateLimiting.status, mockRateLimiting.response, mockRateLimiting.headers)

  // [search] no results
  mockApiClient.onGet(REGEX_URL_SEARCH).reply(mockNoResults.status, mockNoResults.response, mockNoResults.headers)

  // [search] general results
  mockApiClient.onGet(REGEX_URL_SEARCH).reply(mockResults.status, mockResults.response, mockResults.headers)

  // [captcha] verify captcha
  mockApiClient.onPost(REGEX_URL_SEARCH).reply(mockVerifyCaptcha.status, mockVerifyCaptcha.response)
}
