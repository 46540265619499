import React from 'react'
import { isDevelopment } from './modules/config/application_mode'

if (isDevelopment()) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  })
}
