import React, { useCallback, useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { default as ReCAPTCHA } from 'react-google-recaptcha'
import { debounce } from 'lodash'
import PageVisibility from 'react-page-visibility'
import { useInView } from 'react-intersection-observer'
import { AxiosError } from 'axios'
import { ApiClientError } from '../../../modules/api/request'
import useErrorHandler from '../../../hooks/useErrorHandler'
import { useCaptchaInitialized } from '../../../hooks/useCaptchaInitialized'
import Loader from '../../Loader'
import { ErrorCodes, rateLimitingVerify } from '../../../modules/rateLimiting/api'
import UserContext from '../../../modules/user/context'
import { ConfigContext } from '../../../modules/config/context'
import { Images } from '../../Images'
import Icon from '../../Icons'
import { TurnstileCaptcha } from '../../TurnstileRecaptcha'
import { ITurnstile } from '../../TurnstileRecaptcha/model'
import { isCaptchaProvider } from '../../../modules/rateLimiting/captchaProviders'
import { ECaptchaProvider } from '../../../modules/rateLimiting/model'
import { createQuietErrorCatch, isApiError } from '../../../modules/api/error'
import { apiCreateLog } from '../../../modules/logger/api'
import { TObject, TNullable } from '../../../types'
import { Checkbox } from '../../form/Checkbox'
import { Centered, CenteredCell, Modal } from './styled'
import { getRecaptchaStyle, removeRateLimitedAt, setVerifiedAt, shouldRevalidateData } from './recaptcha'

enum EState {
  Waiting = 'waiting',
  Success = 'success',
  Error = 'error',
}

enum ELogName {
  ApiError = 'recaptcha-error-api',
  SuccessChallenge = 'recaptcha-success-challenge',
  SuccessLoad = 'recaptcha-success-load',
  ErrTokenExpired = 'recaptcha-error-token-expired',
  ErrOnExpired = 'recaptcha-error-OnExpired',
  ErrOnErrored = 'recaptcha-error-OnErrored',
}

interface IProps {
  callback: () => void
}

const ModalRateLimiting: React.FC<IProps> = ({ callback }) => {
  const { t } = useTranslation()
  const errHandler = useErrorHandler()
  const user = useContext(UserContext)
  const config = useContext(ConfigContext)
  const isRecaptchaPrimary = isCaptchaProvider(config.captchaProvider, ECaptchaProvider.Recaptcha)
  const isTurnstilePrimary = isCaptchaProvider(config.captchaProvider, ECaptchaProvider.Turnstile)
  const [isLoading, setIsLoading] = useState(false)
  const [state, setState] = useState(EState.Waiting)
  const recaptchaRef = useRef<TNullable<ReCAPTCHA>>(null)
  const turnstileRef = useRef<TNullable<ITurnstile>>(null)
  const { ref: captchaWrapperRef, inView: captchaWrapperInView } = useInView()
  const captchaInitialized = useCaptchaInitialized(captchaWrapperInView)
  const [turToken, setTurToken] = useState<string>('')

  const onPageVisibilityChange = (isVisible: boolean) => {
    if (!isVisible || !shouldRevalidateData()) return
    successHandler()
  }

  const successHandler = () => {
    setIsLoading(false)
    setState(EState.Success)
    setVerifiedAt()
    removeRateLimitedAt()
    callback()
  }

  const verifyToken = useCallback(
    async (captchaType: ECaptchaProvider, token: TNullable<string>) => {
      // empty means token is expired
      if (!token) {
        createApiLog(ELogName.ErrTokenExpired)
        return
      }

      try {
        setIsLoading(true)
        await rateLimitingVerify(user.sessionId, config.service, captchaType, token)
        createApiLog(ELogName.SuccessChallenge)
        successHandler()
      } catch (error) {
        if (error instanceof Error) {
          logApiError(error)
          const errNotVerified = isApiError(error, ErrorCodes.NotVerified)
          if (errNotVerified) {
            setState(EState.Error)
            return
          }
        }

        const isApiClientError = error instanceof ApiClientError
        const isServerError = error instanceof AxiosError && error.status && error.status >= 500
        if (isApiClientError || isServerError) {
          successHandler()
        }

        errHandler(error)
      } finally {
        recaptchaRef.current?.reset()
        setIsLoading(false)
      }
    },
    [recaptchaRef]
  )

  const logApiError = (error: Error) => {
    const apiError = error instanceof ApiClientError
    const data = {
      error: error.message,
      ...(apiError && {
        status: error.response.status,
        apiData: error.response.data,
      }),
    }
    createApiLog(ELogName.ApiError, data)
  }

  const createApiLog = useCallback(
    (logName: string, data: TObject = {}) => {
      const recaptchaValue = recaptchaRef.current?.getValue()
      const widgetId = recaptchaRef.current?.getWidgetId()

      apiCreateLog(
        user.sessionId,
        config.service,
        {
          ...data,
          widgetId,
          recaptchaValue,
        },
        logName
      ).catch(createQuietErrorCatch())
    },
    [recaptchaRef]
  )

  const resetTurToken = () => setTurToken('')

  const turOnVerify = (token: string) => setTurToken(token)

  // after checkbox is checked, verify token after 600ms wait
  const turOnCheckboxChange = debounce((checked: boolean, token: string) => verifyToken(ECaptchaProvider.Turnstile, token), 600)

  const handleRecaptchaLoad = (data?: { loaded: boolean; observers: object }) =>
    createApiLog(ELogName.SuccessLoad, {
      loaded: data?.loaded,
    })

  return (
    <Modal hidden={false}>
      <Centered>
        <CenteredCell>
          <PageVisibility onChange={onPageVisibilityChange}>
            <div>
              {isLoading && <Loader />}
              <h1>429</h1>
              <h2>{t('components.Modal.RateLimiting.title')}</h2>
              <div className="boxes">
                <div className="ok">
                  <div className="icons">
                    <Icon.Browser />
                    <Icon.CheckCircle />
                  </div>
                  <div>
                    <strong>{t('components.Modal.RateLimiting.boxes.browser.title')}</strong>
                    <span>{t('components.Modal.RateLimiting.boxes.browser.status')}</span>
                  </div>
                </div>
                <div>
                  <Icon.Arrows />
                </div>
                <div className="ok">
                  <div className="icons">
                    <Icon.Cloudflare />
                    <Icon.CheckCircle />
                  </div>
                  <div>
                    <strong>{t('components.Modal.RateLimiting.boxes.cloudflare.title')}</strong>
                    <span>{t('components.Modal.RateLimiting.boxes.cloudflare.status')}</span>
                  </div>
                </div>
                <div>
                  <Icon.Arrows />
                </div>
                <div className="error">
                  <div className="icons">
                    <Images.LogoSymbol />
                    <Icon.CloseCircle />
                  </div>
                  <div>
                    <strong>{t('components.Modal.RateLimiting.boxes.gozo.title')}</strong>
                    <span>{t('components.Modal.RateLimiting.boxes.gozo.status')}</span>
                  </div>
                </div>
              </div>
              <div className="what" ref={captchaWrapperRef}>
                <div>
                  <h2>{t('components.Modal.RateLimiting.captcha.title')}</h2>
                  <p>{t('components.Modal.RateLimiting.captcha.' + state)}</p>
                  <div>
                    {isRecaptchaPrimary && captchaInitialized && (
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={window.Config.RECAPTCHA_SITE_KEY}
                        size="normal"
                        theme={'light'}
                        onChange={(token) => verifyToken(ECaptchaProvider.Recaptcha, token)}
                        className="captcha"
                        hl={config.language}
                        style={getRecaptchaStyle()}
                        onExpired={() => createApiLog(ELogName.ErrOnExpired)}
                        onErrored={() => createApiLog(ELogName.ErrOnErrored)}
                        asyncScriptOnLoad={handleRecaptchaLoad}
                      />
                    )}
                    {isTurnstilePrimary && captchaInitialized && (
                      <TurnstileCaptcha
                        onBeforeInteractive={resetTurToken}
                        onError={resetTurToken}
                        onExpire={resetTurToken}
                        onTimeout={resetTurToken}
                        onVerify={turOnVerify}
                        ref={turnstileRef}
                      >
                        {turToken && (
                          <Checkbox
                            label={t('components.Modal.RateLimiting.boxes.cloudflare.imNotRobot')}
                            onChange={(checked) => turOnCheckboxChange(checked, turToken)}
                          />
                        )}
                      </TurnstileCaptcha>
                    )}
                  </div>
                </div>
                <div>
                  <h2>{t('components.Modal.RateLimiting.whatHappened.title')}</h2>
                  <p>{t('components.Modal.RateLimiting.whatHappened.body')}</p>
                </div>
              </div>
            </div>
          </PageVisibility>
        </CenteredCell>
      </Centered>
    </Modal>
  )
}

export default ModalRateLimiting
