import React, { useContext, useEffect, useRef } from 'react'
import SearchStore from '../../../modules/search/store'
import { getSearchSuggest, GetSearchSuggestResponse } from '../../../modules/searchSuggest/api'
import { ApiRequestPromise } from '../../../modules/api/request'
import { ConfigContext } from '../../../modules/config/context'
import UserContext from '../../../modules/user/context'
import { useStore } from '../../../hooks/useStore'
import SearchSuggestStore from '../../../modules/searchSuggest/store'
import useOutsideMouseClick from '../../../hooks/useOutsideMouseClick'
import useScroll from '../../../hooks/useScroll'
import { createQuietErrorCatch } from '../../../modules/api/error'
import { SearchSuggest } from './styled'

interface Props {
  isHome?: boolean
  activeItem: number
  onScroll: (hasItems: boolean) => void
}

const SuggestBox: React.FC<Props> = ({ isHome, activeItem, onScroll }) => {
  const config = useContext(ConfigContext)
  const user = useContext(UserContext)
  const searchSuggestStore = useStore(SearchSuggestStore)
  const searchSuggestRequestRef = useRef<ApiRequestPromise<GetSearchSuggestResponse> | undefined>(undefined)
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (searchSuggestRequestRef.current) {
      searchSuggestRequestRef.current.cancel()
    }
    if (!searchSuggestStore.query) {
      return
    }

    const request = getSearchSuggest(user.sessionId, config.service, searchSuggestStore.query)
    searchSuggestRequestRef.current = request

    request
      .then(({ data }) => {
        SearchSuggestStore.setItems(data.items)
      })
      .catch(createQuietErrorCatch(SearchSuggestStore.reset))

    return () => request && request.cancel()
  }, [searchSuggestStore.query])

  useEffect(() => {
    SearchSuggestStore.reset()
  }, [window.location.href])

  useScroll(document, () => {
    const hasItems = !!SearchSuggestStore.getValue().items.length
    onScroll?.(hasItems)
    if (hasItems) {
      SearchSuggestStore.reset()
    }
  })

  useOutsideMouseClick(containerRef, () => {
    SearchSuggestStore.reset()
  })

  if (!searchSuggestStore.items.length) {
    return null
  }

  return (
    <SearchSuggest ref={containerRef} $isHome={isHome}>
      {searchSuggestStore.items.map((item, index) => (
        <div
          key={index}
          className={activeItem === index ? 'active' : ''}
          onClick={() => {
            SearchStore.setSearchQuery(item.suggestion)
            SearchStore.makeForceReload()
            SearchSuggestStore.reset()
          }}
        >
          {item.suggestion}
        </div>
      ))}
    </SearchSuggest>
  )
}

export default SuggestBox
