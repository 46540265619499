import React from 'react'
import { Area } from '../../styled'
import useDocumentTitle from '../../../../hooks/useDocumentTitle'

const Content: React.FC = () => {
  useDocumentTitle('Privacy policy')

  return (
    <Area>
      <h1>Privacy policy</h1>

      <p>
        At Gozo, we are committed to protecting the privacy of our users. We understand the importance of maintaining your privacy and
        security when using our search engine. This privacy policy outlines the measures we have taken to ensure the confidentiality and
        security of your personal information when using Gozo.
      </p>

      <ol>
        <li>
          Anonymisation and No Logging
          <ol>
            <li>
              Gozo is designed to provide you with a high level of privacy by anonymising your search queries. We do not store or log any
              personally identifiable information (PII) such as IP addresses, search queries, or other user-specific data.
            </li>
            <li>
              When you use Gozo, your search queries are processed without being linked to your personal identity. We utilize advanced
              anonymisation techniques to prevent any association between your search queries and your online identity. This ensures that
              your searches remain private and secure.
            </li>
          </ol>
        </li>
        <li>
          Data Privacy
          <ol>
            <li>
              We understand the sensitive nature of your personal information, and we take measures to ensure that it remains secure. Gozo
              does not sell, rent, or share your personal information with third parties for marketing or advertising purposes.
            </li>
            <li>
              Furthermore, Gozo adheres to the principles of data minimization and data retention policies. We only collect and process the
              minimal amount of data necessary to provide our services and maintain the functionality of our search engine.
            </li>
          </ol>
        </li>
        <li>
          API Security
          <ol className="nocounter">
            <li>
              We offer a secure API for developers to integrate Gozo into their applications or services. Our API uses strong encryption and
              authentication mechanisms to protect the confidentiality and integrity of the data transmitted between the client and our
              servers. We continuously monitor and update our security measures to defend against any potential threats or vulnerabilities.
            </li>
          </ol>
        </li>
        <li>
          Secure Browsing
          <ol className="nocounter">
            <li>
              To ensure your privacy and security while using Gozo, we employ Secure Socket Layer (SSL) technology, which encrypts the data
              transmitted between your device and our servers. This prevents third parties from intercepting or tampering with your search
              queries and results.
            </li>
          </ol>
        </li>
        <li>
          Privacy by Design
          <ol className="nocounter">
            <li>
              Gozo is built on the principle of Privacy by Design, ensuring that privacy and data protection are an integral part of our
              search engine's foundation. From the initial stages of development to ongoing improvements, we have integrated
              privacy-enhancing technologies and methodologies to guarantee the security of your personal information. Our commitment to
              Privacy by Design encompasses proactive measures to identify and address potential privacy risks, data minimization,
              transparency in our practices, user empowerment, end-to-end security, and robustness and resilience of our systems. By
              incorporating Privacy by Design into every aspect of Gozo, we aim to provide our users with the most secure and private web
              search experience possible.
            </li>
          </ol>
        </li>
        <li>
          Third-Party Websites
          <ol className="nocounter">
            <li>
              Gozo may provide links to third-party websites. Please note that our privacy policy does not apply to these external sites. We
              encourage you to review the privacy policies of any third-party websites you visit to understand their data practices and how
              they may collect, use, or share your personal information.
            </li>
          </ol>
        </li>
        <li>
          Policy Updates
          <ol className="nocounter">
            <li>
              We may update our privacy policy from time to time to reflect changes in our practices or applicable laws and regulations. If
              we make any material changes to this policy, we will notify you through our website, by email, or other means as required by
              law. Your continued use of Gozo following any updates to our privacy policy indicates your acceptance of these changes.
            </li>
          </ol>
        </li>
      </ol>

      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns about our privacy policy or practices, please contact CC RTP Software Limited at
        &#112;&#114;&#105;&#118;&#097;&#099;&#121;&#064;&#103;&#111;&#122;&#111;&#102;&#105;&#110;&#100;&#101;&#114;&#046;&#099;&#111;&#109;.
        We are dedicated to maintaining a high level of privacy and security for our users and will respond promptly to any inquiries.
      </p>
      <p>
        By using Gozo, you agree to the terms outlined in this privacy policy. Your trust in our services is our top priority, and we strive
        to provide you with the most secure and private web search experience possible.
      </p>
    </Area>
  )
}

export default Content
