import React from 'react'

interface Props {
  size?: string
  radius?: number
}

const OtherFile: React.FC<Props> = ({ size, radius }) => (
  <svg viewBox="0 0 600 600" preserveAspectRatio="xMinYMid" width={size} height={size}>
    <rect rx={radius} ry={radius} width="100%" height="100%" fill="#999" />
    <path
      d="M439.2 196q5.6 5.6 9.6 15.2t4 17.6v230.4q0 8-5.6 13.6t-13.6 5.6H164.8q-8 0-13.6-5.6t-5.6-13.6v-320q0-8 5.6-13.6t13.6-5.6H344q8 0 17.6 4t15.2 9.6zm-88.8-48.8v75.2h75.2q-2-5.8-4.4-8.2l-62.6-62.6q-2.4-2.4-8.2-4.4zm76.8 305.6V248H344q-8 0-13.6-5.6t-5.6-13.6v-83.2H171.2v307.2h256z"
      fill="#fff"
    />
  </svg>
)

export default OtherFile
