import React from 'react'

interface Props {
  size?: string
  radius?: number
}

const ISO: React.FC<Props> = ({ size, radius }) => (
  <svg viewBox="0 0 600 600" preserveAspectRatio="xMinYMid" width={size} height={size}>
    <rect rx={radius} ry={radius} width="100%" height="100%" fill="#788a9d" />
    <g fill="#fff">
      <path d="M242.843 228.058c-18.076 0-32.782 14.705-32.782 32.782 0 18.076 14.706 32.782 32.782 32.782s32.782-14.706 32.782-32.782c0-18.077-14.707-32.782-32.782-32.782zm0 52.564c-10.909 0-19.782-8.874-19.782-19.782 0-10.909 8.873-19.782 19.782-19.782 10.908 0 19.782 8.873 19.782 19.782 0 10.908-8.875 19.782-19.782 19.782z" />
      <path d="M453.756 326.455H329.16c14.225-18.723 22.122-41.735 22.122-65.615 0-59.794-48.646-108.44-108.44-108.44-59.793 0-108.438 48.646-108.438 108.44 0 23.88 7.897 46.892 22.123 65.615h-7.677c-12.544 0-22.75 10.206-22.75 22.75v73.05c0 12.544 10.206 22.75 22.75 22.75h304.906c12.544 0 22.75-10.206 22.75-22.75v-73.05c0-12.545-10.206-22.75-22.75-22.75zM153.904 260.84c0-49.041 39.897-88.94 88.939-88.94 49.042 0 88.94 39.899 88.94 88.94 0 24.971-10.541 48.776-28.941 65.615H182.845c-18.4-16.84-28.94-40.645-28.94-65.615zm303.102 161.416c0 1.761-1.489 3.25-3.25 3.25H148.85c-1.761 0-3.25-1.489-3.25-3.25v-73.051c0-1.762 1.489-3.25 3.25-3.25h304.906c1.761 0 3.25 1.488 3.25 3.25v73.05z" />
      <path d="M321.465 361.583H179.768c-7.168 0-13 5.832-13 13v22.293c0 7.168 5.832 13 13 13h141.697c7.169 0 13-5.832 13-13v-22.293c0-7.168-5.831-13-13-13zm-.018 35.294h-141.68v-22.292h141.698l-.018 22.292zm.018 6.5v-6.5h.002l-.002 6.5zM378.232 375.98h-16.25a9.75 9.75 0 00-9.75 9.75 9.75 9.75 0 009.75 9.75h16.25a9.75 9.75 0 009.75-9.75 9.75 9.75 0 00-9.75-9.75zM426.332 375.98h-16.25a9.75 9.75 0 00-9.75 9.75 9.75 9.75 0 009.75 9.75h16.25a9.75 9.75 0 009.75-9.75 9.75 9.75 0 00-9.75-9.75z" />
    </g>
  </svg>
)

export default ISO
