import { Language } from './model'

export const detectUserLanguage = (): Language => {
  const languages = (navigator.languages || []) as Language[]

  for (const lang of languages) {
    if (Object.values(Language).includes(lang)) {
      return lang
    }
  }

  return Language.EN
}
