import React, { useEffect } from 'react'
import { generatePath, Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Images } from '../../components/Images'
import { getDefaultDomainConfig } from '../../modules/config/domains'
import { LanguageToCountry } from '../../modules/language/converter'
import { detectUserLanguage } from '../../modules/language/detector'
import { getServiceRoute } from '../../modules/router/service'
import { NotFound } from './styled'

const UrlNotFound: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    if (window.location.pathname === '/') {
      const config = getDefaultDomainConfig()
      navigate(getServiceRoute(config.service, LanguageToCountry[detectUserLanguage()]))
    }
  }, [])

  return (
    <NotFound>
      <div className="logo">
        <Link to={generatePath('/')} reloadDocument={true}>
          <Images.LogoHome />
        </Link>
      </div>
      <div>{t('routes.UrlNotFound.title')}</div>
    </NotFound>
  )
}

export default UrlNotFound
